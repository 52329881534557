import { createMuiTheme } from '@material-ui/core/styles';
import variables from '../../themeVars';

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

export default createMuiTheme({
  palette: {
    primary: {
      main: variables.primaryColor,
      contrastText: variables.btnContrastText,
    },
    secondary: {
      light: '#ff4081',
      main: variables.secondaryColor,
      dark: '#c51162',
      contrastText: variables.btnContrastText,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color: '#346094',
    },
    useNextVariants: true,
    fontFamily: variables.fontRegular,
    fontSize: 16,
  },
  overrides: {
    MUIDataTableHeadCell: {
      sortActive: {
        color: '#fff',
      },
    },
    MuiTableSortLabel: {
      active: {
        '& svg': {
          color: '#fff !important',
        },
      },
    },
    MUIDataTableSearch: {
      main: {
        display: 'flex',
        flex: '1 0 auto',
        '& button': {
          marginTop: '-4px',
        },
      },
      searchIcon: {
        display: 'none',
        marginTop: '15px',
        marginRight: '8px',
      },
      searchText: {
        flex: '0.8 0',
        top: 8,
        margin: '-1px 0 0px',
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        flexDirection: 'row',
        maxWidth: 350,
        height: 40,
        '& div': {
          '&::before, &::after': {
            borderBottom: 0,
          },
        },
        '& div:hover': {
          '&::before, &::after': {
            border: 'none !important',
          },
        },
        '& input': {
          padding: '10px',
          fontSize: 15,
        },
        [defaultTheme.breakpoints.down('xs')]: {
          flex: '1 0',
        },
      },
      clearIcon: {
        marginLeft: '5px',
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MUIDataTable: {
      paper: {
        '&&.iconSearchActive': {
          '& button[title=Search]': {
            color: `${variables.primaryColor}`,
          },
        },
        '&&.iconSearchClose': {
          '& button[title=Search]': {
            color: 'rgba(0, 0, 0, 0.54) !important',
          },
        },
      },
    },
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: 15,
        fontWeight: 'normal',
      },
    },
    MUIDataTableToolbar: {
      root: {
        maxHeight: 56,
        padding: 0,
        marginBottom: 10,
      },
      actions: {
        textAlign: 'right',
      },
      icon: {
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      [defaultTheme.breakpoints.down('xs')]: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        left: {
          padding: ' 0',
          flexGrow: ' 1',
          flex: ' 1',
        },
        titleText: {
          textAlign: 'center',
        },
        actions: {
          textAlign: 'right',
          display: 'flex',
          flexGrow: 0,
        },
      },
      [defaultTheme.breakpoints.down('sm')]: {
        left: {
          display: 'block',
          padding: '0',
        },
      },
    },
    MuiIconButton: {
      root: {
        top: 5,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 15,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white !important',
        height: 40,
      },
    },
    MuiTypography: {
      root: {
        '&.dialogContent': {
          fontSize: 15,
        },
        '&.title': {
          fontFamily: variables.fontMedium
        }
      },
    },
    MuiDialogActions: {
      root: {
        '&.confirmationButtons': {
          justifyContent: 'end',
          paddingTop: 0,
          '& .keepBtn':{
            '&:hover': {
              backgroundColor: variables.primaryColor,
              color: variables.btnContrastText
            },
            '&.Mui-disabled': {
              color: variables.primaryBtnDisabled
            }
          },
          '& .deleteBtn': {
              backgroundColor: variables.dialogActionButton,
              '&:hover': {
                  backgroundColor: variables.dialogActionBtnHover,
              },
              '&.Mui-disabled': {
                  backgroundColor: variables.primaryBtnDisabled
              }
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        '&.deleteConfirmationDialog': {
          width: 600,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        '&.title-wrapper': {
          fontFamily: `${variables.fontMedium} !important`,
          color: variables.headerPrimary,
          marginBottom: 20
        },
      },
    },
    MuiDialogContent: {
      root: {
        '&.dialogContent': {
        paddingTop: 10,
        paddingLeft: 16,
        paddingBottom: 0,
        }
      },
    },
    MuiDialogContentText: {
      root: {
        color: '#000',
        fontSize: 15,
      },
    },
    MuiButtonBase: {
      root: {
        '&.closeButton': {
          position: 'absolute',
          right: defaultTheme.spacing(1),
          top: defaultTheme.spacing(1),
          color: variables.primaryColor,
        },
        '&.MuiButton-outlinedPrimary':{
          '&:hover': {
            backgroundColor: variables.primaryColor,
            color: variables.btnContrastText
          },
          '&.Mui-disabled': {
            color: variables.primaryBtnDisabled
          }
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: variables.primaryColor,
            '&:hover': {
                backgroundColor: variables.primaryBtnHover,
            },
            '&.Mui-disabled': {
                backgroundColor: variables.primaryBtnDisabled
            },
            '&.deleteBtn': {
              backgroundColor: variables.primaryBtnHover,
          }
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        border: `1px solid ${variables.primaryColor}`
      },
      root: {
        minWidth: 100,
        width: 178,
      },
    },
    MuiDivider: {
      root: {
        '&.divider': {
          borderColor: '#003b5c',
          backgroundColor: '#003b5c',
          border: '0.5px solid',
          marginTop: 5,
        },
      },
    },
  },
});
