import React, { useState } from 'react';
import { ListItem, List } from '@material-ui/core';
import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputFormik from '../../components/InputFormik';
import PercentFormat from '../PercentFormat';
import { EmptyCheckBoxSvg, FilledCheckBoxSvg } from './checkBox-svg';
import { INCREASE_INITIALDB_X } from '../../../src/constants';

const ListItemCheckbox = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  label,
  children,
  disabled,
  nameInput,
  ...rest
}) => {

  const [increaseInitialDBbyX, setIncreaseInitialDBbyX] = useState();
  const hasErrors = !!(getIn(touched, nameInput) && getIn(errors, nameInput));

  const handleRefreshValue = () => {
    setIncreaseInitialDBbyX(values.faceAmountAndPremium.increaseInitialDBbyX);
  }

  const handleOnClick = check => {
    if (check !== 'checkbox' && values.faceAmountAndPremium.increaseInitialDBbyX) { 
      return;
    }
    if (!disabled) {
      setFieldValue(field.name, !field.value);
      setFieldTouched(field.name, true);
      
       if(increaseInitialDBbyX) {
        setIncreaseInitialDBbyX(values.faceAmountAndPremium.increaseInitialDBbyX)
        setFieldValue('faceAmountAndPremium.increaseInitialDBbyX', increaseInitialDBbyX)
      } else if (!field.value) {
        setFieldValue('faceAmountAndPremium.increaseInitialDBbyX', INCREASE_INITIALDB_X);
      } else {
        setIncreaseInitialDBbyX(values.faceAmountAndPremium.increaseInitialDBbyX)
        setFieldValue('faceAmountAndPremium.increaseInitialDBbyX', '');
      }
    }
  };

  return (
    <List className={hasErrors ? 'error' : ''}>
      <ListItem
        disabled={disabled}
        className="container-input-check-box"
      >
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              color="primary"
              icon={<EmptyCheckBoxSvg />}
              checkedIcon={<FilledCheckBoxSvg />}
              {...field}
              {...rest}
              value=""
              checked={field.value}
              onClick={() => handleOnClick('checkbox')}
            />
          }
        />
        <InputFormik
          name={nameInput}
          label={label}
          numberFormatCustom={PercentFormat}
          maxLength={4}
          decimalscale={0}
          inputClass='input-check-box'
          className='input-check-box'
          onClick={() => handleOnClick()}
          onChange={() => handleRefreshValue()}
          disabled={!values.faceAmountAndPremium.increaseInitialDBbyXChk}
        />
        {children}
      </ListItem>
    </List>
  );
};

ListItemCheckbox.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  label: PropTypes.string,
  showHelp: PropTypes.bool,
  partialSelect: PropTypes.bool,
  textHint: PropTypes.object,
  hintPosition: PropTypes.string,
  nameInput: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any
};

ListItemCheckbox.defaultProps = {
  disabled: false,
};

const CustomCheckBoxInputFormik = props => (
  <Field component={ListItemCheckbox} {...props} />
);

export default CustomCheckBoxInputFormik;
