/* eslint-disable no-console */
import React, { useState, useReducer, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import {
  NEXT_STEP,
  BACK_STEP,
  GO_STEP,
  UPDATE_INITIAL_VALUES,
} from './constants';
import wizardReducer from './reducer';
import WizardContext, { useWizardContext } from './WizardContext';

const useStyles = makeStyles(
  theme => {
    return {
      root: {
        padding: '0px 10px',
        width: '100%',
      },
      header: {
        fontSize: '1em',
        margin: '50px 0px 20px',
        '@media only screen and (max-width: 600px)': {
          fontSize: '0.625em',
        },
      },
      stepsNavigation: {
        display: 'block',
        width: '100%',
      },
      stepNavigation: {
        cursor: 'default',
        display: 'inline-block',
        fontSize: '1rem',
        padding: '10px 20px',
        borderRight: '1px solid ' + theme.palette.primary.main,
        '&:last-child': {
          border: 'none',
        },
        textAlign: 'center',
        '&.is-visited': {
          cursor: 'pointer',
        },
        '&.is-active': {
          cursor: 'pointer',
        },
        '@media only screen and (max-width: 600px) and (orientation: portrait)': {
          border: 'none',
          display: 'block',
          fontSize: '14px',
          padding: '0px 10px',
        },
        '@media only screen and (max-width: 960px) and (orientation: landscape) ': {
          fontSize: '14px',
          padding: '2px 14px',
        },
      },
      steps: {
        width: '100%',
      },
      step: {
        width: '100%',
      },
      stepTitle: {
        padding: '20px 0px',
      },
      navigationControls: {
        width: '100%',
        '& button': {
          margin: '20px 15px 20px 0px',
          minWidth: 100,
          '&:first-child': {
            minWidth: 192,
          },
        },
      },
      summary: {
        display: 'inline-block',
        height: '120px',
        verticalAlign: 'top',
        width: '20%',
      },
      summaryStep: {
        border: '1px solid green',
        display: 'block',
        height: '60px',
        verticalAlign: 'top',

        '&.is-active': {},
      },
    };
  },
  { name: 'MUIAnnexusFormWizard' },
);

const defaultInitialState = {
  currentStep: 1,
  totalSteps: 5,
  isContinuable: true,
  isBackable: false,
  isLastPage: false,
  initialValues: {},
  isEditForm: false,
};

let lastVisitedStep = 1;
const FormWizard = ({
  children,
  initialValues,
  validations,
  handleSubmit,
  resetOnSubmit,
  settings,
  ...rest
}) => {
  const classes = useStyles(rest);
  // Local States
  let localFormikProps = {};
  const [state, dispatch] = useReducer(wizardReducer, {
    ...defaultInitialState,
    initialValues: initialValues,
  });
  const [fieldsChanged, setFieldsChanged] = useState([]);

  const getInitialStep = (values, settings) => {
    let initialStep = state.totalSteps;

    if (!validations) {
      return initialStep;
    }

    for (let step = 0; step < validations.length; step = step + 1) {
      const errors = validations[step](initialValues, settings);
      if (Object.keys(errors).length !== 0) {
        initialStep = step + 1;
        break;
      }
    }
    
    return initialStep;
  }

  // Scroll window to top when currentStep is updated
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.currentStep]);

  useEffect(() => {
    dispatch({
      type: UPDATE_INITIAL_VALUES,
      initialValues: initialValues,
    });
    // is edit form
    if (initialValues.illustrationId) {
      lastVisitedStep = getInitialStep(initialValues, settings);
    }
  }, [initialValues, state.totalSteps]);

  const handleNext = () => {
    // validate on next
    localFormikProps.validateForm().then(errors => {
      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        localFormikProps.submitForm();
      } else {
        localFormikProps.setTouched(errors);
        Object.keys(document.forms[0]).some(key => {
          const obj = document.forms[0][key];
          if (_.has(errors, obj.name)) {
            obj.focus();
            return true;
          }
          return false;
        });
      }
    });
  };

  const handlePrev = () => {
    localFormikProps.setTouched({});
    dispatch({ type: BACK_STEP });
  };
  const handleGoToStep = step => {
    localFormikProps.setTouched({});
    dispatch({ type: GO_STEP, step });
  };
  const handleValidations = values => {
    if (!validations) {
      return {};
    }
    if (validations) {
      return validations[state.currentStep - 1](values, settings);
    }
  };
  const handleOnSubmit = (values, actions) => {
    if (state.isLastPage) {
      if (handleSubmit) {
        actions.setSubmitting(false);
        handleSubmit(values, actions);
      }
      if (resetOnSubmit) {
        resetWizardForm();
      }
    } else {
      localFormikProps.setTouched({});
      localFormikProps.setSubmitting(false);
      lastVisitedStep = Math.max(state.currentStep + 1, lastVisitedStep);
      dispatch({ type: NEXT_STEP, values });
    }
  };
  const resetWizardForm = () => {
    dispatch({ type: GO_STEP, step: 1 });
    localFormikProps.resetForm();
  };

  const changeOnForm = (fieldName, fieldValue) => {
    if (!_.find(fieldsChanged, n => n === fieldName)) {
      setFieldsChanged([...fieldsChanged, fieldName]);
    }

    setTimeout(() => {
      setFieldsChanged([]);
    }, 4000);

    localFormikProps.setFieldValue(fieldName, fieldValue);
  };

  const wizardProps = {
    handleNext,
    handlePrev,
    handleSubmit,
    handleGoToStep,
    changeOnForm,
    fieldsChanged,
    ...state,
  };

  return (
    <Formik
      initialValues={state.initialValues}
      validate={handleValidations}
      enableReinitialize
      validateOnBlur
      onSubmit={handleOnSubmit}
      render={formikProps => {
        localFormikProps = formikProps;
        const context = {
          ...wizardProps,
          ...formikProps,
          isModified: !_.isEmpty(formikProps.touched) && formikProps.dirty,
          lastVisitedStep,
        };
        if (!_.isEmpty(formikProps.touched) && formikProps.dirty) {
          lastVisitedStep = state.currentStep;
          context.lastVisitedStep = state.currentStep;
        }
        return (
          <WizardContext.Provider value={context}>
            <div className={classes.root}>{children(context)}</div>
          </WizardContext.Provider>
        );
      }}
    />
  );
};

FormWizard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  validations: PropTypes.array,
  resetOnSubmit: PropTypes.bool,
  settings: PropTypes.any
};

const Header = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return children ? <div className={classes.header}>{children}</div> : null;
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const StepsNavigation = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return <div className={classes.stepsNavigation}>{children}</div>;
};

StepsNavigation.propTypes = {
  children: PropTypes.array,
};

const StepsNavigationStep = ({ step, children, ...rest }) => {
  const classes = useStyles(rest);
  const { currentStep, handleGoToStep, lastVisitedStep } = useWizardContext();
  const classIsActive = step === currentStep ? 'is-active' : '';
  let isVisited = step <= lastVisitedStep ? 'is-visited' : '';
  const handleClick = () => {
    if (isVisited !== '') {
      handleGoToStep(step);
    }
    return false;
  };
  return children ? (
    <div
      className={`${classes.stepNavigation} ${classIsActive} ${isVisited}`}
      onClick={handleClick}
    >
      {children}
    </div>
  ) : null;
};
StepsNavigationStep.propTypes = {
  step: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const Steps = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return <div className={classes.steps}>{children}</div>;
};
Steps.propTypes = {
  children: PropTypes.array,
};

const Step = ({ step, children, ...rest }) => {
  const classes = useStyles(rest);
  const { currentStep } = useWizardContext();

  return currentStep === step ? (
    <div className={classes.step}>
      <Form>{children}</Form>
    </div>
  ) : null;
};
Step.propTypes = {
  step: PropTypes.number,
  children: PropTypes.any,
};

const StepTitle = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return children ? <div className={classes.stepTitle}>{children}</div> : null;
};

StepTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const PreviousButton = ({ children }) => {
  const { isBackable } = useWizardContext();

  return isBackable ? children : null;
};

PreviousButton.propTypes = {
  children: PropTypes.any
}

const NextButton = ({ children }) => {
  const { isContinuable } = useWizardContext();

  return isContinuable ? children : null;
};

NextButton.propTypes = {
  children: PropTypes.any
}

const SubmitButton = ({ children }) => {
  const { isLastPage } = useWizardContext();

  return isLastPage ? children : null;
};

SubmitButton.propTypes = {
  children: PropTypes.any
}

const NavigationControls = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return <div className={classes.navigationControls}>{children}</div>;
};
NavigationControls.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
const Summary = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return <div className={classes.summary}>{children}</div>;
};
Summary.propTypes = {
  children: PropTypes.array,
};

const SummaryStep = ({ step, children, ...rest }) => {
  const classes = useStyles(rest);
  const { currentStep, handleGoToStep } = useWizardContext();
  const classIsActive = step === currentStep ? 'is-active' : '';
  return (
    <div
      className={`${classes.summaryStep} ${classIsActive}`}
      onClick={() => handleGoToStep(step)}
    >
      {children}
    </div>
  );
};
SummaryStep.propTypes = {
  step: PropTypes.number,
  children: PropTypes.object,
};

FormWizard.Summary = Summary;
FormWizard.SummaryStep = SummaryStep;
FormWizard.Navigation = StepsNavigation;
FormWizard.NavigationStep = StepsNavigationStep;
FormWizard.Steps = Steps;
FormWizard.Step = Step;
FormWizard.StepTitle = StepTitle;
FormWizard.Header = Header;
FormWizard.NavigationControls = NavigationControls;
FormWizard.Next = NextButton;
FormWizard.Previous = PreviousButton;
FormWizard.Submit = SubmitButton;

export default FormWizard;
