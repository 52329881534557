/**
 * IllustrationHistory Messages
 * This contains all the text for the IllustrationHistory container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.IllustrationHistory';

export default defineMessages({
  illustrationHistory: {
    id: `${scope}.illustrationHistory`,
    defaultMessage: 'Illustration History',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  detailsTooltip: {
    id: `${scope}.detailsTooltip`,
    defaultMessage:
      'State | Total Specified Amount Option | Death Benefit Option | Scheduled Premium | Premium Allocation First Index Name: Allocation | Illustration Title',
  },
});
