import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import messages from '../../messages';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import InputFormik from '../../../../components/InputFormik';
import InputMaskA999 from '../../../../components/InputMaskA999';
import { TABLE } from '../../../../constants';
import CurrencyPercentFormat from '../../../../components/CurrencyPercentFormat/index';
import DownshiftFormikField from '../../../../components/DownshiftFormik';
import {
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_A99,
} from '../../../../components/DownshiftFormik/constants';
import PropTypes from 'prop-types';

const Advanced = props => {
  const {
    formikProps,
    arrayHelpers,
    amountColumnLabel,
    increaseColumnLabel,
    stepColumnLabel,
    maxColumnLabel,
    fromColumnLabel,
    thruColumnLabel,
    amountFieldName,
    increaseFieldName,
    stepFieldName,
    maxFieldName,
    fromFieldName,
    thruFieldName,
    items,
    isReadOnly,
    decimalScale,
    maxRows,
  } = props;
  const intl = useIntl();

  const isAdvancedView = formikProps.values.isAdvanced;

  const toggleAdvancedTabled = () => {
    formikProps.setFieldValue('isAdvanced', !formikProps.values.isAdvanced);
  };

  return (
    <>
      <Table>
        <Hidden xsDown>
          <TableHead>
            <TableRow>
              <TableCell align="left">{amountColumnLabel}</TableCell>
              {isAdvancedView && (
                <React.Fragment>
                  <TableCell align="left">{increaseColumnLabel}</TableCell>
                  <TableCell align="left">{stepColumnLabel}</TableCell>
                  <TableCell align="left">{maxColumnLabel}</TableCell>
                </React.Fragment>
              )}

              <TableCell align="left">{fromColumnLabel}</TableCell>
              <TableCell align="left">{thruColumnLabel}</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
        </Hidden>
        <TableBody>
          {formikProps.values.valuesPopup &&
          formikProps.values.valuesPopup.length > 0
            ? formikProps.values.valuesPopup.map((row, index) => (
              <TableRow
                  key={`${index.toString()}-${formikProps.values.valuesPopup.length.toString()}`}
                >
                <Hidden smUp>
                  <TableCell align="left">{amountColumnLabel}</TableCell>
                </Hidden>
                <TableCell className="face-amount-cell" align="left">
                  <DownshiftFormikField
                      name={`valuesPopup[${index}][${amountFieldName}]`}
                      initialInputValue={row[amountFieldName]}
                      customInputType={DOWNSHIFT_OPTION_CURRENCY}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
                      decimalScale={decimalScale}
                      // Dropdown options
                      items={items}
                      isReadOnly={isReadOnly}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          formikProps.setFieldValue(
                            `valuesPopup[${index}][${amountFieldName}]`,
                            value,
                          );
                        } else {
                          // default object
                          formikProps.setFieldValue(
                            `valuesPopup[${index}][${amountFieldName}]`,
                            '',
                          );
                        }
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                </TableCell>
                {isAdvancedView && (
                <React.Fragment>
                  <Hidden smUp>
                    <TableCell align="left">
                      {increaseColumnLabel}
                    </TableCell>
                  </Hidden>
                  <TableCell align="left">
                    <InputFormik
                          name={`valuesPopup[${index}][${increaseFieldName}]`}
                          numberFormatCustom={CurrencyPercentFormat}
                          maxLength={12}
                          decimalscale={2}
                          isErrorMessageDisplayed={false}
                          returntypevalue="formattedValue"
                        />
                  </TableCell>
                  <Hidden smUp>
                    <TableCell align="left">{stepColumnLabel}</TableCell>
                  </Hidden>
                  <TableCell align="left">
                    <InputFormik
                          name={`valuesPopup[${index}][${stepFieldName}]`}
                          numberFormatCustom={CurrencyFormat}
                          maxLength={12}
                          decimalscale={0}
                          isErrorMessageDisplayed={false}
                        />
                  </TableCell>
                  <Hidden smUp>
                    <TableCell align="left">{maxColumnLabel}</TableCell>
                  </Hidden>
                  <TableCell align="left">
                    <InputFormik
                          name={`valuesPopup[${index}][${maxFieldName}]`}
                          numberFormatCustom={CurrencyFormat}
                          maxLength={12}
                          decimalscale={0}
                          isErrorMessageDisplayed={false}
                        />
                  </TableCell>
                </React.Fragment>
                  )}
                <Hidden smUp>
                  <TableCell align="left">{fromColumnLabel}</TableCell>
                </Hidden>
                <TableCell align="left">
                  <InputFormik
                      name={`valuesPopup[${index}][${fromFieldName}]`}
                      numberFormatCustom={InputMaskA999}
                      isErrorMessageDisplayed={false}
                    />
                </TableCell>
                <Hidden smUp>
                  <TableCell align="left">{thruColumnLabel}</TableCell>
                </Hidden>
                <TableCell align="left">
                  <DownshiftFormikField
                      name={`valuesPopup[${index}][${thruFieldName}]`}
                      initialInputValue={row[thruFieldName]}
                      // Dropdown options
                      customInputType={DOWNSHIFT_OPTION_A99}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_A99]}
                      items={[{ value: 'Max', name: 'Max' }]}
                      handleValueOnChange={value => {
                        formikProps.setFieldValue(
                          `valuesPopup[${index}][${thruFieldName}]`,
                          value,
                        );
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                </TableCell>
                <Hidden xsDown>
                  <TableCell align="left">
                    <IconButton
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label="delete"
                        size="medium"
                      >
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell align="left">
                    <Link onClick={() => arrayHelpers.remove(index)}>
                      {intl.formatMessage(messages.remove)}
                    </Link>
                  </TableCell>
                </Hidden>
              </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
      <Grid
        container
        direction="row"
        justify={
          formikProps.values.valuesPopup.length < maxRows
            ? 'space-between'
            : 'flex-end'
        }
        alignItems="center"
      >
        {formikProps.values.valuesPopup.length < maxRows && (
          <Button
            color="primary"
            onClick={() =>
              arrayHelpers.push({
                [amountFieldName]: '',
                [increaseFieldName]: '',
                [stepFieldName]: '',
                [maxFieldName]: '',
                [fromFieldName]: '',
                [thruFieldName]: '',
              })
            }
          >
            <AddCircleOutlineTwoToneIcon />
            {intl.formatMessage(messages.addNew, { type: amountColumnLabel })}
          </Button>
        )}

        <Button
          color="primary"
          className="advanced-button"
          onClick={toggleAdvancedTabled}
        >
          {isAdvancedView
            ? intl.formatMessage(messages.standard)
            : intl.formatMessage(messages.advanced)}
        </Button>
      </Grid>
    </>
  );
};

Advanced.propTypes = {
    formikProps: PropTypes.any,
    arrayHelpers: PropTypes.any,
    amountColumnLabel: PropTypes.any,
    increaseColumnLabel: PropTypes.any,
    stepColumnLabel: PropTypes.any,
    maxColumnLabel: PropTypes.any,
    fromColumnLabel: PropTypes.any,
    thruColumnLabel: PropTypes.any,
    amountFieldName: PropTypes.any,
    increaseFieldName: PropTypes.any,
    stepFieldName: PropTypes.any,
    maxFieldName: PropTypes.any,
    fromFieldName: PropTypes.any,
    thruFieldName: PropTypes.any,
    items: PropTypes.any,
    isReadOnly: PropTypes.any,
    decimalScale: PropTypes.any,
    maxRows: PropTypes.any,
};
Advanced.defaultProps = {
  isOpen: false,
  title: '',
  amountColumnLabel: 'Amount',
  increaseColumnLabel: 'Increase',
  stepColumnLabel: 'Step',
  maxColumnLabel: 'Max',
  fromColumnLabel: 'From year',
  thruColumnLabel: 'Thru year',
  amountFieldName: 'specifiedAmountValue',
  increaseFieldName: 'increase',
  stepFieldName: 'step',
  maxFieldName: 'max',
  fromFieldName: 'specifiedAmountFrom',
  thruFieldName: 'specifiedAmountThru',
  isReadOnly: false,
  decimalScale: 0,
  maxRows: TABLE.MAX_ROWS,
};

export default Advanced;
