import _ from 'lodash';
import { ERRORS_MESSAGES } from '../../../constants';
import { minValue, maxValue } from '../../../utils/validate';
import { ageValidator } from './ageFieldsValidator';
import { isDateFormat, getAgeNearestProduct } from '../../../utils/utils';
/**
 * Validate Insured Form
 * @param {object} values
 * @return {object} Return an object with the list of errors on Insurance form
 */
export const InsuredValidations = (values, settings) => {
  let errors = {};

  if (!values.insured.riskClass) {
    errors.riskClass = true;
  }

  let ageCalculated = null;
  if (isDateFormat(values.insured.issueAgeOrDateOfBirth)) {
    const age = getAgeNearestProduct(
      settings.serverDateTime,
      values.insured.issueAgeOrDateOfBirth,
    );
    ageCalculated = isNaN(age) ? null : age;
  } else {
    ageCalculated = values.insured.issueAgeOrDateOfBirth;
  }

  const applyflatExtra =
    values.insured.riskClass === 'Standard Tobacco' ||
    (values.insured.riskClass === 'Standard Nontobacco' && ageCalculated > 14);

  if (!values.insured.issueState) {
    errors.issueState = true;
  }

  if (!values.insured.gender) {
    errors.gender = true;
  }

  // Issue AGE
  errors = ageValidator(values, settings, errors);
  if (!values.insured.taxBracket.length) {
    errors.taxBracket = true;
  } else {
    if (values.insured.taxBracket.length > 0) {
      const taxBracket = values.insured.taxBracket[0];
      if (
        !_.isNumber(taxBracket.taxBracketRate) ||
        _.isNull(taxBracket.taxBracketRate)
      ) {
        errors.taxBracket = true;
      } else {
        const taxBracketRate = Number(taxBracket.taxBracketRate);
        if (_.isNaN(taxBracketRate) || _.isNull(taxBracketRate)) {
          errors.taxBracket = true;
        }
        if (taxBracketRate || taxBracketRate === 0) {
          const min = minValue(0)(taxBracketRate);
          const max = maxValue(100)(taxBracketRate);
          if (min) {
            errors.taxBracket = `${ERRORS_MESSAGES.MIN_VALUE} 0%`;
          }
          if (max) {
            errors.taxBracket = `${ERRORS_MESSAGES.MAX_VALUE} 100%`;
          }
        }
      }
    }
  }

  if (values.insured.rating) {
    if (!values.insured.tableRating) {
      errors.tableRating = true;
    }
    if (applyflatExtra) {
      if (!values.insured.flatExtra.length) {
        errors.flatExtra = true;
      } else {
        if (values.insured.flatExtra.length > 0) {
          const flatExtra = values.insured.flatExtra[0];
          if (
            !_.isNumber(flatExtra.flatExtraAmount) ||
            _.isNull(flatExtra.flatExtraAmount)
          ) {
            errors.flatExtra = true;
          } else {
            const flatExtraAmount = Number(flatExtra.flatExtraAmount);
            if (_.isNaN(flatExtraAmount) || _.isNull(flatExtraAmount)) {
              errors.flatExtra = true;
            }
            if (flatExtraAmount || flatExtraAmount === 0) {
              const min = minValue(0)(flatExtraAmount);
              const max = maxValue(25)(flatExtraAmount);
              if (min) {
                errors.flatExtra = `${ERRORS_MESSAGES.MIN_VALUE} $0`;
              }
              if (max) {
                errors.flatExtra = `${ERRORS_MESSAGES.MAX_VALUE} $25`;
              }
            }
          }
        }
      }
    }
  }

  if (_.isEmpty(errors)) {
    return {};
  }

  return { insured: { ...errors } };
};
