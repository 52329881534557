import _ from 'lodash';
import { minValue, maxValue } from '../../../utils/validate';
import { MAX_NUMBER, CURRENT, CREDITING_RATE } from '../../../constants';
import {
  errorOnCoreStratety,
  errorOnPlusStratety,
  errorOnCoreStratetySP500,
  errorOnCoreStratety2SP500,
  errorOnCoreStratetyGoldman
} from './strategyValidator';
import {
  isCurrencyFormat,
  isPercentFormat,
  getNumbersAndDecimalsOnly,
} from '../../../utils/utils';
export const InterestRateValidations = values => {
  let errors = {};

  //Interest Rate
  if (
    !values.interestRateAndIncome.premiumAllocation.length ||
    values.interestRateAndIncome.premiumAllocation.find(index => index.total !== 100)
  ) {
    errors.premiumAllocation = true;
  }

  if (
    !values.interestRateAndIncome.maturedSegmentAllocation.length ||
    values.interestRateAndIncome.maturedSegmentAllocation.find(index => index.total !== 100)
  ) {
    errors.maturedSegmentAllocation = true;
  }

  if (
    !values.interestRateAndIncome.fixedCreditingRate ||
    values.interestRateAndIncome.fixedCreditingRate.length === 0
  ) {
    errors.fixedCreditingRate = true;
  } else {
    if (values.interestRateAndIncome.fixedCreditingRate.length > 0) {
      const fixedCreditingRate =
        values.interestRateAndIncome.fixedCreditingRate[0];
      if (_.isNull(fixedCreditingRate.creditRate) ||
        fixedCreditingRate.creditRate === '') {
        errors.fixedCreditingRate = true;
      } else {
        if (fixedCreditingRate.creditRate !== CURRENT) {
          const creditRate = fixedCreditingRate.creditRate;
          if (creditRate || creditRate === 0) {
            const min = minValue(CREDITING_RATE.MIN_VALUE)(creditRate);
            const max = maxValue(CREDITING_RATE.MAX_VALUE)(creditRate);
            if (min) {
              errors.fixedCreditingRate = true;
            }
            if (max) {
              errors.fixedCreditingRate = true;
            }
          }
        }
      }
    }
  }

  // Core Indexed Interest Strategies
  let coreIndexedInterestStrategiesErrors = {};
  const _1YrCappedSP500 =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrCappedSP500;
  if (!_1YrCappedSP500 || _1YrCappedSP500.length === 0) {
    coreIndexedInterestStrategiesErrors._1YrCappedSP500 = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratetySP500(_1YrCappedSP500);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._1YrCappedSP500 = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }

  const _2YrSP500 =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrSP500;
  if (!_2YrSP500 || _2YrSP500.length === 0) {
    coreIndexedInterestStrategiesErrors._2YrSP500 = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratety2SP500(_2YrSP500);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._2YrSP500 = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }

  const _1YrJPMorganMozaicII =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrJPMorganMozaicII;
  if (!_1YrJPMorganMozaicII || _1YrJPMorganMozaicII.length === 0) {
    coreIndexedInterestStrategiesErrors._1YrJPMorganMozaicII = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratety(_1YrJPMorganMozaicII);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._1YrJPMorganMozaicII = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }

  const _2YrJPMorganMozaicII =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrJPMorganMozaicII;
  if (!_2YrJPMorganMozaicII || _2YrJPMorganMozaicII.length === 0) {
    coreIndexedInterestStrategiesErrors._2YrJPMorganMozaicII = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratety(_2YrJPMorganMozaicII);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._2YrJPMorganMozaicII = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }

  const _1YrNYSEZebraEdge =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrNYSEZebraEdge;
  if (!_1YrNYSEZebraEdge || _1YrNYSEZebraEdge.length === 0) {
    coreIndexedInterestStrategiesErrors._1YrNYSEZebraEdge = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratety(_1YrNYSEZebraEdge);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._1YrNYSEZebraEdge = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }

  const _2YrNYSEZebraEdge =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrNYSEZebraEdge;
  if (!_2YrNYSEZebraEdge || _2YrNYSEZebraEdge.length === 0) {
    coreIndexedInterestStrategiesErrors._2YrNYSEZebraEdge = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratety(_2YrNYSEZebraEdge);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._2YrNYSEZebraEdge = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }
  const _1YrHighPar5GoldmanSachsNewHorizons =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrHighPar5GoldmanSachsNewHorizons;
  if (!_1YrHighPar5GoldmanSachsNewHorizons || _1YrHighPar5GoldmanSachsNewHorizons.length === 0) {
    coreIndexedInterestStrategiesErrors._1YrHighPar5GoldmanSachsNewHorizons = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratetyGoldman(_1YrHighPar5GoldmanSachsNewHorizons);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._1YrHighPar5GoldmanSachsNewHorizons = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }
  const _1YrGoldmanSachsNewHorizons =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrGoldmanSachsNewHorizons;
  if (!_1YrGoldmanSachsNewHorizons || _1YrGoldmanSachsNewHorizons.length === 0) {
    coreIndexedInterestStrategiesErrors._1YrGoldmanSachsNewHorizons = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratetyGoldman(_1YrGoldmanSachsNewHorizons);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._1YrGoldmanSachsNewHorizons = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }
  const _2YrGoldmanSachsNewHorizons =
    values.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrGoldmanSachsNewHorizons;
  if (!_2YrGoldmanSachsNewHorizons || _2YrGoldmanSachsNewHorizons.length === 0) {
    coreIndexedInterestStrategiesErrors._2YrGoldmanSachsNewHorizons = true;
    errors.coreNewHeightsIndexedInterestStrategies = {
      ...coreIndexedInterestStrategiesErrors,
    };
  } else {
    const strategyError = errorOnCoreStratetyGoldman(_2YrGoldmanSachsNewHorizons);
    if (strategyError) {
      coreIndexedInterestStrategiesErrors._2YrGoldmanSachsNewHorizons = strategyError;
      errors.coreNewHeightsIndexedInterestStrategies = {
        ...coreIndexedInterestStrategiesErrors,
      };
    }
  }
  // Choice Plus Indexed Interest Strategies
  let choicePlusIndexedStrategiesErrors = {};

  const _1YrChoicePlusJPMorganMozaicII =
    values.interestRateAndIncome.choicePlusIndexedStrategies
      ._1YrChoicePlusJPMorganMozaicII;
  if (
    !_1YrChoicePlusJPMorganMozaicII ||
    _1YrChoicePlusJPMorganMozaicII.length === 0
  ) {
    choicePlusIndexedStrategiesErrors._1YrChoicePlusJPMorganMozaicII = true;
    errors.choicePlusIndexedStrategies = {
      ...choicePlusIndexedStrategiesErrors,
    };
  } else {
    const strategyError = errorOnPlusStratety(_1YrChoicePlusJPMorganMozaicII);
    if (strategyError) {
      choicePlusIndexedStrategiesErrors._1YrChoicePlusJPMorganMozaicII = strategyError;
      errors.choicePlusIndexedStrategies = {
        ...choicePlusIndexedStrategiesErrors,
      };
    }
  }

  const _1YrChoicePlusNYSEZebraEdge =
    values.interestRateAndIncome.choicePlusIndexedStrategies
      ._1YrChoicePlusNYSEZebraEdge;
  if (
    !_1YrChoicePlusNYSEZebraEdge ||
    _1YrChoicePlusNYSEZebraEdge.length === 0
  ) {
    choicePlusIndexedStrategiesErrors._1YrChoicePlusNYSEZebraEdge = true;
    errors.choicePlusIndexedStrategies = {
      ...choicePlusIndexedStrategiesErrors,
    };
  } else {
    const strategyError = errorOnPlusStratety(_1YrChoicePlusNYSEZebraEdge);
    if (strategyError) {
      choicePlusIndexedStrategiesErrors._1YrChoicePlusNYSEZebraEdge = strategyError;
      errors.choicePlusIndexedStrategies = {
        ...choicePlusIndexedStrategiesErrors,
      };
    }
  }

  //income
  if (values.interestRateAndIncome.incomeDistributionAmounts.length === 0) {
    errors.incomeDistributionAmounts = true;
  } else {
    if (values.interestRateAndIncome.incomeDistributionAmounts.length > 0) {
      const incomeDistributionAmounts =
        values.interestRateAndIncome.incomeDistributionAmounts[0];
      if (_.isNull(incomeDistributionAmounts.distributionAmount)) {
        errors.incomeDistributionAmounts = true;
      } else {
        const unformattedValue = getNumbersAndDecimalsOnly(
          incomeDistributionAmounts.distributionAmount,
        );
        if (unformattedValue || unformattedValue === 0) {
          const distributionAmount = Number(unformattedValue);
          let min = null;
          let max = null;
          // currency
          if (isCurrencyFormat(incomeDistributionAmounts.distributionAmount)) {
            min = minValue(0)(distributionAmount);
            max = maxValue(MAX_NUMBER.MAX_VALUE)(distributionAmount);
          }
          if (isPercentFormat(incomeDistributionAmounts.distributionAmount)) {
            min = minValue(0)(distributionAmount);
            max = maxValue(100)(distributionAmount);
          }
          if (min) {
            errors.incomeDistributionAmounts = true;
          }
          if (max) {
            errors.incomeDistributionAmounts = true;
          }
        }
      }
    }
  }

  if (!values.interestRateAndIncome.incomeLoanType.length) {
    errors.incomeLoanType = true;
  }

  if (!values.interestRateAndIncome.incomeWithdrawalCap) {
    errors.incomeWithdrawalCap = true;
  }
  if (!values.interestRateAndIncome.incomeLoanInterestPaymentMethod) {
    errors.incomeLoanInterestPaymentMethod = true;
  }
  if (!values.interestRateAndIncome.incomeDistributionMode) {
    errors.incomeDistributionMode = true;
  }

  if (_.isEmpty(errors)) {
    return {};
  }
  return { interestRateAndIncome: { ...errors } };
};
