import React, { useState, useEffect } from 'react';
import { useResize } from 'react-browser-hooks';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import HintSVG from './hint-svg';
import variables from '../../themeVars';

function arrowGenerator() {
  return {
    opacity: 1,
    '@media only screen and (max-width: 600px) and (orientation: portrait)': {
      maxWidth: 280,
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      '&::before': {
        top: '-4px',
        position: 'relative',
        borderWidth: '0 1.7em 1.7em 1.7em',
        borderColor:
          `transparent transparent ${variables.tooltipArrowColor} transparent`,
        zIndex: '999',
      },
      '&::after': {
        top: '-15px',
        position: 'relative',
        borderWidth: '0 1.7em 1.7em 1.7em',
        borderColor:
          `transparent transparent ${variables.tooltipArrowColor} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      '&::before': {
        top: '11px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 0 1.7em',
        borderColor: `${variables.tooltipArrowColor} transparent transparent transparent`,
      },
      '&::after': {
        top: '2px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 0 1.7em',
        borderColor:
          `${variables.tooltipArrowColor} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.85em',
      '&::before': {
        position: 'relative',
        right: '8px',
        borderWidth: '1.7em 1.7em 1.7em 0',
        borderColor:
          `transparent ${variables.tooltipArrowColor} transparent transparent`,
        zIndex: '999',
      },
      '&::after': {
        top: '-20px',
        right: '9px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 1.7em 0',
        borderColor:
          `transparent ${variables.tooltipArrowColor} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.80em',
      '&::before': {
        left: '6px',
        position: 'relative',
        borderWidth: '1.7em 0 1.7em 1.7em',
        borderColor:
          `transparent transparent transparent ${variables.tooltipArrowColor}`,
        zIndex: '999',
      },
      '&::after': {
        top: '-20px',
        left: '8px',
        position: 'relative',
        borderWidth: '1.7em 0 1.7em 1.7em',
        borderColor:
          `transparent transparent transparent ${variables.tooltipArrowColor}`,
      },
    },
  };
}

const styles = () => ({
  wrapper: {
    display: 'inline',
    position: 'relative',
    top: '-6px',
    '&.right-hint': {
      position: 'absolute',
      right: 0,
      top: '-2px',
    }
  },

  lightTooltip: {
    backgroundColor: variables.lightTooltipBg,
    opacity: 1,
    border: variables.lightTooltipBorder,
    color: variables.lightTooltipTextColor,
    fontSize: 12,
    minWidth: 350,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 9,
  },

  darkTooltip: {
    backgroundColor: variables.darkTooltipBg,
    opacity: 0.95,
    color: variables.darkTooltipContrastText,
    fontSize: 12,
    width: 350,
    maxWidth: 350,
    padding: 10,
    fontWeight: 'normal',
    '& .subtitle': {
      fontFamily: variables.fontMedium,
      marginBottom: '3px',
    },
    '& ul': {
      paddingLeft: 20,
    },
  },

  mobileTooltip: {
    opacity: 0.95,
    backgroundColor: variables.darkTooltipBg,
    color: variables.darkTooltipContrastText,
    fontSize: 11,
    width: 298,
    maxWidth: 298,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 9,
    '& .subtitle': {
      fontFamily: variables.fontMedium,
      marginBottom: '3px',
    },
    '& ul': {
      paddingLeft: 20,
    },
  },

  smallTooltip: {
    width: 175,
  },

  tooltipTop: {
    margin: '-2px -1px',
  },

  tooltipLeft: {
    margin: '0',
  },

  tooltioRight: {
    margin: '0',
  },

  backgroundWhite: {
    opacity: 1,
  },
  arrowPopper: arrowGenerator(),

  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      transition: 'opacity 2s linear',
    },
    '&::before, &::after': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
});

const Hint = ({ text, position, fillColor, classes, popperProps, isInTitle, top, rightAlign }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsOpen(true);
  };

  const handleArrowRef = node => {
    setArrowRef(node);
  };

  const { width } = useResize();

  useEffect(() => {
    handleTooltipClose();
  }, [width]);

  let mobileTooltip = width < 768;
  let smallTooltip = width < 567;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={[classes.wrapper, 'hint-wrapper', isInTitle ? 'hint-in-title' : 'hint-not-in-title', rightAlign ? 'right-hint' : ''].join(' ')}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: arrowRef,
                  element: arrowRef,
                },
              },
            },
            ...popperProps
          }}
          title={
            <div>
              {text}
              <span className={classes.arrow} ref={handleArrowRef} />
            </div>
          }
          placement={position}
          onClose={handleTooltipClose}
          open={isOpen}
          disableFocusListener
          leaveTouchDelay={5000}
          classes={{
            tooltip: [
              !mobileTooltip ? classes.darkTooltip : classes.mobileTooltip,
              smallTooltip ? classes.smallTooltip : '',
            ].join(' '),
            popper: classes.arrowPopper,
            tooltipPlacementTop: classes.tooltipTop,
            tooltipPlacementLeft: classes.tooltipLeft,
            tooltipPlacementRight: classes.tooltioRight,
          }}
        >
          <IconButton
            disableRipple
            onClick={handleTooltipOpen}
            onMouseOver={handleTooltipOpen}
            style={{ backgroundColor: 'transparent', cursor: 'default', top: `${top}px`}}
          >
            <HintSVG fill={fillColor} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

Hint.propTypes = {
  text: PropTypes.object.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  fillColor: PropTypes.string,
  smallTooltip: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  popperProps: PropTypes.object,
  isInTitle: PropTypes.bool,
  top: PropTypes.number,
  rightAlign: PropTypes.bool,
};

Hint.defaultProps = {
  position: 'top',
  fillColor: variables.hintIconFill,
  popperProps: {},
  isInTitle: false,
  top: 0,
  rightAlign: false,
};

export default withStyles(styles)(Hint);
