import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import InputFormik from '../../../../components/InputFormik';
import messages from '../../messages';
import InputMaskA999 from '../../../../components/InputMaskA999';
import { TABLE } from '../../../../constants';
import DownshiftFormikField from '../../../../components/DownshiftFormik';
import {
  DOWNSHIFT_OPTION_A99,
  DOWNSHIFT_OPTION_CURRENCY,
} from '../../../../components/DownshiftFormik/constants';
import PropTypes from 'prop-types';

const AmountFromThru = props => {
  const {
    formikProps,
    arrayHelpers,
    amountColumnLabel,
    fromColumnLabel,
    thruColumnLabel,
    amountFieldName,
    fromFieldName,
    thruFieldName,
    inputFormat,
    customInputTypeOptions,
    isReadOnly,
    dropdownOptions,
    maxRows,
  } = props;

  const intl = useIntl();

  return (
    <>
      <Table>
        <Hidden xsDown>
          <TableHead>
            <TableRow>
              <TableCell align="left">{amountColumnLabel}</TableCell>
              <TableCell align="left">{fromColumnLabel}</TableCell>
              <TableCell align="left">{thruColumnLabel}</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
        </Hidden>
        <TableBody>
          {formikProps.values.valuesPopup &&
          formikProps.values.valuesPopup.length > 0
            ? formikProps.values.valuesPopup.map((row, index) => (
              <TableRow
                  key={`${index.toString()}-${formikProps.values.valuesPopup.length.toString()}`}
                >
                <Hidden smUp>
                  <TableCell align="left">{amountColumnLabel}</TableCell>
                </Hidden>
                <TableCell align="left" className="fixed-crediting-rate">
                  <DownshiftFormikField
                      name={`valuesPopup[${index}][${amountFieldName}]`}
                      initialInputValue={row[amountFieldName]}
                      customInputType={inputFormat}
                      customInputTypeOptions={customInputTypeOptions}
                      // Dropdown options
                      items={dropdownOptions}
                      isReadOnly={isReadOnly}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          formikProps.setFieldValue(
                            `valuesPopup[${index}][${amountFieldName}]`,
                            value,
                          );
                        } else {
                          // default object
                          formikProps.setFieldValue(
                            `valuesPopup[${index}][${amountFieldName}]`,
                            '',
                          );
                        }
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                </TableCell>
                <Hidden smUp>
                  <TableCell align="left">{fromColumnLabel}</TableCell>
                </Hidden>
                <TableCell align="left">
                  <InputFormik
                      name={`valuesPopup[${index}][${fromFieldName}]`}
                      numberFormatCustom={InputMaskA999}
                      isErrorMessageDisplayed={false}
                    />
                </TableCell>
                <Hidden smUp>
                  <TableCell align="left">{thruColumnLabel}</TableCell>
                </Hidden>
                <TableCell align="left">
                  <DownshiftFormikField
                      name={`valuesPopup[${index}][${thruFieldName}]`}
                      initialInputValue={row[thruFieldName]}
                      // Dropdown options
                      customInputType={DOWNSHIFT_OPTION_A99}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_A99]}
                      items={[{ value: 'Max', name: 'Max' }]}
                      handleValueOnChange={value => {
                        formikProps.setFieldValue(
                          `valuesPopup[${index}][${thruFieldName}]`,
                          value,
                        );
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                </TableCell>
                <Hidden xsDown>
                  <TableCell align="left">
                    <IconButton
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label="delete"
                      >
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell align="left">
                    <Link onClick={() => arrayHelpers.remove(index)}>
                      {intl.formatMessage(messages.remove)}
                    </Link>
                  </TableCell>
                </Hidden>
              </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
      {formikProps.values.valuesPopup.length < maxRows && (
        <Button
          color="primary"
          onClick={() =>
            arrayHelpers.push({
              [amountFieldName]: '',
              [fromFieldName]: '',
              [thruFieldName]: '',
            })
          }
        >
          <AddCircleOutlineTwoToneIcon />
          {intl.formatMessage(messages.addNew, { type: amountColumnLabel })}
        </Button>
      )}
    </>
  );
};

AmountFromThru.propTypes = {
    formikProps: PropTypes.any,
    arrayHelpers: PropTypes.any,
    amountColumnLabel: PropTypes.any,
    fromColumnLabel: PropTypes.any,
    thruColumnLabel: PropTypes.any,
    amountFieldName: PropTypes.any,
    fromFieldName: PropTypes.any,
    thruFieldName: PropTypes.any,
    inputFormat: PropTypes.any,
    customInputTypeOptions: PropTypes.any,
    isReadOnly: PropTypes.any,
    dropdownOptions: PropTypes.any,
    maxRows: PropTypes.any,
};
AmountFromThru.defaultProps = {
  isOpen: false,
  amountColumnLabel: 'Amount',
  fromColumnLabel: 'From year',
  thruColumnLabel: 'Thru year',
  rateFieldName: 'indexStrategyRate',
  fromFieldName: 'indexStrategyFrom',
  thruFieldName: 'indexStrategyThru',
  maxLength: 6,
  inputFormat: DOWNSHIFT_OPTION_CURRENCY,
  firstColumnShowDownshiftComponent: false,
  dropdownOptions: [],
  maxRows: TABLE.MAX_ROWS,
};

export default AmountFromThru;
