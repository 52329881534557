import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const MonthYearfield = ({ onChange, ...rest }) => (
  <InputMask {...rest} mask="99-99" maskChar={null} onChange={onChange} />
);

MonthYearfield.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MonthYearfield;
