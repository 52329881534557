import { MAX } from '../../../constants';
import { getPercentFormat } from '../../../utils/utils';

const handleCustomValueOnPopupSave = inputValue =>
  inputValue ? `${getPercentFormat(inputValue)}...` : '';

const handleIsCustomizedValue = value => {
  if (!value.length) return false;
  if (value.length > 1) return true;
  const fieldValue = value[0];
  if (
    fieldValue.indexStrategyFrom !== '1' ||
    fieldValue.indexStrategyThru !== MAX
  ) {
    return true;
  }
  return false;
};

const FromThruIndexes = {
  handleCustomValueOnPopupSave,
  handleIsCustomizedValue,
};

export default FromThruIndexes;
