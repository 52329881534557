/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ErrorMessage } from 'formik';

const InputComponent = ({
  field,
  maxLength,
  form: { touched, errors, setFieldValue },
  extraIcon,
  label,
  numberFormatCustom,
  decimalscale,
  isErrorMessageDisplayed,
  returntypevalue,
  handleBeforeOnChange,
  handleAfterOnChange,
  handleOnKeyUp,
  handleOnKeyDown,
  handleOnKeyPress,
  handleOnBlur,
  handleOnFocus,
  inputClass,
  labelClassName,
  ...props
}) => {

  const ExtraIcon = extraIcon;
  const NumberFormatCustom = numberFormatCustom;
  const hasErrors = !!(getIn(touched, field.name) && getIn(errors, field.name));

  const handleTextChange = (event) => {
    if (handleBeforeOnChange) {
      handleBeforeOnChange(event.currentTarget.value, field.value);
    }
    
    setFieldValue(field.name, event.currentTarget.value);

    if (handleAfterOnChange) {
      handleAfterOnChange(event.currentTarget.value, field.value);
    }
  };

  return (
    <div>
      <FormControl variant="filled" fullWidth disabled={props.disabled}>
        <InputLabel
          error={hasErrors}
          shrink={!!field.value || field.value === 0}
          className={!props.className ? `${labelClassName}` : `${props.className} ${labelClassName}`}
        >
          {label}
        </InputLabel>
        <FilledInput
          inputProps={{
            maxLength,
            decimalscale,
            returntypevalue,
            className: inputClass ? inputClass : ''
          }}
          disableUnderline={true}
          {...props}
          {...field}
          inputComponent={!numberFormatCustom ? 'input' : NumberFormatCustom}
          error={hasErrors}
          autoComplete="off"
          onChange={handleTextChange}
          onKeyUp={handleOnKeyUp}
          onKeyDown={handleOnKeyDown}
          onKeyPress={handleOnKeyPress}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
        {!!extraIcon && (
          <div className="extra-icon-input">
            <ExtraIcon />
          </div>
        )}
      </FormControl>
      {isErrorMessageDisplayed && (
        <ErrorMessage component="span" name={field.name} />
      )}
    </div>
  );
};

InputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  maxLength: PropTypes.number,
  decimalScale: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  extraIcon: PropTypes.any,
  inputClass: PropTypes.string,
  label: PropTypes.any,
  numberFormatCustom: PropTypes.any,
  decimalscale: PropTypes.any,
  isErrorMessageDisplayed: PropTypes.bool,
  returntypevalue: PropTypes.any,
  handleBeforeOnChange: PropTypes.any,
  handleAfterOnChange: PropTypes.any,
  handleOnBlur: PropTypes.func,
  handleOnKeyUp: PropTypes.func,
  handleOnKeyDown: PropTypes.func,
  handleOnKeyPress: PropTypes.func,
  handleOnFocus: PropTypes.func,
  labelClassName : PropTypes.string,
};
InputComponent.defaultProps = {
  isErrorMessageDisplayed: true,
};

const FormikInput = props => <Field component={InputComponent} {...props} />;

export default FormikInput;
