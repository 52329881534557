//NW-Visual-Styleguide 1.0
const palette = {
  vibrantBlue:           '#0047bb',
  darkBlue:              '#141b4d',
  mediumBlue:            '#1f74db',
  lightBlue:             '#8cc8e9',
  mint:                  '#6eceb2',
  darkMint:              '#008574',
  seaBlue:               '#72b0bd',
  darkSeaBlue:           '#005670',
  lavender:              '#d7a9e3',
  deepPurple:            '#890c58',
  sunburstOrange:        '#ff9800',
  cherryRed:             '#cb333b',
  lemonYellow:           '#f9e27d',
  darkMustard:           '#c5a900',
  fossil:                '#afa9a0',
  darkFossil:            '#7a7266',
  paleGrey:              '#d0d3d4',
  darkGray:              '#7e7e82',
  charcoal:              '#171717',
  blue:                  '#0067c5',
  deepBlue:              '#266ba7',
  dark:                  '#000',
  white:                 '#ffffff',
  blueAccent:            '#569bbd',
  greenAccent:           '#7eb942',
  grayAccent:            '#818286',
  lightLavender:         '#f0f0f4',
  red:                   '#ff0000',
  grayBox:               '#4d4d4f',
  blueDark:              '#004270',
  greenDark:             '#6ebe4a',
  greenDark2:            '#7eba42',
  smalt:                 '#00368F',
  vibrantBlue50:         'rgba(0, 71, 187, 0.5)',
  mondo95:               'rgba(84, 75, 68, 0.95)',
  mondo80:               'rgba(84, 75, 68, 0.80)',
  silverChalice:         '#a8a8a8',
  alto:                  '#d9d9d9',
  silver80:              'rgba(191, 191, 191, 0.8)',
  bayOfMany:             '#23527c',
  athensGray:            '#edeef0',
  mercury:               '#e6e6e6',
  atoll:                 '#0e567f',
  mineShaft:             '#222',
  whiteSmoke:            'rgb(247, 247, 247)',
  solitude:              'rgb(237, 238, 240)',
  silverFoil:            '#b0b0b0',
  darkMintAlpha50:       '#87bdb6'
}

export default palette;