import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import {
  Grid,
  Toolbar,
  Typography,
  IconButton,
  Link,
} from '@material-ui/core/';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import WhiteLogo from '../../images/logo-white.svg';
import messages from './messages';
import { NEW_PRODUCT } from '../../constants'
import { useAppContext } from '../../containers/App/AppContext';

const Header = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { settings, currentProduct } = useAppContext();
  const [productString, setProductString] = useState('2020');
  const currentPageName = window.location.pathname;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (currentProduct !== NEW_PRODUCT) {
      setProductString('Illustrator');
    }
  }, [currentProduct, setProductString]);

  const isViewHistoryModuleEnabled = settings &&
  settings.featureFlip.viewHistoryModuleEnabled !== 'undefined' &&
  settings.featureFlip.viewHistoryModuleEnabled;

  const isViewHistoryModule = currentPageName === '/view-history';
  const isNewIllustrationModule = currentPageName === '/';
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {!isViewHistoryModule && (
        <MenuItem onClick={handleMobileMenuClose}>
          <Link href="/view-history">
            <Typography>
              <FormattedMessage {...messages.viewHistory} />
            </Typography>
          </Link>
        </MenuItem>
      )}

      {!isNewIllustrationModule && (
        <MenuItem onClick={handleMobileMenuClose}>
          <Link href="/">
            <Typography>
              <FormattedMessage {...messages.newIllustration} />
            </Typography>
          </Link>
        </MenuItem>
      )}
    </Menu>
  );
  return (
    <AppBar position="static" color="primary">
      <Grid container spacing={0} alignItems="center" justify="center">
        <Grid item xs={12} sm={10} md={9} lg={8} xl={6}>
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs md={3} lg={2} className="header-desktop">
              <Link href="/">
                <IconButton>
                  <img src={WhiteLogo} alt="" />
                </IconButton>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
              <Toolbar>
                <Typography variant="subtitle1">
                  <FormattedHTMLMessage {...messages.nationwideIllust} values={{ product: productString }}/>
                </Typography>
                <div className="header-desktop">
                  {isViewHistoryModuleEnabled && !isViewHistoryModule && (
                    <Link href="/view-history">
                      <FormattedMessage {...messages.viewHistory} />
                    </Link>
                  )}

                  {!isNewIllustrationModule && (
                    <Link href="/">
                      <FormattedMessage {...messages.newIllustration} />
                    </Link>
                  )}
                </div>
                <div className="header-mobile">
                  {isViewHistoryModuleEnabled && (
                    <IconButton onClick={handleMobileMenuOpen}>
                      <MoreIcon />
                    </IconButton>
                  )}
                </div>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {renderMobileMenu}
    </AppBar>
  );
};

Header.propTypes = {};

export default Header;
