import { CORE_INTEREST, PLUS_INDEXED_INTEREST, CREDITING_RATE } from '../../constants';

export const START_VALUES = {
  presentedBy: '',
  illustrationTitle: '',
  revisedIllustration: false,
  product: 'Nationwide New Heights Indexed UL Accumulator 2020',
  insured: {
    financePremiums: false,
    issueState: undefined,
    firstName: '',
    lastName: '',
    gender: 'Female',
    issueAgeOrDateOfBirth: 45,
    riskClass: 'Preferred Nontobacco',
    rating: false,
    tableRating: 'None',
    flatExtra: [
      {
        flatExtraAmount: 0,
        flatExtraFrom: '1',
        flatExtraThru: 'Max',
      },
    ],
    taxBracket: [
      {
        taxBracketRate: 30,
        taxBracketFrom: '1',
        taxBracketThru: 'Max',
      },
    ],
  },
  faceAmountAndPremium: {
    totalSpecifiedAmount: [
      {
        specifiedAmountValue: 'Balanced Solve',
        increase: '',
        step: '',
        max: '',
        specifiedAmountFrom: '1',
        specifiedAmountThru: 'Max',
      },
    ],
    increaseInitialDBbyXChk: false,
    increaseInitialDBbyX: '',
    section7702: 'GPT',

    reduceSpecifiedAmount: false,
    deathBenefitOption: [
      {
        benefitOption: 'Optimal Switch',
        benefitFrom: '1',
        benefitThru: 'Max',
      },
    ],
    scheduledPremium: [
      {
        scheduledPremiumAmount: '0',
        scheduledPremiumFrom: '1',
        increase: '',
        step: '',
        max: '',
        scheduledPremiumThru: 'Max',
      },
    ],
    premiumMode: 'Annual',
    monthToBeginScheduledPremium: '1',
    lumpSumPremium: [
      {
        lumpSumPremiumAmount: 0,
        lumpSumPremiumFrom: '1',
        lumpSumPremiumThru: 'Max',
      },
    ],
    lumpSumMonthFirstYear: 1,
    _1035Exchange: false,
    exchangeInfo: {
      exchangeAmount: '',
      loanBalance1035: '',
      exchangeBasis: '',
      existingContractMEC: false,
      month1035Received: 1,
    },
    advancedPremiumFund: false,
    advancedPremiumFundInfo: {
      advancedPremiumFundAmount: '10000',
      advancedPremiumFundAgeOrYear: 4,
      discountRate: 3.5,
    },
    avoidMEC: true,
    targetCashValue: 10000,
    targetDeathBenefitYear: 1,
    targetYear: 'Lifetime',
  },
  interestRateAndIncome: {
    hasIncome: false,
    premiumAllocation: [
      {
        indexInfo: null,
        indexFrom: '1',
        indexThru: 'Max',
        total: 100,
      },
    ],
    maturedSegmentAllocation: [
      {
        indexInfo: null,
        indexFrom: '1',
        indexThru: 'Max',
        total: 100,
      },
    ],
    fixedCreditingRate: [
      {
        creditRate: CREDITING_RATE.DEFAULT,
        creditRateFrom: '1',
        creditRateThru: 'Max',
      },
    ],
    coreNewHeightsIndexedInterestStrategies: {
      _1YrCappedSP500: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT_1_SP500,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _2YrSP500: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT_2_SP500,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _1YrHighPar5GoldmanSachsNewHorizons: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT_GOLDMAN,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _1YrGoldmanSachsNewHorizons: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT_GOLDMAN,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _2YrGoldmanSachsNewHorizons: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT_GOLDMAN,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _1YrJPMorganMozaicII: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _2YrJPMorganMozaicII: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _1YrNYSEZebraEdge: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _2YrNYSEZebraEdge: [
        {
          indexStrategyRate: CORE_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
    },
    choicePlusIndexedStrategies: {
      _1YrChoicePlusJPMorganMozaicII: [
        {
          indexStrategyRate: PLUS_INDEXED_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
      _1YrChoicePlusNYSEZebraEdge: [
        {
          indexStrategyRate: PLUS_INDEXED_INTEREST.DEFAULT,
          indexStrategyFrom: '1',
          indexStrategyThru: 'Max',
        },
      ],
    },
    incomeDistributionAmounts: [
      {
        distributionAmount: '$0',
        amountAction: 'Borrow',
        increase: '',
        step: '',
        max: '',
        amountFrom: 'A66',
        amountThru: 'A80',
      },
    ],
    incomeLoanType: [
      {
        loanType: 'Alternative',
        loanFrom: '1',
        loanThru: 'Max',
      },
    ],
    incomeWithdrawalCap: 'Basis',
    incomeLoanInterestPaymentMethod: 'Borrow',
    incomeDistributionMode: 'Monthly',
  },
  riders: {
    longTermCareRiderII: false,
    longTermCareRiderInfo: {
      maximumMonthlyBenefit: '2%',
      longTermCareAmount: 'Equal to Total Specified Amount',
      rateClass: 'Preferred Nontobacco',
      marriedOrCivilUnionOrDomesticPartnership: true,
      printLTCAnalysisRepot: false,
      LTCAnalysisReportInfo: {
        hipaaPerDiemRate: 3.2,
        ageToBeginLTCRiderBenefit: 70,
        desiredDailyLTCBenefit: 300,
      },
    },
    accidentalDeathBenefit: false,
    adbAmountAndRating: {
      ADBAmount: '0',
      ADBRating: 'None',
    },
    waiverRider: false,
    waiverRiderInfo: {
      waiverType: 'Monthly Deductions',
      premiumAmount: 'Maximum',
      waiverRating: 'None',
    },
    conditionalReturnOfPremium: false,
    returnOfPremium: 'Option A (100% Graded)',
    surrenderValueEnhancementRider: false,
    surrenderValueEnhancementRiderInfo: {
      returnOfPremium: '100% Surrender Charge Waiver, 110% Account Value Multiple',
    },
    changeOfInsuredRiderInfo: false,
  },
  illustrationIndicators: {
    deathBenefitAmount: 100000,
    increaseByPercentage: 0,
    indexInterestStrategyInformation: true,
    generalLedger: true,
    annualCostSummary: true,
    internalRateOfReturnReport: false,
    premiumSummary: true,
    nationwideFinancialStrength: false,
    valueOfLifeInsuranceReport: false,
    commissionSummary: false,
    occReport: false,
    outlayTaxReport: false,
    surrenderChargeReport: false,
    monthlyCostSummary: false,
    whichIllustration: undefined,
    yearsForMonthlyOutput: '',
    monthlyCostOfInsuranceReport: false,
    costAndBenefitReport: false,
    netCostPerDollar: false,
    costOfMoney: '',
    iulAsRothIRASupplement: false,
    insuranceBasedIncomeSolution: false,
    rangeOfPossibleOutcomes: false,
    basicTermLifeInsurance: {
      includeTermLifeQuote: false,
      faceAmount: 0,
      premiumMode : 'Annual'
    },
  },
  prepopulateData: false,
};
