import { createMuiTheme } from '@material-ui/core/styles';
import variables from './themeVars';

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

export default createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    primary: {
      main: variables.primaryColor,
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: variables.secondaryColor,
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    success: {
      main: '#008574',
      contrastText: '#fff'
    },
    info: {
      main: '#141b4d',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: variables.headerSecondary,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: variables.fontRegular,
    fontSize: 16,
  },
  overrides: {
    MUIAnnexusFormWizard: {
      root: {},
      stepNavigation: {
        padding: '1px 24px',
        marginBottom: 15,
        color: '#b6b6b6',
        '&.is-visited': {
          cursor: 'pointer',
          color: '#253746',
          '&:hover': {
            color: '#0072CF'
          }
        },
        '&.is-active': {
          cursor: 'pointer',
          color: variables.primaryColor,
        },
        '@media (min-width: 1279.95px) and (max-width: 1370px)': {
          padding: '1px 20px',
        },
        [defaultTheme.breakpoints.down('md')]: {
          padding: '1px 13px',
          fontSize: '16px',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '1px 8px',
          fontSize: '14px',
        },
      },
      header: {
        margin: '36px 0 34px auto',
        '& h4': {
          fontSize: '46px',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          margin: '42px 0px 7px',
          '& h4': {
            fontSize: 35,
          },
        },
      },
      stepTitle: {
        padding: '32px 0 15px',
        borderTop: '3px solid #0047bb',
        '& .hint-in-title' : {
          top: '0px !important',
        },
        '& .hint-not-in-title' : {
          top: '-4px !important',
        },
      },
      navigationControls: {
        margin: '43px 0px 160px 0px',
        [defaultTheme.breakpoints.down('sm')]: {
          margin: '30px 0px 65px 0px',
        },
        '& button': {
          fontFamily: variables.fontMedium,
          margin: '0px 24px 0px 0px',
          minHeight: 38,
          minWidth: 114,
          '&:hover': {
            color: variables.btnContrastText,
            backgroundColor: variables.primaryColor,
          },
          '&:first-child': {
            minWidth: 208,
            color: variables.btnContrastText,
            backgroundColor: variables.nextFormBtnColor,
            '&:hover': {
              backgroundColor: variables.nextFormBtnHover,
            },
          },
          [defaultTheme.breakpoints.down('sm')]: {
            margin: '0px 0px 15px 0px',
            minWidth: '100%',
            '&:first-child': {
              minWidth: '100%',
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: '0px',
        '& .input-check-box': {
          width: '100%',
        },
        '& .deathBenefitAmount': {
          width: '100% !important',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .extra-icon': {
          top: 'calc(50% - 14px)',
          position: 'absolute',
          right: '35px',
        },
        '& .extra-icon-input': {
          top: 'calc(50% - 14px)',
          position: 'absolute',
          right: '10px',
        },
        '& .deathBenefitAmount': {
          width: '100%',
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
        '&$error': {
          border: '1px solid red',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$focused': {
          backgroundColor: 'transparent',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        '&.isDropdown input': {
          cursor: 'pointer',
        },
        '&.input-check-box': {
          border: 'none !important',
          width: '100%',
          backgroundColor: 'white !important'
        },
        '& .deathBenefitAmount': {
          width: '100% !important',
        },
      },
      input: {
        paddingBottom: '0.35714em',
        paddingTop: '1.5em',
      },
      adornedEnd: {
        paddingRight: 0,
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
          paddingRight: 12,
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        lineHeight: 1.18,
        '&$error': {
          border: '1px solid red',
        },
        '&$focused': {
          border: '1px solid #2A9FD8',
        },
        '& .icons-container': {
          top: 'calc(50% - 13px)',
          right: '0',
          color: 'rgba(0, 0, 0, 0.54)',
          pointerEvents: 'none',
          position: 'absolute',
          '& .arrow': {
            display: 'inline',
            borderLeft: '1px solid #d9d9d9',
            paddingTop: '16px',
            marginLeft: '5px',
          },
        },
        '& .input-check-box': {
          border: 'none !important',
          backgroundColor: 'white !important',
          paddingLeft: 0,
          '&:disabled': {
            backgroundColor: 'white !important',
            width: '195px',
            cursor: 'pointer'
          }
        },
      },
      input: {
        backgroundColor: 'white',
        borderRadius: '4px',
        color: '#333333',
        fontSize: '15px',
        '&:disabled': {
          backgroundColor: '#eeeeee',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#737373;',
        fontSize: '14px',
        width: '89%',
        letterSpacing: '-0.2px',
        [defaultTheme.breakpoints.down('xs')]: {
          width: '84%',
          lineHeight: '12px',
          fontSize: 13,
        },
        '&$focused': {
          color: '#767676',
          lineHeight: '0.9',
          width: '100%',
          transform: 'translate(12px, 8px) scale(0.75) !important',
        },
        '&$shrink': {
          color: '#767676',
          lineHeight: '0.9',
          width: '100%',
        },
        '&$disabled': {
          color: '#767676',
        },
        '&$filled': {
          transform: 'translate(12px, 18px) scale(1)',
          '&&.MuiInputLabel-shrink': {
            transform: 'translate(12px, 8px) scale(0.75)',
          },
        },
        '&.input-check-box': {
          width: '100% !important',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '15px',
          paddingBottom: '1px',
          transform: 'translate(0, 18px) scale(1)',
          '&&.MuiInputLabel-shrink': {
            transform: 'translate(1px, 8px) scale(0.75) !important',
          },
          '&$focused': {
            transform: 'translate(1px, 8px) scale(0.75) !important',
          },
          '&:disabled': {
            cursor: 'pointer'
          }
        },
        '&.customLabelSelect' :{
          color : '#2566e8',
          '&.Mui-error' : {
          color : '#f44336'
          },
        },
      },
    },
    MuiSelect: {
      select: {
        borderRadius: '4px',
        '&$filled': {
          backgroundColor: 'white',
          borderRadius: '4px',
        },
        '&$disabled': {
          color: '#b6b6b6 !important',
        },
        '& .option-changed': {
          outline: 'none',
          borderColor: '#9ecaed',
          boxShadow: '0 0 10px #9ecaed',
        },
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: variables.primaryColor,
      },
      colorSecondary: {
        color: '#919191',
      },
    },
    MuiList: {
      root: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        '&.open-border': {
          borderRadius: '4px 4px 0 0',
          borderBottom: 'none',
        },
        '&.link': {
          backgroundColor: '#fafafa',
          '& a': {
            textDecoration: 'underline',
            '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
              position: 'relative',
              top: 8,
            },
          },
        },
        '&.error': {
          border: '1px solid red'
        }
      },
      padding: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: 4,
        paddingTop: 4,
        minHeight: 46,
        borderBottom: 'solid 1px #d9d9d9',
        backgroundColor: '#ffffff',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        '&:nth-last-child(1)': {
          borderBottom: 'none',
          borderRadius: '4px',
        },
        '& .label-checkBox': {
          fontSize: '15px',
          position: 'relative',
          top: '2px',
          cursor: 'pointer',
          '@media (min-width: 1279.95px) and (max-width: 1370px)': {
            fontSize: '13px',
            top: '0px',
          },
        },
        '& .label-radioButton': {
            fontSize: '15px',
            position: 'relative',
            top: '2px',
            cursor: 'pointer',
            '@media (min-width: 1279.95px) and (max-width: 1370px)': {
                fontSize: '13px',
                top: '0px',
            },
        },
        '&.list-container': {
          backgroundColor: '#fafafa',
          '& .MuiFilledInput-adornedEnd': {
            backgroundColor: '#ffffff',
          },
          '&.padding-space': {
            paddingTop: '30px',
            paddingBottom: '22px',
          }
        },
        '&.Mui-disabled': {
          '& .label-checkBox': {
            cursor: 'default',
          },
        },
        '& .hint-wrapper .MuiIconButton-root': {
          top: 4,
        },
        '&.container-input-check-box': {
          height: '40px !important',
          '& > div': {
            width: '100%',
          },
        },
        '& > div > span': {
          display: 'flex',
          marginLeft: '1px',
          color: 'rgb(227, 44, 43)',
          fontSize: '0.7em',
          letterSpacing: '0.15px',
          lineHeight: '13px',
          height: 0,
          position: 'relative',
          top: '2px'
        },
      },
    },
    MuiGrid: {
      root: {
        '&.list-container': {
          padding: '25px 25px',
          [defaultTheme.breakpoints.down('xs')]: {
            padding: '10px 0px',
          },
        },
        '&.sub-title': {
          paddingTop: '20px !important',
        },
        '& .list-item': {
          padding: '8px',
        },
      },
      item: {
        '& > div > span': {
          display: 'flex',
          marginLeft: '14px',
          color: 'rgb(227, 44, 43)',
          fontSize: '0.7em',
          letterSpacing: '0.15px',
          lineHeight: '13px',
          height: 0,
        },
        '& span.rpv-zoom__popover-target': {
          fontSize: '1rem',
        }
      },
    },
    MuiLink: {
      root: {
        color: variables.primaryColor,
        cursor: 'pointer',
        fontSize: '15px',
        '& svg': {
          position: 'relative',
          top: '3px',
          marginRight: '10px',
        },
      },
    },
    MuiTypography: {
      subtitle1: {
        fontFamily: variables.fontMedium,
        fontSize: 16,
        fontWeight: 'unset',
      },
      root: {
        '&.input-note': {
          fontSize: '12px',
          paddingLeft: '12px',
          letterSpacing: '-0.7px',
          marginTop: 2,
        },
        '&.hasErrors': {
          color: '#f44336',
        },
        '&.input-link': {
          fontSize: '12px',
          paddingLeft: '5px',
        },
        '&&.rider-tooltip': {
          '& [class*="MuiTooltip-tooltip"]': {
            width: 168,
          },
        },
        [defaultTheme.breakpoints.down('xs')]: {
          '&&[class*="h5"]': {
            fontSize: '1.3rem',
          },
          '&&[class*="h6"]': {
            fontSize: '1.2rem',
          },
        },
        '& .hint-in-title' : {
          top: '0px !important',
        },
        '& .hint-not-in-title' : {
          top: '-4px !important',
        },
      },
      h1: {
        fontSize: '40px',
        fontFamily: variables.fontHeadlineSemibold,
      },
      h2: {
        fontSize: '24px'
      }
    },
    MuiAppBar: {
      root: {
        cursor: 'default',
        '& .header-desktop': {
          display: 'block',
          '& a': {
            lineHeight: '15px',
            [defaultTheme.breakpoints.down('md')]: {
              fontSize: 13,
              minWidth: 105,
              textAlign: 'right',
            },
            '&:nth-child(2)': {
              marginLeft: 20,
            },
            '& button': {
              backgroundColor: 'transparent',
            },
          },
        },
        [defaultTheme.breakpoints.down('sm')]: {
          '& .header-desktop': {
            display: 'none',
          },
        },
        '& button': {
          float: 'right',
          minWidth: 180,
          [defaultTheme.breakpoints.down('sm')]: {
            minWidth: 'initial',
          },
          '& img': {
            marginTop: '-5px',
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'space-between',
        fontFamily: variables.fontMedium,
        lineHeight: '28.08px',
        '& .header-desktop': {
          display: 'flex',
        },
        '& .header-mobile': {
          display: 'none',
        },
        '& a': {
          color: 'white',
          fontFamily: variables.fontRegular,
        },
        [defaultTheme.breakpoints.down('md')]: {
          '& [class*="subtitle1"]': {
            fontSize: 16,
          },
        },
        [defaultTheme.breakpoints.down('sm')]: {
          '& .header-desktop': {
            display: 'none',
          },
          '& .header-mobile': {
            display: 'block',
          },
          '& [class*="subtitle1"]': {
            fontSize: 'large',
          },
        },
        '& button': {
          color: 'inherit',
        },
      },
      regular: {
        minHeight: '0px !important',
        padding: '0px 0px 0px 16px',
        [defaultTheme.breakpoints.down('sm')]: {
          minHeight: 0,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
      gutters: {
        [defaultTheme.breakpoints.down('md')]: {
          paddingLeft: 16,
          paddingRight: 0,
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        border: `1px solid ${variables.primaryColor}`
      },
      root: {
        textTransform: 'Capitalize',
        boxShadow: 'none !important',
        fontFamily: variables.fontMedium
      },
    },
    MuiDialog: {
      root: {
        '& .MuiDivider-root ': {
          backgroundColor: '#141b4d',
          margin: '0 24px',
        },
      },
      paper: {
        border: '6px solid rgba(191, 191, 191, 0.8)',
        borderRadius: 3,
        [defaultTheme.breakpoints.down('md')]: {
          margin: 20,
        },
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
          '&&.MuiDialog-paperWidthSm': {
            maxWidth: 710,
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 24px 8px 24px',
        fontFamily: variables.fontMedium,
        fontSize: '24px',
        color: '#141b4d',
        letterSpacing: '0',
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: 24,
        '& .MuiButton-label svg': {
          margin: '0 5px',
          stroke: 'ghostwhite',
          strokeWidth: 0.8,
        },
        '& .MuiButton-textPrimary': {
          color: variables.primaryColor,
          cursor: 'pointer',
          padding: '10px 12px 8px 5px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: '8px 0px',
        '& button': {
          maxWidth: 365,
        },
        '& .MuiButton-root': {
          width: '50%',
        },
        '& .MuiButton-outlinedPrimary':{
          '&:hover': {
            backgroundColor: variables.primaryColor,
            color: variables.btnContrastText
          },
          '&.Mui-disabled': {
            color: variables.primaryBtnDisabled
          }
        },
        '& .MuiButton-containedSecondary':{
          '&:hover': {
            backgroundColor: variables.secondaryBtnHover,
            color: variables.btnContrastText
          },
          '&.Mui-disabled': {
            color: variables.secondaryBtnDisabled
          }
        },
        '& .MuiButton-containedPrimary': {
            backgroundColor: variables.primaryColor,
            '&:hover': {
                backgroundColor: variables.primaryBtnHover,
            },
            '&.Mui-disabled': {
                backgroundColor: variables.primaryBtnDisabled
            }
        },
      },
      spacing: {
        '& > * + *': {
          marginLeft: 24,
        },
      },
    },
    MuiTable: {
      root: {
        marginBottom: 10,
      },
    },
    MuiTableBody: {
      root: {
        '& .MuiIconButton-root': {
          color: variables.primaryColor,
          [defaultTheme.breakpoints.down('xs')]: {
            padding: 0,
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        [defaultTheme.breakpoints.down('md')]: {
          padding: '14px 0px 14px 16px',
          '&&:nth-last-child(2)': {
            minWidth: '120px',
          },
        },
      },
    },
    MuiPaper: {
      root: {
        '&&.dropdown-style': {
          position: 'absolute',
          zIndex: 100,
          width: '100%',
          borderRadius: '0',
          maxHeight: '160px',
          overflowY: 'auto',
          '& .MuiMenuItem-root': {
            fontSize: 15,
            minHeight: 0,
            padding: '0 6px',
            lineHeight: '1.29',
            transition: 'none',
            border: 0,
            backgroundColor: 'transparent',
            '&:hover, &:focus, &:active, &:checked': {
              backgroundColor: '#1e90ff',
              color: 'white',
              borderRadius: 0,
            },
          },
        },
        '& .dropdown-style-item': {
          justifyContent: 'center !important',
          width: '40px',
          backgroundColor: 'transparent',
          '&:hover, &:focus, &:active, &:checked': {
            backgroundColor: '#1e90ff',
          },
        },
        '& .selectedCheck': {
          width: '3%',
          fontSize: '15px',
          textAlign: 'left',
        },
        '& .item': {
          width: '97%'
        }
      },
    },
    MuiInputAdornment: {
      root: {
        '& button': {
          '&:hover': {
            background: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            borderLeft: '1px solid #d9d9d9',
            borderRadius: 0,
            height: 32,
            paddingLeft: 2,
            marginRight: 1,
          },
        },
        '& button:nth-child(2)': {
          padding: '3px 0px',
        },
        '& button:nth-child(1)': {
          marginRight: 4,
          '& .MuiSvgIcon-root': {
            marginRight: '-6px',
          },
        },
      },
    },
    MuiSnackbar: {
      root: {
        '&&.error-type .MuiSnackbarContent-root': {
          backgroundColor: '#ff5252',
        },
        '& .MuiIconButton-root': {
          top: 10,
          right: 14,
          position: 'absolute',
          height: 31,
          width: 31,
          padding: '0px 0px 3px',
          fontSize: 20,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        paddingRight: 48,
        backgroundColor: 'rgba(52, 96, 148, 1)',
      },
      message: {
        '& > div': {
          padding: '4px 0px',
          lineHeight: '16px',
        },
      },
    },
    MUIDataTableHeadCell: {
      sortActive: {
        color: '#fff',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: '#fff !important',
      },
    },
    MUIDataTableSearch: {
      main: {
        display: 'flex',
        flex: '1 0 auto',
        '& button': {
          marginTop: '-4px',
        },
      },
      searchIcon: {
        display: 'none',
        marginTop: '15px',
        marginRight: '8px',
      },
      searchText: {
        flex: '0.8 0',
        top: 2,
        margin: '-1px 0 0px',
        '& div': {
          '&::before, &::after': {
            borderBottom: 0,
          },
        },
        '& div:hover': {
          '&::before, &::after': {
            border: 'none !important',
          },
        },
        '& input': {
          padding: '10px',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          flex: '1 0',
        },
      },
      clearIcon: {
        marginLeft: '5px',
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MUIDataTable: {
      paper: {
        '&&.iconSearchActive': {
          '& button[title=Search]': {
            color: `${variables.primaryColor} !important`,
          },
        },
        '&&.iconSearchClose': {
          '& button[title=Search]': {
            color: 'rgba(0, 0, 0, 0.54) !important',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        maxHeight: 56,
        padding: 0,
        marginBottom: 10,
      },
      actions: {
        textAlign: 'right',
      },
      icon: {
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      [defaultTheme.breakpoints.down('xs')]: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        left: {
          padding: ' 0',
          flexGrow: ' 1',
          flex: ' 1',
        },
        titleText: {
          textAlign: 'center',
        },
        actions: {
          textAlign: 'right',
          display: 'flex',
          flexGrow: 0,
        },
      },
      [defaultTheme.breakpoints.down('sm')]: {
        left: {
          display: 'block',
          padding: '0',
        },
      },
    },
  },
});
