import React from 'react';
import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { EmptyCheckBoxSvg, FilledCheckBoxSvg } from './checkBox-svg';

const ListItemCheckbox = ({
  field,
  form,
  label,
  children,
  disabled,
  ...rest
}) => {
  const handleOnClick = () => {
    if (!disabled) {
      form.setFieldValue(field.name, !field.value);
      form.setFieldTouched(field.name, true);
    }
  };
  return (
    <ListItem onClick={handleOnClick} disabled={disabled}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            color="primary"
            icon={<EmptyCheckBoxSvg />}
            checkedIcon={<FilledCheckBoxSvg />}
            {...field}
            {...rest}
            value=""
            checked={field.value}
          />
        }
      />
      <span className="label-checkBox">{label}</span>
      {children}
    </ListItem>
  );
};

ListItemCheckbox.propTypes = {
  children: PropTypes.any,
  form: PropTypes.object,
  field: PropTypes.object,
  label: PropTypes.string,
  showHelp: PropTypes.bool,
  partialSelect: PropTypes.bool,
  textHint: PropTypes.object,
  hintPosition: PropTypes.string,
  disabled: PropTypes.bool
};

ListItemCheckbox.defaultProps = {
  disabled: false
}

const ListItemCheckboxFormik = props => (
  <Field component={ListItemCheckbox} {...props} />
);

export default ListItemCheckboxFormik;
