/* eslint-disable react/jsx-key */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { FormControl, InputLabel, Paper, MenuItem } from '@material-ui/core';
import { ErrorMessage, getIn, Field, isObject } from 'formik';
import { renderInput } from './shared';
import {
  DOWNSHIFT_OPTION_A99,
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_INPUT,
  DOWNSHIFT_OPTION_PERCENTAGE,
} from './constants';
import TableDialog from '../../containers/Illustration/components/TableDialog';

const CUSTOM_OPTIONS = [
  DOWNSHIFT_OPTION_A99,
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_PERCENTAGE,
];

const DownshiftFormik = ({
  customInputType,
  customInputTypeOptions,
  decimalScale,
  field,
  form,
  handleCustomValueOnPopupSave,
  handleIsCustomizedValue,
  handleValueOnChange,
  hasPopup,
  ignoreValueFromItems,
  initialInputValue,
  isAdvanced,
  isErrorMessageDisplayed,
  isReadOnly,
  isCenter,
  items,
  label,
  maxLength,
  popupComponent,
  popupInitialValuesHanlder,
  popupOnSaveHandler,
  popupTitle,
  popupValidations,
  returnTypeValue,
  size,
  tableProps,
  defaultDownshiftCurrencyValue
}) => {
  const PopupComponent = popupComponent;
  // functions
  const isDropdown = !!items && items.length > 0;
  const hasError = !!(
    getIn(form.touched, field.name) && getIn(form.errors, field.name)
  );
  // Hooks
  const [currentValue, setCurrentValue] = useState(
    initialInputValue !== '' ? initialInputValue : '',
  );
  const [typeOfInput, setTypeOfInput] = useState(customInputType || 'input');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCustomizedValue, setIsCustomizedValue] = useState(false);
  const [isAutoFocus, setIsAutoFocus] = useState(false);

  useEffect(() => {
    if (handleIsCustomizedValue) {
      setIsCustomizedValue(handleIsCustomizedValue(field.value));
    }
  }, [field.value, handleIsCustomizedValue]);

  // Set empty current value when initialInputValue is modified and is empty
  useEffect(() => {
    if (initialInputValue !== currentValue) {
      if (initialInputValue === '') {
        resetValues();
      } else {
        setCurrentValue(initialInputValue);
      }
    }
  }, [initialInputValue]);

  // Search initialValue on Items
  useEffect(() => {
    const getItems = filter => {
      if (ignoreValueFromItems) {
        return [];
      }
      if (Number(filter) || isObject(filter)) {
        return [];
      }
      return items.filter(
        item => item.value.toUpperCase() === filter.toUpperCase(),
      );
    };
    if (isDropdown && initialInputValue && !ignoreValueFromItems) {
      const itm = getItems(initialInputValue);
      if (itm.length) {
        setCurrentValue(itm[0]);
        setTypeOfInput(DOWNSHIFT_OPTION_INPUT);
      } else {
        // set correct input type if there are multiple options
        if (customInputTypeOptions && customInputTypeOptions.length > 1) {
          if (/\$\d[.,]?/.test(initialInputValue)) {
            setTypeOfInput(DOWNSHIFT_OPTION_CURRENCY);
          }
          if (/\d+(%)/.test(initialInputValue)) {
            setTypeOfInput(DOWNSHIFT_OPTION_PERCENTAGE);
          }
          if (/^[aA]?[0-9]{1,3}$/.test(initialInputValue)) {
            setTypeOfInput(DOWNSHIFT_OPTION_A99);
          }
        } else {
          if (/\d[.,]?/.test(initialInputValue)) {
            if (customInputType === DOWNSHIFT_OPTION_CURRENCY)
              setTypeOfInput(DOWNSHIFT_OPTION_CURRENCY);
            if (customInputType === DOWNSHIFT_OPTION_PERCENTAGE)
              setTypeOfInput(DOWNSHIFT_OPTION_PERCENTAGE);
          }
        }
      }
    }
  }, [
    customInputType,
    customInputTypeOptions,
    ignoreValueFromItems,
    initialInputValue,
    isDropdown,
    items,
    typeOfInput,
  ]);

  const resetValues = () => {
    setCurrentValue('');
    setIsCustomizedValue(false);
    setTypeOfInput(customInputType);
    setIsAutoFocus(false);
    form.setFieldTouched(field.name, true);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleLocalStateChange = (changes, downshiftHelper) => {
    if (
      changes.hasOwnProperty('selectedItem') &&
      (changes.type === Downshift.stateChangeTypes.clickItem ||
        changes.type === Downshift.stateChangeTypes.keyDownEnter)
    ) {
      if (changes.selectedItem) {
        switch (changes.selectedItem.value) {
          case DOWNSHIFT_OPTION_CURRENCY:
            downshiftHelper.setState({
              inputValue: defaultDownshiftCurrencyValue,
            })
            handleValueOnChange(defaultDownshiftCurrencyValue);
            setCurrentValue(defaultDownshiftCurrencyValue);  
            setTypeOfInput(DOWNSHIFT_OPTION_CURRENCY);
            setIsAutoFocus(true);
            break;
          case DOWNSHIFT_OPTION_PERCENTAGE:
            setTypeOfInput(DOWNSHIFT_OPTION_PERCENTAGE);
            handleValueOnChange('');
            setCurrentValue('');
            setIsAutoFocus(true);
            break;
          case DOWNSHIFT_OPTION_A99:
            setTypeOfInput(DOWNSHIFT_OPTION_A99);
            handleValueOnChange('');
            setCurrentValue('');
            setIsAutoFocus(true);
            break;
          default:
            setTypeOfInput(DOWNSHIFT_OPTION_INPUT);
            handleValueOnChange(changes.selectedItem.value);
            setCurrentValue(changes.selectedItem.value);
            setIsAutoFocus(false);
            break;
        }

        downshiftHelper.setState({
          isOpen: false
        })
      }
    } else if (changes.type === Downshift.stateChangeTypes.changeInput && typeOfInput !== DOWNSHIFT_OPTION_INPUT) {
      if (changes.hasOwnProperty('inputValue')) {
        if (changes.inputValue === '') {
          setCurrentValue('');
          handleValueOnChange('');
          form.setFieldTouched(field.name, true);
        } else {
          const value = changes.inputValue == null ? '' : changes.inputValue;
          setCurrentValue(value);
          handleValueOnChange(value);
          form.setFieldTouched(field.name, true);
        }
      }

      if (downshiftHelper.isOpen) {
        downshiftHelper.setState({
          isOpen: false
        })
      }
    }

    // Clear option
    if (
      changes.type === Downshift.stateChangeTypes.unknown &&
      changes.inputValue === '' &&
      changes.selectedItem === null
    ) {
      handleValueOnChange('');
      setCurrentValue('');
    }
  };

  const getItems = () => {
    let customItems = items;
    if (!customInputTypeOptions) {
      return customItems;
    }
    customInputTypeOptions.map(item => {
      if (item === typeOfInput && currentValue !== '' && !isCustomizedValue) {
        return item;
      }
      if (item === DOWNSHIFT_OPTION_CURRENCY) {
        customItems = [
          {
            name: 'Specified Amount',
            value: 'DOWNSHIFT_OPTION_CURRENCY',
          },
          ...customItems,
        ];
      }
      if (item === DOWNSHIFT_OPTION_PERCENTAGE) {
        customItems = [
          {
            name: 'Specified Percentage',
            value: 'DOWNSHIFT_OPTION_PERCENTAGE',
          },
          ...customItems,
        ];
      }
      if (item === DOWNSHIFT_OPTION_A99) {
        customItems = [
          {
            name: 'Specified Age/Year',
            value: 'DOWNSHIFT_OPTION_A99',
          },
          ...customItems,
        ];
      }
      return item;
    });
    return customItems;
  };

  const handleValueOnPopupSave = inputValue => {
    if (handleCustomValueOnPopupSave) {
      return handleCustomValueOnPopupSave(inputValue);
    }
    return `${inputValue}...`;
  };

  return (
    <>
      <Downshift
        selectedItem={currentValue}
        onStateChange={handleLocalStateChange}
        itemToString={item => {
          // is an option from dropdown
          if (typeof item === 'object') {
            if (CUSTOM_OPTIONS.indexOf(item.value) !== -1) {
              return '';
            }
            return item.name;
          }
          // empty value
          if (!item && item !== 0) {
            return '';
          }
          return item;
        }}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
        }) => {
          const isShrunk =
            !!label &&
            ((isOpen && !isReadOnly) || !!inputValue || inputValue === 0);

          return (
            <div>
              <FormControl variant="filled" fullWidth>
                {label && (
                  <InputLabel
                    error={hasError}
                    shrink={isShrunk}
                    variant="filled"
                    {...getLabelProps()}
                  >
                    {label}
                  </InputLabel>
                )}
                {renderInput({
                  customInputType: customInputType,
                  clearSelection: clearSelection,
                  isCustomizedValue: isCustomizedValue,
                  handleValueOnPopupSave: handleValueOnPopupSave,
                  inputType: typeOfInput,
                  readOnly: isReadOnly,
                  error: hasError,
                  openMenu: openMenu,
                  inputProps: {
                    returntypevalue: returnTypeValue,
                    ...getInputProps({
                      name: field.name,
                      maxLength,
                      onBlur: field.onBlur,
                      decimalscale: decimalScale,
                      autoFocus: isAutoFocus,
                    }),
                  },
                  hasPopup: hasPopup,
                  isDropdown: isDropdown,
                  handleOpenPopup: handleOpenPopup,
                  toggleButtonProps: { ...getToggleButtonProps() },
                })}
                {/* Dropdown */}
                <div>
                  {isDropdown && isOpen ? (
                    <Paper className="dropdown-style" {...getMenuProps()}>
                      {getItems().map((item, index) => {
                        const isSelectedItem =
                          selectedItem.value === item.value;
                        return (
                          <MenuItem
                            {...getItemProps({
                              key: `${item.value}.${index}`,
                              item,
                              index: index,
                            })}
                            selected={highlightedIndex === index || isSelectedItem}
                            component='div'
                          >
                            {isCenter ? (
                              <MenuItem className='dropdown-style-item'>
                                {isSelectedItem ? <span className='selectedCheck'>{'\u2714\ufe0e'}</span>
                                  : <span className='selectedCheck'>{''}</span>}
                                <span className='item'>{item.name}</span>
                              </MenuItem>
                            ) : (
                              <>
                                {isSelectedItem ? <span className='selectedCheck'>{'\u2714\ufe0e'}</span>
                                  : <span className='selectedCheck'>{''}</span>}
                                {' '}
                                <span className='item'>{item.name}</span>
                              </>
                            )}
                          </MenuItem>
                        );
                      })}
                    </Paper>
                  ) : null}
                </div>
              </FormControl>
              {isErrorMessageDisplayed && (
                <ErrorMessage component="span" name={field.name} />
              )}
            </div>
          );
        }}
      </Downshift>
      {hasPopup && isPopupOpen && (
        <TableDialog
          isOpen={isPopupOpen}
          title={popupTitle}
          size={size}
          name={field.name}
          initialValues={
            popupInitialValuesHanlder
              ? popupInitialValuesHanlder(field.value)
              : field.value
          }
          isAdvanced={isAdvanced}
          handleValidateForm={popupValidations}
          handleOnClose={() => {
            setIsPopupOpen(false);
          }}
          handleOnSave={(values, isAdvanced) => {
            // update state if advanced table
            if (popupOnSaveHandler) {
              popupOnSaveHandler(values, field, form, isAdvanced);
            } else {
              // set values
              form.setFieldValue(field.name, values);
              form.setFieldTouched(field.name, true);
            }
          }}
          render={({ formikProps, arrayHelpers, isAdvanced }) => (
            // The render prop gives us { formikProps, arrayHelpers }
            // to render whatever we want here.
            <PopupComponent
              {...tableProps}
              formikProps={formikProps}
              arrayHelpers={arrayHelpers}
              isAdvanced={isAdvanced}
            />
          )}
        />
      )}
    </>
  );
};

DownshiftFormik.propTypes = {
  hasPopup: PropTypes.bool.isRequired,
  ignoreValueFromItems: PropTypes.bool.isRequired,
  isAdvanced: PropTypes.bool.isRequired,
  isCurrencyFormat: PropTypes.bool.isRequired,
  isErrorMessageDisplayed: PropTypes.bool.isRequired,
  isPercentageFormat: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isCenter: PropTypes.bool,
  popupInitialValuesHanlder: PropTypes.func,
  popupOnSaveHandler: PropTypes.func,
  returnTypeValue: PropTypes.string,
  customInputTypeOptions: PropTypes.arrayOf(PropTypes.oneOf(CUSTOM_OPTIONS)),
  popupComponent: PropTypes.func,
  handleValueOnChange: PropTypes.func.isRequired,
  defaultDownshiftCurrencyValue: PropTypes.any,
  customInputType: PropTypes.any,
  decimalScale: PropTypes.any,
  field: PropTypes.any,
  form: PropTypes.any,
  handleCustomValueOnPopupSave: PropTypes.any,
  handleIsCustomizedValue: PropTypes.any,
  initialInputValue: PropTypes.any,
  items: PropTypes.any,
  label: PropTypes.any,
  maxLength: PropTypes.any,
  popupTitle: PropTypes.any,
  popupValidations: PropTypes.any,
  size: PropTypes.any,
  tableProps: PropTypes.any,
};

DownshiftFormik.defaultProps = {
  hasPopup: false,
  ignoreValueFromItems: false,
  isAdvanced: false,
  isCurrencyFormat: false,
  isErrorMessageDisplayed: false,
  isPercentageFormat: false,
  isReadOnly: false,
  isCenter:false,
  popupInitialValuesHanlder: null,
  popupOnSaveHandler: null,
  returnTypeValue: undefined,
  defaultDownshiftCurrencyValue: '',
};

const DownshiftFormikField = props => (
  <Field component={DownshiftFormik} {...props} />
);

export default DownshiftFormikField;
