import React from 'react';

const HintSvg = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g id="ico-tooltip" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M8.87746558,0.000835288932 C3.90799504,0.0685010006 -0.0659559985,4.15144684 0.00082960492,9.12093356 C0.0676152083,14.0904203 4.14985373,18.0650977 9.11934793,17.9991921 C14.0888421,17.9332865 18.064239,13.8517484 17.9992135,8.88223836 C17.9679724,6.49466822 16.9892267,4.21737144 15.2784278,2.5516496 C13.5676289,0.885927772 11.2650167,-0.0316742805 8.87746558,0.000835288932 Z M9.77172332,3.01365869 C10.0999367,2.95793814 10.4328743,3.07458072 10.6723313,3.32918099 C10.9117883,3.58378126 11.0304433,3.94728994 10.9932972,4.31248484 C10.9804357,4.78586281 10.7922008,5.2325428 10.4727767,5.5476763 C10.1533525,5.8628098 9.73086735,6.01864645 9.30449041,5.97860694 C8.96396412,6.05020627 8.61287138,5.93986363 8.35716513,5.68087829 C8.10145887,5.42189295 7.97109257,5.04460251 8.00540826,4.66286585 C8.03241194,4.18074065 8.23639775,3.73181258 8.56999585,3.42033226 C8.90359396,3.10885194 9.33777875,2.96192055 9.77172332,3.01365869 Z M7.58759479,15 C7.01302742,15 6.59303908,14.5983733 6.99455571,12.8303183 L7.65273187,9.68910391 C7.7674509,9.18650961 7.78689481,8.98569626 7.65273187,8.98569626 C7.16827912,9.12391999 6.70792895,9.35689788 6.29165857,9.6745197 L6,9.13153835 C7.03845183,8.04302045 8.3166313,7.30554441 9.69434182,7 C10.2689092,7 10.3641843,7.7853036 10.0773867,8.98906184 L9.32587984,12.2895807 C9.1917169,12.8718272 9.25004861,13.0748843 9.38323935,13.0748843 C9.85998844,12.9530237 10.3033995,12.6975724 10.6743146,12.3310896 L11,12.8336839 C10.1027213,13.9732622 8.90205861,14.7354856 7.58759479,15 Z" id="Shape" fill="#0047BB" {...props} />
    </g>
  </svg>
);

export default HintSvg;
