import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../../messages';
import CalendarSvg from '../../CalendarSvg';
import { useWizardContext } from '../../../../../components/FormWizard/WizardContext';
import { useAppContext } from '../../../../App/AppContext';
import { PremiumAndMaturedAllocations } from '../../../utils';
import { PremiumAllocation } from '../../Tables';
import { premiumAllocationValidations } from '../../Tables/validators/premiumAllocationValidator';
import DownshiftFormikField from '../../../../../components/DownshiftFormik';

const PremiumAllocationDropDown = () => {
  const { values, setFieldValue } = useWizardContext();
  const intl = useIntl();
  const { settings } = useAppContext();

  const premiumAllocationsPopupCatalog = settings.premiumAllocations.filter(
    item =>
      item.value !== PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyIndex &&
      item.value !== PremiumAndMaturedAllocations.twoYearsPremiumAllocationFiftyFiftyIndex &&
      item.value !== PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyGoldman,
  );

  const handleValueOnChange = value => {
    if (value !== '') {
      const indexInfoData = premiumAllocationsPopupCatalog.map(element => {
        let allocation = '';
        if (
          value === PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyIndex &&
          (element.value === '1-Yr J.P. Morgan Mozaic II' ||
            element.value === '1-Yr NYSE Zebra Edge')
        ) {
          allocation = 50;
        }

        if (
          value === PremiumAndMaturedAllocations.twoYearsPremiumAllocationFiftyFiftyIndex &&
          (element.value === '2-Yr J.P. Morgan Mozaic II' ||
            element.value === '2-Yr NYSE Zebra Edge')
        ) {
          allocation = 50;
        }

        if (value == PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyGoldman &&
          (element.value === '1-Yr Capped S&P 500' ||
          element.value === '1-Yr High Par 5 Goldman Sachs New Horizons')) {
          allocation = 50;
        }
        if (element.value === value) allocation = 100;

        return {
          key: element.value,
          allocation,
        };
      });

      const data = [
        {
          indexInfo: indexInfoData,
          indexFrom: '1',
          indexThru: 'Max',
          total: 100,
        },
      ];

      setFieldValue('interestRateAndIncome.premiumAllocation', data);
    } else {
      const indexInfoData = premiumAllocationsPopupCatalog.map(element => ({
        key: element.value,
        allocation: '',
      }));

      const data = [
        {
          indexInfo: indexInfoData,
          indexFrom: '',
          indexThru: '',
          total: '',
        },
      ];
      setFieldValue('interestRateAndIncome.premiumAllocation', data);
    }
  };

  const handleIsCustomizedValue = value => {
    const defaultRows = 1;
    const defaultIndexWithAllocation = 1;

    if (!value) return false;
    if (!Array.isArray(value)) return true;
    if (value.length > defaultRows) return true;

    const fiftyFiftySelected = PremiumAndMaturedAllocations.fiftyFiftySelectedOption(
      value,
    );

    if (fiftyFiftySelected !== null) return false;

    if (value.length) {
      const listOfInndexesWithAllocation = PremiumAndMaturedAllocations.getIndexWithAllocation(
        value[0].indexInfo,
      );
      if (listOfInndexesWithAllocation.length > defaultIndexWithAllocation) {
        return true;
      }

      if (value[0].indexFrom === '' && value[0].indexThru === '') return false;

      const defaultIndexFrom = '1';
      const defaultIndexThru = 'Max';

      return (
        value[0].indexFrom !== defaultIndexFrom ||
        value[0].indexThru !== defaultIndexThru
      );
    }

    return false;
  };

  const handleCustomValueOnPopupSave = () => {
    const selectedIndex = PremiumAndMaturedAllocations.getInitialInputValueFromIndexInfoNode(
      values.interestRateAndIncome.premiumAllocation,
    );
    if (
      selectedIndex ===
        PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyIndex ||
      selectedIndex ===
      PremiumAndMaturedAllocations.twoYearsPremiumAllocationFiftyFiftyIndex ||
      selectedIndex === PremiumAndMaturedAllocations.oneYearPremiumAllocationFiftyFiftyGoldman
    ) {
      return selectedIndex;
    }

    const indexWithAllocation = PremiumAndMaturedAllocations.getFirstIndexWithAllocation(
      values.interestRateAndIncome.premiumAllocation,
    );

    return indexWithAllocation
      ? `${indexWithAllocation.key}: ${indexWithAllocation.allocation}%...`
      : '';
  };

  return (
    <DownshiftFormikField
      name="interestRateAndIncome.premiumAllocation"
      label={intl.formatMessage(messages.premiumAllocation)}
      initialInputValue={PremiumAndMaturedAllocations.getInitialInputValueFromIndexInfoNode(
        values.interestRateAndIncome.premiumAllocation,
      )}
      isReadOnly
      handleIsCustomizedValue={handleIsCustomizedValue}
      // Popup options
      tableProps={{
        catalogSettings: premiumAllocationsPopupCatalog,
      }}
      hasPopup
      size="xl"
      popupIcon={CalendarSvg}
      popupTitle={intl.formatMessage(messages.premiumAllocation)}
      popupComponent={PremiumAllocation}
      handleCustomValueOnPopupSave={handleCustomValueOnPopupSave}
      popupValidations={values =>
        premiumAllocationValidations(values, {
          rateFieldName: 'indexStrategyRate',
          fromFieldName: 'indexFrom',
          thruFieldName: 'indexThru',
          rateMinValue: 0,
          rateMaxValue: 100,
          totalRequiredValue: 100,
          indexName: intl.formatMessage(messages.premiumAllocation),
        })
      }
      // Dropdown options
      items={settings.premiumAllocations}
      handleValueOnChange={handleValueOnChange}
    />
  );
};

export default PremiumAllocationDropDown;
