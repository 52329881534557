import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ClickAwayListener } from '@material-ui/core';
import {
  getNumbersAndDecimalsOnly,
  splitDecimal,
  limitToScale,
  applyThousandSeparator,
} from '../../utils/utils';

const PercentFormat = ({
  inputRef,
  onChange,
  decimalscale,
  fixedDecimalScale,
  returntypevalue,
  thousandSeparator,
  prefix,
  suffix,
  decimalSeparator,
  ...rest
}) => {

  const formatVal = (values, val) => {
    values.floatValue = +val;
    values.formattedValue = val;
    values.value = val;
  }

  const { value } = { ...rest };
  const [showPrefix, setShowPrefix] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleOnFocus = () => (
    onChange({
      currentTarget: {
        value: currentValue === '0' || currentValue === 0 ? '' : currentValue,
      },
      target: {
        value: currentValue === '0' || currentValue === 0 ? '' : currentValue,
      },
    })
  );

  // values : {formattedValue: "34.40%", value: "34.40", floatValue: 34.4}
  const handleOnChange = values => {
    let value = null;
    let val;
    if (values.formattedValue.length > 3 && +decimalscale === 0) {
      if (+values.formattedValue.substr(0, 1) === 0) {
        val = values.formattedValue.slice(1, 3);
        formatVal(values, val);
      }
      if (values.formattedValue.substr(values.formattedValue.length - 2, 1)) {
        val = values.formattedValue.slice(0, 2);
        formatVal(values, val);
      }
    }
    switch (returntypevalue) {
      case 'formattedValue':
        value = typeof values.formattedValue === 'undefined' ? '' : values.formattedValue;
        break;
      case 'floatValue':
        value = typeof values.floatValue === 'undefined' ? '' : values.floatValue;
        break;
      case 'value':
        value = typeof values.value === 'undefined' ? '' : values.value;
        break;
      default:
        value = values;
        break;
    }
    setCurrentValue(value)
    return onChange({
      currentTarget: {
        value,
      },
      target: {
        value,
      },
    });
  };

  const handleFormat = formattedValue => {
    // if decimal scale is zero remove decimal and number after decimalSeparator
    if (decimalSeparator && decimalscale === 0) {
      formattedValue = formattedValue.split(decimalSeparator)[0];
    }
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
      formattedValue,
      false,
    );

    const hasDecimalSeparator =
      formattedValue.indexOf('.') !== -1 ||
      (decimalscale && fixedDecimalScale && !!afterDecimal);

    if (decimalscale !== undefined && afterDecimal !== '') {
      afterDecimal = limitToScale(
        afterDecimal,
        decimalscale,
        fixedDecimalScale,
      );
    }
    if (thousandSeparator) {
      beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator);
    }
    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal = afterDecimal + suffix; // restore negation sign
    if (addNegation) beforeDecimal = '-' + beforeDecimal;
    return (
      beforeDecimal +
      ((hasDecimalSeparator && decimalSeparator) || '') +
      afterDecimal
    );
  };

  const handleRemoveFormatting = val => getNumbersAndDecimalsOnly(val);
  return (
    <ClickAwayListener onClickAway={() => setShowPrefix(false)}>
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={values => {
          handleOnChange(values);
        }}
        thousandSeparator
        suffix="%"
        inputMode="decimal"
        allowNegative={false}
        decimalScale={decimalscale}
        format={showPrefix ? null : handleFormat}
        removeFormatting={handleRemoveFormatting}
        allowEmptyFormatting={showPrefix}
        onClick={() => setShowPrefix(true)}
        onFocus={() => {
          rest.onFocus();
          handleOnFocus();
          setShowPrefix(true);
        }}
        onBlurCapture={() => setShowPrefix(false)}
      />
    </ClickAwayListener>
  );
};

PercentFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalscale: PropTypes.number,
  returntypevalue: PropTypes.string.isRequired,
  fixedDecimalScale: PropTypes.any,
  thousandSeparator: PropTypes.any,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  decimalSeparator: PropTypes.any
};

PercentFormat.defaultProps = {
  decimalscale: 2,
  fixedDecimalScale: true,
  thousandSeparator: ',',
  returntypevalue: 'floatValue',
  decimalSeparator: '.',
  suffix: '%',
};

export default PercentFormat;
