import React from 'react';
import { useResize } from 'react-browser-hooks';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import Hint from '../../../../../components/Hint';
import messages from '../../../messages';
import { useAppContext } from '../../../../App/AppContext';

const SyledtHint = styled.div`
  .MuiTooltip-tooltipPlacementTop {
    width: 342px;
  }

  @media (max-width: 600px) {
    .MuiTooltip-tooltip {
      width: 280px !important;
    }
  }

  table th, table td {
    text-align: center
  }

  table th:first-child, table td:first-child {
    text-align: left
  }
  table th:nth-child(2), table th:nth-child(3) {
    padding-left: 5px;
    padding-right: 5px;
    min-width: 65px;
  }
`;

const RiskClassHint = () => {
  const { settings } = useAppContext();
    const { width } = useResize();
    const position = width <= 600 ? 'top-end' : 'top';
    return (
      <SyledtHint>
        <Hint
            position={position}
            text={
              <FormattedHTMLMessage
                {...messages.riskClassHint(settings.riskClasses)}
                />
            }
            />
      </SyledtHint>
    )
}

export default RiskClassHint;
