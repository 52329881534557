import React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import messages from '../../../messages';
import CalendarSvg from '../../CalendarSvg';
import { useWizardContext } from '../../../../../components/FormWizard/WizardContext';
import { useAppContext } from '../../../../App/AppContext';
import { PremiumAndMaturedAllocations } from '../../../utils';
import { PremiumAllocation } from '../../Tables';
import { premiumAllocationValidations } from '../../Tables/validators/premiumAllocationValidator';
import DownshiftFormikField from '../../../../../components/DownshiftFormik';
import { MATURED_SEGMENT_ALLOCATION_DEFAULT } from '../../../../../constants';

const MaturedSegmentAllocationDropDown = () => {
  const { values, setFieldValue } = useWizardContext();
  const intl = useIntl();
  const { settings } = useAppContext();

  const realocationIndex = PremiumAndMaturedAllocations.realocationIndex;

  const segmentAllocationsPopupCatalog = settings.maturedSegmentAllocations.filter(
    item => item.value !== realocationIndex,
  );

  const handleValueOnChange = value => {
    if (value !== '') {
      let indexInfoData = [];
      if (value === realocationIndex) {
        indexInfoData.push({
          key: value,
          allocation: 100,
        });
      }
      const catalog = segmentAllocationsPopupCatalog.map(element => ({
        key: element.value,
        allocation: element.value === value ? 100 : '',
      }));

      indexInfoData = [...indexInfoData, ...catalog];
      const data = [
        {
          indexInfo: indexInfoData,
          indexFrom: '1',
          indexThru: 'Max',
          total: 100,
        },
      ];
      setFieldValue('interestRateAndIncome.maturedSegmentAllocation', data);
    } else {
      const indexInfoData = segmentAllocationsPopupCatalog.map(
        (element) => ({
          key: element.value,
          allocation: '',
        }),
      );
      const data = [
        {
          indexInfo: indexInfoData,
          indexFrom: '',
          indexThru: '',
          total: '',
        },
      ];
      setFieldValue('interestRateAndIncome.maturedSegmentAllocation', data);
    }
  };

  const handleIsCustomizedValue = value => {
    const defaultRows = 1;
    const defaultIndexWithAllocation = 1;
    if (!value) return false;
    if (!Array.isArray(value)) return true;
    if (value.length > defaultRows) return true;
    if (value.length) {
      const listOfInndexesWithAllocation = PremiumAndMaturedAllocations.getIndexWithAllocation(
        value[0].indexInfo,
      );

      if (listOfInndexesWithAllocation.length > defaultIndexWithAllocation) {
        return true;
      }

      if (value[0].indexFrom === '' && value[0].indexThru === '') return false;

      const defaultIndexFrom = '1';
      const defaultIndexThru = 'Max';
      return (
        value[0].indexFrom !== defaultIndexFrom ||
        value[0].indexThru !== defaultIndexThru
      );
    }

    return false;
  };

  const handleCustomValueOnPopupSave = () => {
    const indexWithAllocation = PremiumAndMaturedAllocations.getFirstIndexWithAllocation(
      values.interestRateAndIncome.maturedSegmentAllocation,
    );
    return indexWithAllocation
      ? `${indexWithAllocation.key}: ${indexWithAllocation.allocation}%...`
      : '';
  };

  const popupValidations = values =>
    premiumAllocationValidations(values, {
      rateFieldName: 'indexStrategyRate',
      fromFieldName: 'indexFrom',
      thruFieldName: 'indexThru',
      rateMinValue: 0,
      rateMaxValue: 100,
      totalRequiredValue: 100,
      indexName: intl.formatMessage(messages.maturedSegmentAllocation),
    });

  return (
    <DownshiftFormikField
      name="interestRateAndIncome.maturedSegmentAllocation"
      label={intl.formatMessage(messages.maturedSegmentAllocation)}
      initialInputValue={PremiumAndMaturedAllocations.getMaturedSegmentAllocationInitialInputValue(
        values.interestRateAndIncome.maturedSegmentAllocation,
      )}
      isReadOnly
      handleIsCustomizedValue={handleIsCustomizedValue}
      // Popup options
      tableProps={{
        catalogSettings: segmentAllocationsPopupCatalog,
      }}
      popupInitialValuesHanlder={originalValue => {
        if (originalValue.length === 0) return originalValue;

        const value = _.cloneDeep(originalValue);

        if(value[0]?.indexInfo?.some(x => x.key === MATURED_SEGMENT_ALLOCATION_DEFAULT && typeof x.allocation === 'number')) {
          value[0].total = 0;       
        }

        value[0].indexInfo = value[0].indexInfo.filter(
          indexItem =>
            indexItem.key !== PremiumAndMaturedAllocations.realocationIndex,
        );

        return value;
      }}
      hasPopup
      size="xl"
      popupIcon={CalendarSvg}
      popupTitle={intl.formatMessage(messages.maturedSegmentAllocation)}
      popupComponent={PremiumAllocation}
      handleCustomValueOnPopupSave={handleCustomValueOnPopupSave}
      popupValidations={popupValidations}
      // Dropdown options
      items={settings.maturedSegmentAllocations}
      handleValueOnChange={handleValueOnChange}
    />
  );
};

export default MaturedSegmentAllocationDropDown;
