import _ from 'lodash';
import {
  ERRORS_MESSAGES,
  MAX_NUMBER, LIFETIME,
  FASE_AMOUNT_PREMIUM,
  MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT
} from '../../../constants';
import { minValue, maxValue } from '../../../utils/validate';

export const validateA999 = (fieldName, value, errors) => {
  const fromFieldValue = Number(value.replace(/[^0-9.-]+/g, ''));
  // validate empty or zero
  if (!fromFieldValue) {
    errors[fieldName] = true;
  }
  const minVal = minValue(0)(fromFieldValue);
  const maxVal = maxValue(120)(fromFieldValue);
  if (minVal) {
    errors[fieldName] = true;
  }
  if (maxVal) {
    errors[fieldName] = true;
  }
};

export const FaceAmountValidations = values => {
  const errors = {};

  if (!values.faceAmountAndPremium.totalSpecifiedAmount.length) {
    errors.totalSpecifiedAmount = true;
  } else if (values.faceAmountAndPremium.totalSpecifiedAmount.length > 0) {
    const totalSpecifiedAmount =
      values.faceAmountAndPremium.totalSpecifiedAmount[0];
    if (
      !totalSpecifiedAmount.specifiedAmountValue &&
      totalSpecifiedAmount.specifiedAmountValue !== 0
    ) {
      errors.totalSpecifiedAmount = true;
    } else if (_.isNumber(totalSpecifiedAmount.specifiedAmountValue)) {
      const specifiedAmountValue = Number(
        totalSpecifiedAmount.specifiedAmountValue,
      );
      if (_.isNaN(specifiedAmountValue) || _.isNull(specifiedAmountValue)) {
        errors.totalSpecifiedAmount = true;
      }
      if (specifiedAmountValue || specifiedAmountValue === 0) {
        const min = minValue(100000)(specifiedAmountValue);
        const max = maxValue(MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT.MAX_VALUE)(specifiedAmountValue);
        if (min) {
          errors.totalSpecifiedAmount = `${ERRORS_MESSAGES.MIN_VALUE} $100,000`;
        }
        if (max) {
          errors.totalSpecifiedAmount = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT.MAX_STRING}`;
        }
      }
    }
  }

  if (
    values.faceAmountAndPremium.increaseInitialDBbyXChk &&
    values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue ===
    FASE_AMOUNT_PREMIUM.MinNonMec.value &&
    values.faceAmountAndPremium.totalSpecifiedAmount.length === 1
  ) {
    if (
      !values.faceAmountAndPremium.increaseInitialDBbyX &&
      values.faceAmountAndPremium.increaseInitialDBbyX !== 0
    ) {
      errors.increaseInitialDBbyX = true;
    } else {
      const min = minValue(1)(values.faceAmountAndPremium.increaseInitialDBbyX);
      const max = maxValue(100)(
        values.faceAmountAndPremium.increaseInitialDBbyX,
      );
      if (min) {
        errors.increaseInitialDBbyX = `${ERRORS_MESSAGES.MIN_VALUE} 1%`;
      }
      if (max) {
        errors.increaseInitialDBbyX = `${ERRORS_MESSAGES.MAX_VALUE} 100%`;
      }
    }
  }

  if (!values.faceAmountAndPremium.section7702) {
    errors.section7702 = true;
  }

  if (!values.faceAmountAndPremium.deathBenefitOption.length) {
    const deathBenefitOptionErrors = {};
    deathBenefitOptionErrors.benefitOption = true;
    errors.deathBenefitOption = [{ ...deathBenefitOptionErrors }];
  } else if (!values.faceAmountAndPremium.deathBenefitOption[0].benefitOption) {
    const deathBenefitOptionErrors = {};
    deathBenefitOptionErrors.benefitOption = true;
    errors.deathBenefitOption = [{ ...deathBenefitOptionErrors }];
  }

  if (!values.faceAmountAndPremium.scheduledPremium.length) {
    errors.scheduledPremium = true;
  } else if (values.faceAmountAndPremium.scheduledPremium.length > 0) {
    const scheduledPremium = values.faceAmountAndPremium.scheduledPremium[0];
    if (
      !scheduledPremium.scheduledPremiumAmount &&
      scheduledPremium.scheduledPremiumAmount !== 0
    ) {
      errors.scheduledPremium = true;
    } else if (_.isNumber(scheduledPremium.scheduledPremiumAmount)) {
      const scheduledPremiumAmount = Number(
        scheduledPremium.scheduledPremiumAmount,
      );
      if (_.isNaN(scheduledPremiumAmount) || _.isNull(scheduledPremiumAmount)) {
        errors.scheduledPremium = true;
      }
      if (scheduledPremiumAmount) {
        const min = minValue(0)(scheduledPremiumAmount);
        const max = maxValue(MAX_NUMBER.MAX_VALUE)(scheduledPremiumAmount);
        if (min) {
          errors.scheduledPremium = `${ERRORS_MESSAGES.MIN_VALUE} $0`;
        }
        if (max) {
          errors.scheduledPremium = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
        }
      }
    }
  }

  if (!values.faceAmountAndPremium.premiumMode) {
    errors.premiumMode = true;
  }

  if (!values.faceAmountAndPremium.lumpSumPremium.length) {
    errors.lumpSumPremium = true;
  } else if (values.faceAmountAndPremium.lumpSumPremium.length > 0) {
    const lumpSumPremium = values.faceAmountAndPremium.lumpSumPremium[0];
    if (
      !lumpSumPremium.lumpSumPremiumAmount &&
      lumpSumPremium.lumpSumPremiumAmount !== 0
    ) {
      errors.lumpSumPremium = true;
    } else if (_.isNumber(lumpSumPremium.lumpSumPremiumAmount)) {
      const lumpSumPremiumAmount = Number(lumpSumPremium.lumpSumPremiumAmount);
      if (_.isNaN(lumpSumPremiumAmount) || _.isNull(lumpSumPremiumAmount)) {
        errors.lumpSumPremium = true;
      }
      if (lumpSumPremiumAmount) {
        const max = maxValue(MAX_NUMBER.MAX_VALUE)(lumpSumPremiumAmount);
        if (max) {
          errors.lumpSumPremium = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
        }
      }
    }
  }

  if (values.faceAmountAndPremium._1035Exchange) {
    const exchange1035InfoErrors = {};
    if (
      !values.faceAmountAndPremium.exchangeInfo.exchangeAmount &&
      values.faceAmountAndPremium.exchangeInfo.exchangeAmount !== 0
    ) {
      exchange1035InfoErrors.exchangeAmount = true;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    } else if (
      values.faceAmountAndPremium.exchangeInfo.exchangeAmount >
      MAX_NUMBER.MAX_VALUE
    ) {
      exchange1035InfoErrors.exchangeAmount = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    }
    if (
      !values.faceAmountAndPremium.exchangeInfo.loanBalance1035 &&
      values.faceAmountAndPremium.exchangeInfo.loanBalance1035 !== 0
    ) {
      exchange1035InfoErrors.loanBalance1035 = true;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    } else if (
      values.faceAmountAndPremium.exchangeInfo.loanBalance1035 >
      MAX_NUMBER.MAX_VALUE
    ) {
      exchange1035InfoErrors.loanBalance1035 = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    }
    if (
      !values.faceAmountAndPremium.exchangeInfo.exchangeBasis &&
      values.faceAmountAndPremium.exchangeInfo.exchangeBasis !== 0
    ) {
      exchange1035InfoErrors.exchangeBasis = true;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    } else if (
      values.faceAmountAndPremium.exchangeInfo.exchangeBasis >
      MAX_NUMBER.MAX_VALUE
    ) {
      exchange1035InfoErrors.exchangeBasis = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    }

    if (!values.faceAmountAndPremium.exchangeInfo.month1035Received) {
      exchange1035InfoErrors.month1035Received = true;
      errors.exchangeInfo = { ...exchange1035InfoErrors };
    }
  }

  if (values.faceAmountAndPremium.premiumMode !== 'Annual') {
    if (!values.faceAmountAndPremium.monthToBeginScheduledPremium) {
      errors.monthToBeginScheduledPremium = true;
    }
  }

  if (
    values.faceAmountAndPremium.advancedPremiumFund &&
    values.faceAmountAndPremium.premiumMode === 'Annual'
  ) {
    const advancedPremiumFundInfoErrors = {};
    if (
      !values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAmount &&
      values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAmount !== 0
    ) {
      advancedPremiumFundInfoErrors.advancedPremiumFundAmount = true;
      errors.advancedPremiumFundInfo = { ...advancedPremiumFundInfoErrors };
    } else if (
      _.isNumber(
        values.faceAmountAndPremium.advancedPremiumFundInfo
          .advancedPremiumFundAmount,
      )
    ) {
      if (
        values.faceAmountAndPremium.advancedPremiumFundInfo
          .advancedPremiumFundAmount > 10000000
      ) {
        advancedPremiumFundInfoErrors.advancedPremiumFundAmount = `${ERRORS_MESSAGES.MAX_VALUE} $10,000,000`;
        errors.advancedPremiumFundInfo = { ...advancedPremiumFundInfoErrors };
      }
    }
    if (
      !values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAgeOrYear &&
      values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAgeOrYear !== 0
    ) {
      advancedPremiumFundInfoErrors.advancedPremiumFundAgeOrYear = true;
      errors.advancedPremiumFundInfo = { ...advancedPremiumFundInfoErrors };
    } else if (
      values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAgeOrYear < 1
    ) {
      advancedPremiumFundInfoErrors.advancedPremiumFundAgeOrYear = `${ERRORS_MESSAGES.MIN_VALUE} 1`;
      errors.advancedPremiumFundInfo = { ...advancedPremiumFundInfoErrors };
    } else if (
      values.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAgeOrYear > 10
    ) {
      advancedPremiumFundInfoErrors.advancedPremiumFundAgeOrYear = `${ERRORS_MESSAGES.MAX_VALUE} 10`;
      errors.advancedPremiumFundInfo = { ...advancedPremiumFundInfoErrors };
    }
  }

  if (
    !values.faceAmountAndPremium.targetCashValue &&
    values.faceAmountAndPremium.targetCashValue !== 0
  ) {
    errors.targetCashValue = true;
  } else {
    if (_.isNumber(values.faceAmountAndPremium.targetCashValue)) {
      if (values.faceAmountAndPremium.targetCashValue > MAX_NUMBER.MAX_VALUE) {
        errors.targetCashValue = `${ERRORS_MESSAGES.MAX_VALUE} ${MAX_NUMBER.MAX_STRING}`;
      }
    }
    if (values.faceAmountAndPremium.targetCashValue === 'Endow') {
      if (
        !values.faceAmountAndPremium.targetDeathBenefitYear &&
        values.faceAmountAndPremium.targetDeathBenefitYear !== 0
      ) {
        errors.targetDeathBenefitYear = true;
      }
    }
  }

  if (
    !values.faceAmountAndPremium.targetYear &&
    values.faceAmountAndPremium.targetYear !== 0
  ) {
    errors.targetYear = true;
  } else {
    if (values.faceAmountAndPremium.targetYear !== LIFETIME) {
      validateA999(
        'targetYear',
        values.faceAmountAndPremium.targetYear,
        errors,
      );
    }
  }

  if (_.isEmpty(errors)) {
    return {};
  }
  return { faceAmountAndPremium: { ...errors } };
};
