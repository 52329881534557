import FromThruIndexes from './FromThruIndexes';
import PremiumAndMaturedAllocations from './PremiumAndMaturedAllocations';
import MapValuesToApi from './MapValuesToApi';
import MapDataValuesToState from './MapDataValuesToState';
import FieldWithAdvancedTable from './DownshiftWithAdvancedOption';

export const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export { FromThruIndexes, PremiumAndMaturedAllocations, MapValuesToApi, MapDataValuesToState, FieldWithAdvancedTable };
