/* eslint-disable no-extra-boolean-cast */
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getIn } from 'formik';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import InputFormik from '../../../components/InputFormik';
import SelectFormik from '../../../components/SelectFormik';
import PercentFormat from '../../../components/PercentFormat';
import CurrencyFormat from '../../../components/CurrencyFormat';
import NumericFormat from '../../../components/NumericFormat';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import { useAppContext } from '../../App/AppContext';
import messages from '../messages';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';
import DownshiftFormikField from '../../../components/DownshiftFormik';
import { DOWNSHIFT_OPTION_CURRENCY } from '../../../components/DownshiftFormik/constants';
import { ageCalculation } from '../../../utils/ageCalculation';
import { TEEN_AGE, MATURE_AGE } from '../../../constants';

const Riders = () => {
  const { values, setFieldValue, errors } = useWizardContext();
  const { settings } = useAppContext();
  const [hideChangeOfInsuredRider, setHideChangeOfInsuredRider] = useState(false);

  const waiverRatings = settings.waiverTypes
    .filter(item => item.value === values.riders.waiverRiderInfo.waiverType)
    .map(i => i.waiverRatings)[0];

  const intl = useIntl();

  const hasErrors = fieldName =>
    !!getIn(errors, fieldName) ? 'hasErrors' : '';

  useEffect(() => {
    const currentAge = ageCalculation(values.insured.issueAgeOrDateOfBirth, settings);
    const hideChange = values.riders.accidentalDeathBenefit ||
      values.riders.waiverRider ||
      values.riders.surrenderValueEnhancementRider ||
      (currentAge < TEEN_AGE || currentAge > MATURE_AGE);
    setHideChangeOfInsuredRider(hideChange);
  }, [
    values.riders.accidentalDeathBenefit,
    values.riders.waiverRider,
    values.riders.surrenderValueEnhancementRider,
    values.insured.issueAgeOrDateOfBirth,
    setHideChangeOfInsuredRider,
    settings
  ]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List>
            <ListItemCheckboxFormik
              name="riders.longTermCareRiderII"
              label={intl.formatMessage(messages.longTermCareRiderII)}
            />
            {values.riders.longTermCareRiderII && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={5} lg={4}>
                    <SelectFormik
                      name="riders.longTermCareRiderInfo.maximumMonthlyBenefit"
                      label={intl.formatMessage(messages.maximumMonthlyBenefit)}
                      options={settings.maximumMonthlyBenefits}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={8}>
                    <DownshiftFormikField
                      name="riders.longTermCareRiderInfo.longTermCareAmount"
                      label={intl.formatMessage(messages.longTermCareAmount)}
                      initialInputValue={
                        values.riders.longTermCareRiderInfo.longTermCareAmount
                      }
                      customInputType={DOWNSHIFT_OPTION_CURRENCY}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
                      // Dropdown options
                      items={settings.longTermCareAmounts}
                      maxLength={18}
                      decimalScale={2}
                      defaultDownshiftCurrencyValue={0}
                      isErrorMessageDisplayed={true}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          setFieldValue(
                            'riders.longTermCareRiderInfo.longTermCareAmount',
                            value,
                          );
                        } else {
                          // default object
                          setFieldValue(
                            'riders.longTermCareRiderInfo.longTermCareAmount',
                            '',
                          );
                        }
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={7}>
                    <SelectFormik
                      name="riders.longTermCareRiderInfo.rateClass"
                      label={intl.formatMessage(messages.rateClass)}
                      options={settings.rateClasses}
                    />
                    <Typography color="secondary" className="input-note">
                      {intl.formatMessage(messages.rateClassNote)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <List>
                      <ListItemCheckboxFormik
                        name="riders.longTermCareRiderInfo.marriedOrCivilUnionOrDomesticPartnership"
                        label={intl.formatMessage(
                          messages.marriedOrCivilUnionOrDomesticPartnership,
                        )}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      <ListItemCheckboxFormik
                        name="riders.longTermCareRiderInfo.printLTCAnalysisRepot"
                        label={intl.formatMessage(
                          messages.printLTCAnalysisRepot,
                        )}
                      />
                      {values.riders.longTermCareRiderInfo
                        .printLTCAnalysisRepot && (
                        <ListItem className="list-container-ligth">
                          <Grid
                            container
                            className="list-container"
                            spacing={2}
                          >
                            <Grid item xs={12} md={6} lg={4}>
                              <InputFormik
                                name="riders.longTermCareRiderInfo.LTCAnalysisReportInfo.hipaaPerDiemRate"
                                label={intl.formatMessage(
                                  messages.hipaaPerDiemRate,
                                )}
                                numberFormatCustom={PercentFormat}
                                maxLength={6}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <InputFormik
                                name="riders.longTermCareRiderInfo.LTCAnalysisReportInfo.ageToBeginLTCRiderBenefit"
                                label={intl.formatMessage(
                                  messages.ageToBeginLTCRiderBenefit,
                                )}
                                numberFormatCustom={NumericFormat}
                                maxLength={4}
                                decimalscale={0}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <InputFormik
                                name="riders.longTermCareRiderInfo.LTCAnalysisReportInfo.desiredDailyLTCBenefit"
                                label={intl.formatMessage(
                                  messages.desiredDailyLTCBenefit,
                                )}
                                numberFormatCustom={CurrencyFormat}
                                maxLength={14}
                                decimalscale={2}
                              />
                              {!hasErrors(
                                'riders.longTermCareRiderInfo.LTCAnalysisReportInfo.desiredDailyLTCBenefit',
                              ) && (
                                <Typography
                                  color="secondary"
                                  className="input-note"
                                >
                                  {intl.formatMessage(
                                    messages.desiredDailyLTCBenefitNote,
                                  )}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              </ListItem>
            )}
            <ListItemCheckboxFormik
              name="riders.accidentalDeathBenefit"
              label={intl.formatMessage(messages.accidentalDeathBenefit)}
            />
            {values.riders.accidentalDeathBenefit && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DownshiftFormikField
                      name="riders.adbAmountAndRating.ADBAmount"
                      defaultDownshiftCurrencyValue={0}
                      label={intl.formatMessage(messages.ADBAmount)}
                      initialInputValue={
                        values.riders.adbAmountAndRating.ADBAmount
                      }
                      customInputType={DOWNSHIFT_OPTION_CURRENCY}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
                      // Dropdown options
                      items={settings.adbAmounts}
                      maxLength={18}
                      isErrorMessageDisplayed={true}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          setFieldValue(
                            'riders.adbAmountAndRating.ADBAmount',
                            value,
                          );
                        } else {
                          // default object
                          setFieldValue(
                            'riders.adbAmountAndRating.ADBAmount',
                            '',
                          );
                        }
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="riders.adbAmountAndRating.ADBRating"
                      label={intl.formatMessage(messages.ADBRating)}
                      options={settings.adbRatings}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItemCheckboxFormik
              name="riders.waiverRider"
              label={intl.formatMessage(messages.waiverRider)}
            />
            {values.riders.waiverRider && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="riders.waiverRiderInfo.waiverType"
                      label={intl.formatMessage(messages.waiverType)}
                      options={settings.waiverTypes}
                    />
                  </Grid>
                  {values.riders.waiverRiderInfo.waiverType ===
                    'Premium Waiver' && (
                    <Grid item xs={12} md={6}>
                      <DownshiftFormikField
                        name="riders.waiverRiderInfo.premiumAmount"
                        label={intl.formatMessage(messages.ADBAmount)}
                        initialInputValue={
                          values.riders.waiverRiderInfo.premiumAmount
                        }
                        customInputType={DOWNSHIFT_OPTION_CURRENCY}
                        customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
                        // Dropdown options
                        items={settings.adbAmounts}
                        maxLength={18}
                        decimalScale={2}
                        defaultDownshiftCurrencyValue={0}
                        isErrorMessageDisplayed={true}
                        handleValueOnChange={value => {
                          if (value !== '') {
                            setFieldValue(
                              'riders.waiverRiderInfo.premiumAmount',
                              value,
                            );
                          } else {
                            setFieldValue(
                              'riders.waiverRiderInfo.premiumAmount',
                              '',
                            );
                          }
                        }}
                        handleIsCustomizedValue={() => false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="riders.waiverRiderInfo.waiverRating"
                      label={intl.formatMessage(messages.waiverRating)}
                      options={waiverRatings}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
            <ListItemCheckboxFormik
              name="riders.surrenderValueEnhancementRider"
              label={intl.formatMessage(messages.surrenderValueEnhancementRider)}
            />
            {values.riders.surrenderValueEnhancementRider && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={10} lg={8}>
                    <SelectFormik
                      name="riders.surrenderValueEnhancementRiderInfo.returnOfPremium"
                      label={intl.formatMessage(messages.returnOfPremium)}
                      options={settings.surrenderValueEnhancementRiderInfo}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {!hideChangeOfInsuredRider && (
              <ListItemCheckboxFormik
                name="riders.changeOfInsuredRiderInfo"
                label={intl.formatMessage(messages.changeOfInsuredRider)}
              />
            )}
          </List>
        </Grid>
      </Grid>
    </Fragment>
  );
};

Riders.propTypes = {};

export default Riders;
