import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import palette from '../../../src/palette';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  dialogCustomizedWidth: {
    width: '60%',
  },
  dialogTitleText: {
    color: palette.vibrantBlue,
    padding: '24px 24px 2px',
  },
  divider: {
    backgroundColor: `${palette.vibrantBlue} !important`,
  },
  btnActions: {
    justifyContent: 'left',
    padding: '24px',
  }
}));

const ExpirationModal = ({ isOpen, onRenewSession, onClose, onLogout, isSessionExpired, tokenLeftExpirationTime }) => {
  const classes = useStyles();
  const timeText = tokenLeftExpirationTime === 1 ? 'hour' : 'hours';

  return !isSessionExpired ? (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: classes.dialogCustomizedWidth }}
    >
      <DialogTitle className={classes.dialogTitleText}>
        Session Expiring Soon!
        <IconButton onClick={onRenewSession} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              For your security, we log you out automatically when you have not been active for{' '}
              {tokenLeftExpirationTime} {timeText}.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.btnActions}>
        <Button
          className={classes.button}
          onClick={onRenewSession}
          color='secondary'
          variant='contained'
        >
          Continue session
        </Button>
        <Button className={classes.button} onClick={onLogout} color='primary' variant='outlined'>
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: classes.dialogCustomizedWidth }}
    >
      <DialogTitle className={classes.dialogTitleText}>
        Session Expired
        <IconButton aria-label='close' onClick={onLogout} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Please login again to continue using the application.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.btnActions}>
        <Button className={classes.button} onClick={onLogout} color='secondary' variant='contained'>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExpirationModal.propTypes = {
  isOpen: PropTypes.bool,
  onRenewSession: PropTypes.func,
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
  isSessionExpired: PropTypes.bool,
  tokenLeftExpirationTime: PropTypes.number,
};

ExpirationModal.defaultProps = {
  isOpen: false,
  onRenewSession: () => { },
  onClose: () => { },
  onLogout: () => { },
  tokenLeftExpirationTime: 1,
  isSessionExpired: false,
}

export default ExpirationModal;

