import palette from './palette';

const variables = {
  primaryColor: palette.vibrantBlue,
  primaryBtnHover: palette.smalt,
  primaryBtnDisabled: palette.vibrantBlue50,
  secondaryColor: palette.darkMint,
  secondaryBtnHover: palette.darkSeaBlue,
  secondaryBtnOutlinedDisabled: palette.darkMintAlpha50,
  secondaryBtnDisabled: palette.seaBlue,
  dialogActionButton: palette.darkMint,
  dialogActionBtnHover: palette.darkSeaBlue,
  dialogActionBtnDisabled: palette.seaBlue,
  nextFormBtnColor: palette.darkMint,
  nextFormBtnHover: palette.darkSeaBlue,
  backFormBtnText: palette.vibrantBlue,
  hintIconFill: palette.vibrantBlue,
  calendarIconFill: palette.vibrantBlue,
  checkboxCheckedFill: palette.vibrantBlue,
  radioButtonFill: palette.vibrantBlue,
  btnContrastText: palette.white,
  headerPrimary: palette.vibrantBlue,
  headerSecondary: palette.darkBlue,
  fontMedium: 'Gotham-Medium',
  fontRegular: 'Gotham-Book',
  fontBold: 'Gotham-Bold',
  fontHeadlineSemibold: 'TiemposHeadlineSemibold',
  fontFineSemiBold: 'TiemposFineSemiBold',
  viewHistoryTableHead: palette.vibrantBlue,
  viewHistoryTableBorder: `1px solid ${palette.alto}`,
  darkTooltipBg: palette.mondo95,
  darkTooltipContrastText: palette.white,
  lightTooltipBg: palette.white,
  lightTooltipBorder: `1px solid ${palette.silverChalice}`,
  lightTooltipTextColor: palette.dark,
  tooltipArrowColor: palette.mondo80,
  nonMecTableBorder: `8px solid ${palette.silver80}`,
  nonMecTableCellBg: palette.white,
  nonMecTableCellEvenBg: palette.athensGray,
  nonMecTableCellEvenBorder: `1px solid ${palette.mercury}`,
  nonMecTableCellNotFirst: palette.whiteSmoke,
  styledLinkHover: palette.bayOfMany,
  deathBenefitLastCell: palette.atoll,
  premiumTableOddCell: palette.bayOfMany,
  inputText: palette.mineShaft,
  skeletonHighlight: palette.silverFoil
}

export default variables;