import { minValue, maxValue } from '../../../../../utils/validate';
import { MAX } from '../../../../../constants';

export const validateA999 = (fieldName, value, errors, arrayErrors, index) => {
  const fromFieldValue = Number(value.replace(/[^0-9.-]+/g, ''));
  // validate empty or zero
  if (!fromFieldValue) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  const minVal = minValue(0)(fromFieldValue);
  const maxVal = maxValue(120)(fromFieldValue);
  if (minVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  if (maxVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
};
/**
 * Validator
 * @param {object} values.valuesPopup
 * @return {object} Return an object with the list of errors
 */
export const premiumAllocationValidations = (values, validationConfig) => {
  const arrayErrors = [];

  if (values.valuesPopup.length > 0) {
    const rates = values.valuesPopup;
    for (let index = 0; index < rates.length; index++) {
      const rate = rates[index];
      const errors = {};

      const indexAllocationErrors = [];
      let existsIndexAllocationErrors = false;

      rate.indexInfo.forEach(element => {
        let error = false;
        if (element.allocation && element.allocation !== 0) {
          const min = minValue(validationConfig.rateMinValue)(
            element.allocation,
          );
          const max = maxValue(validationConfig.rateMaxValue)(
            element.allocation,
          );
          if (min) {
            error = `The minimum value is ${validationConfig.rateMinValue}%`;
          } else if (max) {
            error = `The maximum value is ${validationConfig.rateMaxValue}%`;
          }
        }

        if (error && !existsIndexAllocationErrors) {
          existsIndexAllocationErrors = true;
        }

        indexAllocationErrors.push({
          allocation: error,
        });
      });

      if (existsIndexAllocationErrors) {
        errors['indexInfo'] = indexAllocationErrors;
        arrayErrors[index] = errors;
      }

      if (!rate[validationConfig.fromFieldName]) {
        errors[validationConfig.fromFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        validateA999(
          validationConfig.fromFieldName,
          rate[validationConfig.fromFieldName],
          errors,
          arrayErrors,
          index,
        );
      }
      if (!rate[validationConfig.thruFieldName]) {
        errors[validationConfig.thruFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        if (rate[validationConfig.thruFieldName] !== MAX) {
          validateA999(
            validationConfig.thruFieldName,
            rate[validationConfig.thruFieldName],
            errors,
            arrayErrors,
            index,
          );
        }
      }

      if (rate.total !== validationConfig.totalRequiredValue) {
        errors[
          'total'
        ] = `The total value should be ${validationConfig.rateMaxValue}%`;
        arrayErrors[index] = errors;
      }
    }
  }

  if (arrayErrors.length > 0) {
    return { valuesPopup: arrayErrors };
  }
  return {};
};
