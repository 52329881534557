import { makeStyles } from '@material-ui/core';
import variables from '../../../../../themeVars';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus, :focus-visible': { 
      outline: 'none', 
    }, 
    '@media (max-width: 599.95px)': {
      alignItems: 'inherit',
    },
  },
  paper: {
    width: 1000,
    '&.cash-columns-not-visible': {
      width: 744,      
    },
    backgroundColor: theme.palette.background.paper,
    border: variables.nonMecTableBorder,
    position: 'relative',
    overflowY: 'auto',
    borderRadius: 10,
    padding: theme.spacing(0, 3, 3, 3),
    '&:focus': {
      outline: 'none'
    }
  },
  placeholder: {
    height: 35,
  },
  divisor: {
    height: 1,
    marginBottom: 15,
    marginTop: -15,
    borderBottom: `1px solid #003b5c`
  },
  modalTitle: {
    color: variables.headerPrimary,
    fontFamily: variables.fontMedium,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  textDescription: {
    color: '#333333',
    fontSize: 15,
    fontFamily: variables.fontRegular,
    lineHeight: '18px',
    letterSpacing: 'normal',
    marginRight: 32,
    marginBottom: 24,
    '& >p:nth-child(1)': {
      marginBottom: 0,
      paddingLeft: 10,
    },
    '& >p:nth-child(2)': {
      marginTop: 0,
      paddingLeft: 12,
      marginBottom: 0,
      '@media (max-width: 599.95px)': {
        paddingLeft: 10
      },
    },
  },
  textNote: {
    marginRight: 0,
    marginBottom: 24,
    '@media (max-width: 599.95px)': {
      marginBottom: 45,
      marginTop: 40
    },
  },
  textTable: {
    color: '#222222',
    fontSize: 15,
    fontFamily: variables.fontRegular,
    marginRight: 50,
    backgroundColor: '#2d496b',
  },
  valuesTable: {
    color: '#222222',
    fontSize: 14,
    fontFamily: variables.fontRegular,
    textAlign: 'right',
    boxShadow: '0 1px 0 #e6e6e6',
    backgroundColor: '#f7f7f7',
    padding: 28,
  },
  tableTitle: {
    color: variables.primaryColor,
    fontFamily: variables.fontBold,
    textAlign: 'right',
    fontSize: 13,
    verticalAlign: 'bottom',
  },
  radioButton: {
    backgroundColor: variables.primaryColor,
    width: 20,
    height: 20,
  },
  marginAddButton: {
    marginLeft: 4,
    marginTop: 10,
    marginBottom: 23,
    display: 'grid',
    justifyContent: 'start',
    justifyItems: 'start',
    minHeight: 41,
    '@media all and (-ms-high-contrast: none)': {
      display: '-ms-flexbox',
      flexDirection: 'column',
      height: 30,
      '& > button':{
        marginBottom: '-37px',
        justifyContent: 'flex-start'
      }
    },
    '& > *':{
      gridRow: 1,
      gridColumn: 1
    }

  },
  marginIncreasePercentage: {
    marginLeft: '10px !important',
  },
  fontMedium: {
    fontFamily: variables.fontMedium,
    fontSize: 15,
  },
  marginControlButton: {
    marginRight: 25,
    width: 200,
    '@media (max-width: 599.95px)': {
      width: '90%',
      margin: '0 auto 12px auto',
      display: 'block'
    },
  },
  marginIncreaseDeathBenefitButton: {
    paddingTop: '5px',
  },
  paddingButton: {
    paddingTop: '12px !important',
  },
  loadingProgress: {
    color: variables.primaryColor,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -15,
    marginLeft: -12,
  },
  addCircle: {
    stroke: 'ghostwhite',
    strokeWidth: 0.8,
    margin: '0 7px 0px 2px',
  },
  increasePercentageWidth: {
    '&>div:nth(2)': {
      width: '90% !important',
    },
    '@media (min-width: 600px) and (orientation: portrait)': {},
    '@media (width: 1024px) and (orientation: portrait)': {
      maxWidth: '12.666667%',
      marginRight: 5,
      marginLeft: 5,
    },
    '@media (min-width: 960px) and (orientation: landscape)': {
      maxWidth: '12.666667% !important',
      marginRight: 5,
      marginLeft: 5,
    },
  },
  wrapper: {
    '& .selectedRow': {
      '@media(min-width: 700px)': {
      backgroundColor: 'rgba(31, 116, 219, 0.12) !important',
      },
    },
    '& .MuiTable-root': {
      marginBottom: '5px !important',
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    '& .MuiTableRow-root': {
      display: 'table-row',
      border: 'none',
      '& td': {
        '&:first-child': {
          padding: 8,
        },
        '&:not(:first-child)': {
          padding: '8px 20px 8px 14px',
          '& span.title-m': {
            display: 'none'
          }
        },
        '&:nth-child(3), &:nth-child(4), &:nth-child(5)': {
          width: 130,
          maxWidth: 130,
          minWidth: 130,
          textAlign: 'right'
        },
      },
      '& .MuiTableCell-body': {
        backgroundColor: '#f7f7f7',
        fontFamily: variables.fontRegular,
        fontSize: 14,
        '&:nth-child(1)': {
          backgroundColor: variables.nonMecTableCellBg,
          fontFamily: variables.fontRegular,
          border: 'none',
          width: 0,
        },
        '&:nth-child(2)': {
          backgroundColor: variables.nonMecTableCellEvenBg,
          fontFamily: variables.fontRegular,
          fontSize: 15,
          border: variables.nonMecTableCellEvenBorder,
        },
        '&:last-child': {
          backgroundColor: 'transparent',
          border: 'none',
        },
        '@media (max-width: 599.95px)': {
          backgroundColor: 'transparent',
          '&:first-child': {
            backgroundColor: 'transparent',
          },
        },
      },
      '&:nth-child(even)': {
        '& .MuiTableCell-body': {
          backgroundColor: variables.nonMecTableCellBg,
          '&:nth-child(2)': {
            backgroundColor: variables.nonMecTableCellEvenBg,
            border: variables.nonMecTableCellEvenBorder,
          },
          '&.premium-table': {
            '&:first-child': {
              backgroundColor: `${variables.nonMecTableCellBg} !important`,
            },
          },
        },
      },
      '@media (max-width: 599.95px)': {
        paddingTop: 8,
        display: 'block',
        marginTop: 5,
        '&:first-child': {
          border: 'none',
          padding: 0,
          marginTop: 10
        },
        '& td': {
          marginBottom: 0,
          '&.MuiTableCell-body': {
            '&:not(:first-child)': {
              width: '100%',
              maxWidth: 'none',
              minWidth: 'unset',
              display: 'block',
              boxShadow: 'none',
              backgroundColor: variables.nonMecTableCellNotFirst,
              borderTop: variables.nonMecTableCellEvenBorder,
              borderRight: 'none',
              height: 37,
              '& span.title-m': {
                display: 'inline-block',
                float: 'left',
                color: variables.primaryColor
              }
            },
            '&:nth-child(2)': {
              width: 'calc(100% - 34px)',
              paddingLeft: 4
            },
            '&:nth-child(1), &:nth-child(2)': {
              display: 'inline-block',
              height: 37,
              backgroundColor: 'rgb(237, 238, 240)',
              fontFamily: variables.fontBold,
            },
            '&:last-child': {
              display: 'none'
            }
          },
        },
        '&:nth-child(even)': {
          '& td': {
            '&.MuiTableCell-body': {
              '&:nth-child(2)': {
                boxShadow: 'none',
                backgroundColor: 'rgb(237, 238, 240)'
              },
            },
          }
        },
      },
    },
    '& .MuiTableCell-root': {
      borderLeft: 0,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: 0,
      '&.pdf-cell': {
        minWidth: 30,
        paddingRight: '0px !important'
      },
      '&:first-child': {
        borderLeft: 'none',
        width: '34px !important',
        minWidth: '34px !important',
      },
      '&:last-child': {
        paddingRight: '0px !important',
        '& button': {
          padding: 12,
        },
        '& .MuiIconButton-root svg': {
          strokeWidth: 0.8,
          stroke: 'ghostwhite',
        },
      },
      '& .MuiInputBase-root': {
        border: 'none',
        borderRadius: 0,
      },
      '& .Mui-error input': {
        backgroundColor: 'rgba(255,0,0,0.3)',
      },
      
      padding: '10px 20px 10px 10px',

      '&.face-amount-cell': {
        '&:first-child': {
          minWidth: '10px',
        },
        '&:nth-child(2)': {
          minWidth: '170px',
        },
        '&.distribution-amount-action': {
          '& .dropdown-style': {
            width: 310,
          },
        },
      },
      '&.fixed-crediting-rate': {
        '& .dropdown-style': {
          width: 210,
        },
      },
    },
    '& .MuiTableCell-head': {
      fontFamily: variables.fontBold,
      fontSize: '12px',
      color: variables.primaryColor,
      letterSpacing: '0',
      lineHeight: '15px',
      border: 'none',
      display: 'table-cell',
      '@media (max-width: 599.95px)': {
        display: 'none'
      },
    },
    '& .MuiTableCell-body': {
      padding: 10,
      border: variables.nonMecTableCellEvenBorder,
      '& .radio-button': {
        paddingLeft: 10,
        paddingRight: 0,
      },
      '& .radio-button span': {
        padding: 0,
      },
      '& .MuiInputAdornment-root': {
        '&[class*="positionEnd"]': {
          marginLeft: '-3px',
        },
        '& button:nth-child(1)': {
          '& .MuiSvgIcon-root': {
            marginRight: '-3px',
          },
        },
      },
      '&.premium-table': {
        '&:nth-last-child(2)': {
          border: 'none',
          background: 'none'
        },
        '@media (max-width: 1279.95px)': {
          '&&:nth-last-child(3)': {
            minWidth: '120px',
          },
        },
        '& .download-pdf': {
          cursor: 'pointer',
          '&.disabled': {
            cursor: 'auto'
          }
        }
      },
      '& input': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderRadius: 0,
        color: variables.inputText,
        fontSize: '15px',
        height: '51px',
        letterSpacing: '0',
        lineHeight: '17px',
        padding: '0px 0px 0px 15px',
      },
    },
  },
  addDeathBenefitInput: {
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: 15,
    '&.MuiButton-textPrimary:hover': {
      backgroundColor: 'white !important',
    }
  },
  increasePercentage: {
    width: '90% !important',
  },
  deathBenefitAmount: {
    paddingTop: '3px',
    paddingLeft: '10px',
    '& > div': {
      display: 'inherit',
      paddingBottom: '4px !important',
      paddingLeft: '0 !important',
      paddingTop: '2px !important',
      '&:nth-child(1)': {
        width: 28,
        maxWidth: 28,
        '& .remove-button': {
          width: 24,
          minWidth: 24,
        }
      },
      '&:nth-child(2)': {
        '& > div': {
          width: '100%',
        },
        maxWidth: 244,
        minWidth: 244,
        '@media (min-width: 744px) and (max-width: 824px)': {
          maxWidth: 194,
          minWidth: 194,
          '& div > div > label': {
            fontSize: '11px',
          },
        },
      },
      '&:nth-child(3)': {
        maxWidth: 90,
        minWidth: 90,
      },
      '@media (max-width: 599.95px)': {
        '&:nth-child(2), &:nth-child(3)': {
          maxWidth: 'calc(100% - 56px)',
          minWidth: 'calc(100% - 56px)',
          marginBottom: 8
        },
        '&:nth-child(3)': {
          marginLeft: 28,
          '& .MuiBox-root': {
            width: '100%'
          }
        }
      },
    },
  },
  actionButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      width: 120
    }
  },
  actionButton: {
    backgroundColor: variables.dialogActionButton,
    color: variables.btnContrastText,
    '&:hover': {
      backgroundColor: variables.dialogActionBtnHover,
    },
    '&:disabled': {
      color: variables.btnContrastText,
      backgroundColor: variables.dialogActionBtnDisabled
    }
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: variables.primaryColor,
      color: variables.btnContrastText
    },
    '&.Mui-disabled': {
      color: variables.primaryBtnDisabled
    }
  },
  addBtn: {
    width: 114,
    fontSize: 15,
    '@media (max-width: 599.95px)': {
      width: 'calc(100% - 56px)',
      marginLeft: 28
    },
  },
  removeDeathBenefitRowButton: {
    padding: '0px !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  deathBenefitLastCell: {
    padding: '0px !important',
    maxWidth: 18,
    minWidth: 18,
    color: variables.deathBenefitLastCell,
  },
  removeButton: {
    marginTop: 6,
    marginLeft: 6,
    fontSize: 30,
    cursor: 'pointer',
    width: 26,
    height: 26,
  },
  skeleton: {
    '-webkit-animation': '$skeleton-custom-animation 0.5s ease-in-out infinite',
    '-moz-animation': '$skeleton-custom-animation 0.5s ease-in-out infinite',
    animation: '$skeleton-custom-animation 0.5s ease-in-out infinite !important'
  },



  '@-webkit-keyframes skeleton-custom-animation': {
    '0%': {
      'background-position': '200%'
    },
    '100%': {
      'background-position': '-100%'
    }
  },
  '@-moz-keyframes skeleton-custom-animation': {
    '0%': {
      'background-position': '200%'
    },
    '100%': {
      'background-position': '-100%'
    }
  },
  '@keyframes skeleton-custom-animation': {
    '0%': {
      'background-position': '200%'
    },
    '100%': {
      'background-position': '-100%'
    }
  },
  
}));

export default useStyles;
