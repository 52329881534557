import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const ProgressButtonWrapper = styled.div`
  display: inline-block;
  text-align: center;

  @media (max-width: 959.95px) {
    width: 100%;

    button {
      &:first-child {
        display: block;
        width: 100%;
      }
    }
  }
`;

export const ProgressButton = styled(Button)`
  &[disabled] {
    background: linear-gradient(270deg, #c1c1c1, #e0e0e0);
    background-size: 400% 400%;

    -webkit-animation: buttonProgress 1.5s ease infinite;
    -moz-animation: buttonProgress 1.5s ease infinite;
    animation: buttonProgress 1.5s ease infinite;

    @-webkit-keyframes buttonProgress {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    @-moz-keyframes buttonProgress {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    @keyframes buttonProgress {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#346094',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -15,
    marginLeft: -12,
  },
});

const ButtonProgress = props => {
  const { loading, classes, children, ...rest } = props;

  return (
    <ProgressButtonWrapper>
      <div className={classes.wrapper}>
        <ProgressButton
          variant="contained"
          color="primary"
          disabled={loading}
          {...rest}
        >
          {children}
          {loading && (
            <CircularProgress
              size={30}
              thickness={6}
              className={classes.buttonProgress}
            />
          )}
        </ProgressButton>
      </div>
    </ProgressButtonWrapper>
  );
};
ButtonProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

export default withStyles(styles)(ButtonProgress);
