/* eslint-disable no-extra-boolean-cast */
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
import { useResize } from 'react-browser-hooks';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import CurrencyFormat from '../../../components/CurrencyFormat';
import InputFormik from '../../../components/InputFormik';
import SelectFormik from '../../../components/SelectFormik';
import Hint from '../../../components/Hint';

import messages from '../messages';
import RiskClassHint from '../components/steps/Insured/RiskClassHint';
import { RateIndexFromThru } from '../components/Tables/';
import { rateIndexFromThruValidations } from '../components/Tables/validators';
import { useAppContext } from '../../App/AppContext';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import {
  isDateFormat,
  getAgeNearestProduct,
  getPercentFormat,
  getCurrencyFormatWithDecimals,
} from '../../../utils/utils';
import { ageCalculation } from '../../../utils/ageCalculation';
import { Typography } from '@material-ui/core';
import { getIn } from 'formik';
import { MAX, NEW_PRODUCT, RISK_CLASS_STANDARD_NON_TABACCO, TEEN_AGE } from '../../../constants';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';
import DownshiftFormikField from '../../../components/DownshiftFormik';
import {
  DOWNSHIFT_OPTION_PERCENTAGE,
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_AGE_DOB
} from '../../../components/DownshiftFormik/constants';


const InsuredForm = () => {
  const { values, setFieldValue, errors } = useWizardContext();
  const { settings } = useAppContext();
  const [riskClasses, setRiskClass] = useState(settings.riskClasses);
  const intl = useIntl();
  const { width } = useResize();
  const mediumTooltip = width > 767 && width < 1025;
  const hasErrors = fieldName => {
    return !!getIn(errors, fieldName) ? 'hasErrors' : '';
  };
  const [
    isDisableRevisedIllustration,
    setIsDisableRevisedIllustration,
  ] = useState(false);

  useEffect(() => {
    if (values.product) {
      const newProduct = values.product !== NEW_PRODUCT;
      if (newProduct) {
        setFieldValue('revisedIllustration', true);
        setIsDisableRevisedIllustration(true);
      }
    }
  }, [setFieldValue, values]);

  useEffect(() => {
    const currentAge = ageCalculation(values.insured.issueAgeOrDateOfBirth, settings);
    if (currentAge !== null) {
      let riskClassesAvailable = settings.riskClasses.filter(item => {
        return (currentAge >= TEEN_AGE && item.maxAge >= currentAge) || (currentAge < TEEN_AGE && item.value === RISK_CLASS_STANDARD_NON_TABACCO)
      });

      let isCurrentRiskClassAvailable = riskClassesAvailable.find(item => {
        return item.value === values.insured.riskClass
      });

      if (isCurrentRiskClassAvailable && values.insured.riskClass) {
        setFieldValue('insured.riskClass', values.insured.riskClass);
      } else {
        setFieldValue('insured.riskClass', undefined);
      }
      setRiskClass(riskClassesAvailable)
    } else {
      setRiskClass(settings.riskClasses)
    }
  }, [setFieldValue, values.insured.issueAgeOrDateOfBirth]);


  let ageCalculated = null;
  let isDOBCalculated = false;
  if (isDateFormat(values.insured.issueAgeOrDateOfBirth)) {
    const age = getAgeNearestProduct(
      settings.serverDateTime,
      values.insured.issueAgeOrDateOfBirth,
    );
    ageCalculated = isNaN(age) ? null : age;
    isDOBCalculated = true;
  } else {
    ageCalculated = values.insured.issueAgeOrDateOfBirth;
  }
  const applyflatExtra =
    values.insured.riskClass === 'Standard Tobacco' ||
    (values.insured.riskClass === 'Standard Nontobacco' && ageCalculated > 14);

  const getApprovedStates = () => {
    if (settings) {
      if (values.revisedIllustration) {
        return settings.approvedRevisedStates;
      }
      return settings.approvedStates;
    }
    return [];
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectFormik
            name="insured.issueState"
            label={intl.formatMessage(messages.issueState)}
            options={getApprovedStates()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <List>
            <ListItemCheckboxFormik
              name="revisedIllustration"
              label={intl.formatMessage(messages.revisedIllustration)}
              disabled={isDisableRevisedIllustration}
            >
              <Hint
                top={4}
                position={mediumTooltip ? 'top' : 'right'}
                rightAlign
                text={
                  <FormattedHTMLMessage {...messages.revisedIllustrationHint} />
                }
              />
            </ListItemCheckboxFormik>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <List>
            <ListItemCheckboxFormik
              name="insured.financePremiums"
              label={intl.formatMessage(messages.financePremiums)}
            >
              <Hint
                top={4}
                position={mediumTooltip ? 'top' : 'right'}
                rightAlign
                text={
                  <FormattedHTMLMessage {...messages.financePremiumsHint} />
                }
              />
            </ListItemCheckboxFormik>
          </List>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputFormik
            name="insured.firstName"
            label={intl.formatMessage(messages.firstName)}
            maxLength={32}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputFormik
            name="insured.lastName"
            label={intl.formatMessage(messages.lastName)}
            maxLength={32}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormik
            name="insured.gender"
            label={intl.formatMessage(messages.gender)}
            options={settings ? settings.genders : []}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="insured.issueAgeOrDateOfBirth"
            label={intl.formatMessage(messages.issueAgeOrDateOfBirth)}
            isErrorMessageDisplayed
            ignoreValueFromItems
            isDisabledInputOnSelect={false}
            isCenter={true}
            customInputType={DOWNSHIFT_OPTION_AGE_DOB}
            initialInputValue={values.insured.issueAgeOrDateOfBirth}
            // Dropdown options
            items={settings ? settings.ages : []}
            // Handler
            handleValueOnChange={value => {
              if (value !== '') {
                // default object
                setFieldValue('insured.issueAgeOrDateOfBirth', value);
              } else {
                setFieldValue('insured.issueAgeOrDateOfBirth', '');
              }
            }}
            handleIsCustomizedValue={() => false}
          />
          {isDOBCalculated && !hasErrors('insured.issueAgeOrDateOfBirth') && (
            <Typography color="secondary" className={`input-note }`}>
              {intl.formatMessage(messages.issueAge)}: {ageCalculated}{' '}
              {intl.formatMessage(messages.ageNearestBirthday)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFormik
            name="insured.riskClass"
            label={intl.formatMessage(messages.riskClass)}
            options={riskClasses ? riskClasses : []}
            extraIcon={RiskClassHint}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DownshiftFormikField
            name="insured.taxBracket"
            label={intl.formatMessage(messages.taxBracket)}
            isErrorMessageDisplayed={true}
            initialInputValue={
              values.insured.taxBracket.length
                ? values.insured.taxBracket[0].taxBracketRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={8}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.taxBracket)}
            popupComponent={RateIndexFromThru}
            tableProps={{
              rateFieldName: 'taxBracketRate',
              fromFieldName: 'taxBracketFrom',
              thruFieldName: 'taxBracketThru',
            }}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'taxBracketRate',
                fromFieldName: 'taxBracketFrom',
                thruFieldName: 'taxBracketThru',
                rateMinValue: 0,
                rateMaxValue: 100,
                indexName: intl.formatMessage(messages.taxBracket),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('insured.taxBracket', [
                  {
                    taxBracketRate: value,
                    taxBracketFrom: '1',
                    taxBracketThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('insured.taxBracket', [
                  {
                    taxBracketRate: '',
                    taxBracketFrom: '1',
                    taxBracketThru: 'Max',
                  },
                ]);
              }
            }}
            handleCustomValueOnPopupSave={inputValue => {
              return `${getPercentFormat(inputValue)}...`;
            }}
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;
              const fieldValue = value[0];
              if (
                fieldValue.taxBracketFrom !== '1' ||
                fieldValue.taxBracketThru !== MAX
              ) {
                return true;
              }
              return false;
            }}
          />
        </Grid>
        {(values.insured.riskClass === 'Standard Tobacco' ||
          values.insured.riskClass === 'Standard Nontobacco') && (
            <Grid item xs={12}>
              <List>
                <ListItemCheckboxFormik
                  name="insured.rating"
                  label={intl.formatMessage(messages.rating)}
                />
                {values.insured.rating && (
                  <ListItem className="list-container">
                    <Grid container className="list-container">
                      <Grid item xs={12} sm={6} className="list-item">
                        <SelectFormik
                          name="insured.tableRating"
                          label={intl.formatMessage(messages.tableRating)}
                          options={settings ? settings.tableRatings : []}
                        />
                      </Grid>
                      {applyflatExtra && (
                        <Grid item xs={12} sm={6} className="list-item">
                          <DownshiftFormikField
                            name="insured.flatExtra"
                            label={intl.formatMessage(messages.flatExtra)}
                            isErrorMessageDisplayed={true}
                            initialInputValue={
                              values.insured.flatExtra.length
                                ? values.insured.flatExtra[0].flatExtraAmount
                                : ''
                            }
                            customInputType={DOWNSHIFT_OPTION_CURRENCY}
                            maxLength={8}
                            decimalScale={2}
                            // Popup options
                            size="sm"
                            hasPopup
                            popupTitle={intl.formatMessage(messages.flatExtra)}
                            popupComponent={RateIndexFromThru}
                            tableProps={{
                              rateFieldName: 'flatExtraAmount',
                              fromFieldName: 'flatExtraFrom',
                              thruFieldName: 'flatExtraThru',
                              rateColumnLabel: 'Amount',
                              inputFormat: CurrencyFormat,
                            }}
                            popupValidations={values => {
                              return rateIndexFromThruValidations(values, {
                                rateFieldName: 'flatExtraAmount',
                                fromFieldName: 'flatExtraFrom',
                                thruFieldName: 'flatExtraThru',
                                rateMinValue: 0,
                                rateMaxValue: 25,
                                indexName: intl.formatMessage(messages.flatExtra),
                              });
                            }}
                            // Dropdown options
                            handleValueOnChange={value => {
                              if (value !== '') {
                                setFieldValue('insured.flatExtra', [
                                  {
                                    flatExtraAmount: value,
                                    flatExtraFrom: '1',
                                    flatExtraThru: 'Max',
                                  },
                                ]);
                              } else {
                                // default object
                                setFieldValue('insured.flatExtra', [
                                  {
                                    flatExtraAmount: '',
                                    flatExtraFrom: '1',
                                    flatExtraThru: 'Max',
                                  },
                                ]);
                              }
                            }}
                            handleCustomValueOnPopupSave={inputValue => {
                              return `${getCurrencyFormatWithDecimals(
                                inputValue,
                              )}...`;
                            }}
                            handleIsCustomizedValue={value => {
                              if (!value.length) return false;
                              if (value.length > 1) return true;
                              const fieldValue = value[0];
                              if (
                                fieldValue.flatExtraFrom !== '1' ||
                                fieldValue.flatExtraThru !== MAX
                              ) {
                                return true;
                              }
                              return false;
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                )}
              </List>
            </Grid>
          )}
      </Grid>
    </Fragment>
  );
};

export default InsuredForm;
