import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { themePlugin } from '@react-pdf-viewer/theme';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { printPlugin } from '@react-pdf-viewer/print';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { isIE } from 'react-device-detect';
import printJS from 'print-js';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { useParams } from 'react-router-dom';
import ViewerToolbar from './ViewerToolbar';

const PDFViewer = () => {
  let { id } = useParams();
  const pdfURL = `${process.env.REACT_APP_API_URL}/api/Illustrations/${id}/pdf`;
  //Plugin instances
  const getFilePluginInstance = getFilePlugin();
  const printPluginInstance = printPlugin({
    enableShortcuts: true,
  });
  const zoomPluginInstance = zoomPlugin();
  const themePluginInstance = themePlugin();
  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: getFilePluginInstance,
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const renderTB = () => {
    return (
      <ViewerToolbar
        handlePrint={handlePrint}
        handleDownload={handleDownload}
        plugins={{
          toolbar: toolbarPluginInstance,
          download: getFilePluginInstance,
          print: printPluginInstance,
          theme: themePluginInstance,
          pageNav: pageNavigationPluginInstance,
          zoom: zoomPluginInstance,
        }}
      />
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderTB,
  });

  //handlers
  const handleDocumentLoad = () => {
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(1);
  };

  const handleSwitchTheme = (theme) => {
    localStorage.setItem('theme', theme)
  }

  const theme = localStorage.getItem('theme') || 'light'

  const handleDownload = () => {
    fetch(pdfURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        if(isIE){
          window.navigator.msSaveBlob(new Blob([blob]), `NW_NewHeightsIUL_${id}.pdf`);
        }else{
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `NW_NewHeightsIUL_${id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
      });
  };

  const handlePrint = () => {
    fetch(pdfURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        printJS(url);
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      });
  };

  return (
    <div>
      <Worker workerUrl={`${process.env.PUBLIC_URL}/scripts/pdf.worker.js`}>
        <div
          className='pdfViewerContainer'
        >
          <Viewer
            fileUrl={pdfURL}
            plugins={[
              defaultLayoutPluginInstance,
              getFilePluginInstance,
              printPluginInstance,
              themePluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
            ]}
            onDocumentLoad={handleDocumentLoad}
            theme={theme}
            onSwitchTheme={handleSwitchTheme}
            defaultScale={1.3}
            httpHeaders={{
              'Content-Disposition': 'attachment',
            }}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;
