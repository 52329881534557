import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { tryLoadFonts } from './utils/utils';

// import esLocaleData from 'react-intl/locale-data/es';

import './index.css';
import theme from './theme';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
// import translations from './i18n/locales';

// Added English and Spanish languages
// addLocaleData(enLocaleData);
// addLocaleData(esLocaleData);

function EmptyContent() {
  return <div />;
}

function renderAppContent(fontsReady) {
  if (fontsReady === false) {
    return <EmptyContent />;
  }
}
const Render = () => {
  // Uncomment this to accept different languages
  // const locale = window.location.search.replace('?locale=', '') || 'en';
  // const messages = translations[locale];

  const [fontsReady, setFontsReady] = useState(false);

  useEffect(() => {
    tryLoadFonts().then(() => {
      setFontsReady(true);
    })
  })

  return (
    <>
      <React.Suspense fallback={<EmptyContent />}>
        {renderAppContent(fontsReady)}
      </React.Suspense>
      <IntlProvider locale="en">
        <Router>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </Router>
      </IntlProvider>
    </>
  );
};

ReactDOM.render(<Render />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
