import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import variables from '../../themeVars';

const styles = () => ({
  root: {
    flexGrow: 1,
    minHeight: 70,
    cursor: 'default',
  },
  footer: {
    backgroundColor: 'rgb(61, 64, 69)',
    lineHeight: '16.22px',
    color: 'rgb(221, 221, 221)',
    fontSize: '12px',
    fontFamily: `${variables.fontRegular}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
  },
  marginTop: {
    marginTop: '40px',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  rightsReserved: {
    backgroundColor: 'rgb(67, 71, 77)',
    color: 'rgb(187, 187, 187)',
    fontSize: '12px',
    fontFamily: `${variables.fontRegular}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
    minHeight: '69px',
    height: '69px',
  },
  superIndex:{
    fontSize: '5px'
  }
});

const FooterBar = ({ classes, serverDateTime }) => {
  const year = serverDateTime ? serverDateTime.getFullYear() : '';
  return (
    <div color="primary" className={classes.appBar}>
      <Grid
        container
        className={classes.footer}
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
          <p className={classes.marginTop}>
            Indexed universal life insurance policies are not stock market investments, 
            do not directly participate in any stock or equity investments, and do not 
            receive dividend or capital gains participation. Past performance of an index 
            is no indication of future crediting rates.
          </p>
          <p>
            Guarantees are subject to the claims-paying ability of the issuing insurer. 
            Nationwide may discontinue any index that becomes unavailable (i.e., is no longer published) 
            or the calculation of which is substantially changed. Nationwide may substitute with a 
            comparable index or may adjust the method of calculating index segment interest.
          </p>
          <p>
            Be sure to choose a product that meets long-term life insurance needs, especially 
            if personal situations change &mdash; for example, marriage, birth of a child or 
            job promotion. Weigh the costs of the policy, and understand that life insurance 
            has fees and charges that vary with sex, health, age and tobacco use. Riders that 
            customize a policy to fit individual needs usually carry an additional charge.
          </p>
          <p>
            The J.P. Morgan Mozaic II<sup className={classes.superIndex}>SM</sup> Index (&ldquo;Index&rdquo;) 
            has been licensed to Nationwide Life and Annuity Insurance Company (the &ldquo;Licensee&rdquo;) 
            for the Licensee’s benefit. Neither the Licensee nor Nationwide New Heights&reg; Indexed Universal 
            Life Accumulator (the &ldquo;Product&rdquo;) is sponsored, operated, endorsed, recommended, sold or 
            promoted by J.P. Morgan Securities LLC (&ldquo;JPMS&rdquo;) or any of its affiliates 
            (together and individually, &ldquo;JPMorgan&rdquo;). JPMorgan makes no representation and 
            gives no warranty, express or implied, to contract owners taking exposure to the Product. 
            Such persons should seek appropriate professional advice before making any investment. 
            The Index has been designed and is compiled, calculated, maintained and sponsored by 
            JPMS without regard to the Licensee, the Product or any contract owner. JPMorgan is under 
            no obligation to continue compiling, calculating, maintaining or sponsoring the Index. 
            JPMorgan may independently issue or sponsor other indices or products that are similar 
            to and may compete with the Index and the Product. JPMorgan may also transact in assets 
            referenced in the Index (or in financial instruments such as derivatives that reference 
            those assets). These activities could have a positive or negative effect on the value of 
            the Index and the Product.
          </p>
          <p>
            The NYSE&reg; Zebra Edge&trade; Index has been licensed to 
            Nationwide Life and Annuity Insurance Company (&ldquo;Nationwide&rdquo;). Neither Nationwide nor the 
            Nationwide New Heights&reg; Indexed Universal Life Accumulator (the &ldquo;Product&rdquo;) is sponsored, 
            operated, endorsed, recommended, sold or promoted by Zebra, NYSE or UBS and in no event shall Zebra, NYSE 
            or UBS have any liability with respect to the Product or the Index. Zebra, NYSE and UBS make no 
            representations, give no express or implied warranties and have no obligations with regard to the Index, 
            the Product or otherwise to any investor in the Product, client or other third party. The marks NYSE&reg;, 
            and NYSE Arca&reg; are registered trademarks of NYSE Group, Inc., Intercontinental Exchange, Inc. or their 
            affiliates. The marks Zebra&reg; and Zebra Edge&trade; are trademarks of 
            Zebra, may not be used without prior authorization from Zebra, and are being utilized by NYSE under license 
            and agreement.
          </p>
          <p>
            The &ldquo;S&P 500&rdquo; is a product of S&P Dow Jones Indices LLC (&ldquo;SPDJI&rdquo;), and has 
            been licensed for use by Nationwide Life and Annuity Insurance Company (&ldquo;Nationwide&rdquo;). 
            Standard & Poor’s&reg;, S&P&reg; and S&P 500&reg; are registered trademarks of Standard & Poor’s Financial 
            Services LLC (&ldquo;S&P&rdquo;), and these trademarks have been licensed for use by SPDJI and sublicensed 
            for certain purposes by Nationwide. Nationwide’s products are not sponsored, endorsed, sold or promoted by 
            SPDJI and S&P, their respective affiliates, and none of such parties make any representation regarding the 
            advisability of investing in such product(s) nor do they have any liability for any errors, omissions, or 
            interruptions of the S&P 500.
          </p>
          <p>
            Products are issued by Nationwide Life Insurance Company or
            Nationwide Life and Annuity Insurance Company, Columbus, Ohio.
          </p>
          <p>
            Nationwide, its affiliates, and subsidiaries are not related to or
            affiliated with Annexus or its affiliates and subsidiaries.
          </p>
          <p>
            Nationwide, Nationwide is on your side, the Nationwide N and Eagle, 
            Nationwide Multiplier are service marks of Nationwide Mutual Insurance Company.
          </p>
          <p className={classes.marginBottom}>
            FLM-1278AO (09/19)
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.rightsReserved}
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
          <div>
            © {year} Nationwide Mutual Insurance Company | All Rights Reserved
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired,
  serverDateTime: PropTypes.any,
};

export default withStyles(styles)(FooterBar);
