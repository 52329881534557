import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { isIE } from 'react-device-detect';
import { PrintIcon } from '@react-pdf-viewer/print';
import { DownloadIcon } from '@react-pdf-viewer/get-file';
import { Box } from '@material-ui/core';

const ViewerToolbar = ({ plugins, handlePrint, handleDownload }) => {
  const { toolbar, print, download, theme, pageNav, zoom } = plugins;
  const { Download } = download;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoom;
  const { Print, PrintButton } = print;
  const { Toolbar } = toolbar;
  const { SwitchThemeButton } = theme;
  const { CurrentPageInput, GoToNextPageButton, GoToPreviousPage } = pageNav;
  return (
    <Toolbar>
      {() => (
        <Grid container direction='row'>
          <Grid container item direction='row' justify='space-between'>
            <Grid container item xs={4} justify='flex-start'>
              <Grid item>
                <GoToPreviousPage />
              </Grid>
              <Grid item>
                <CurrentPageInput />
              </Grid>
              <Grid item>
                <GoToNextPageButton />
              </Grid>
            </Grid>
            <Grid container item xs={4} justify='center'>
              <Grid item>
                <ZoomOutButton />
              </Grid>
              <Box component="span">
                <ZoomPopover />
              </Box>
              <Grid item>
                <ZoomInButton />
              </Grid>
            </Grid>
            <Grid container item xs={4} justify='flex-end'>
              <Grid item>
                {!isIE ? (
                  <Print>
                    {() => (
                      <button className='rpv-core__minimal-button' onClick={handlePrint}>
                        <PrintIcon />
                      </button>
                    )}
                  </Print>
                ) : (
                  <PrintButton />
                )}
              </Grid>
              <Grid item>
                <Download>
                  {() => (
                    <button className='rpv-core__minimal-button' onClick={handleDownload}>
                      <DownloadIcon />
                    </button>
                  )}
                </Download>
              </Grid>
              {!isIE && (
                <Grid item>
                  <SwitchThemeButton />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

ViewerToolbar.propTypes = {
  plugins: PropTypes.object,
  handleDownload: PropTypes.func,
  handlePrint: PropTypes.func,
};

export default ViewerToolbar;
