import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, FilledInput } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CalendarSvg from '../../containers/Illustration/components/CalendarSvg';
import CurrencyFormat from '../CurrencyFormat';
import InputMaskA999 from '../InputMaskA999';
import PercentFormat from '../PercentFormat';
import {
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_PERCENTAGE,
  DOWNSHIFT_OPTION_A99,
  DOWNSHIFT_OPTION_INPUT,
  DOWNSHIFT_OPTION_AGE_DOB,
} from './constants';
import AgeDOBInput from '../AgeDOBInput';

export const CustomIconButton = ({ handleOnClick, children, ...rest }) => (
  <IconButton
    disableRipple
    disableFocusRipple
    size="small"
    onClick={handleOnClick}
    {...rest}
  >
    {children}
  </IconButton>
);
CustomIconButton.propTypes = {
  handleOnClick: PropTypes.func,
  children: PropTypes.object.isRequired,
};

export const renderInput = props => {
  delete props.inputProps.onBlur;
  const {
    inputProps: { ...inputProps },
    inputType,
    hasPopup,
    isDropdown,
    handleOpenPopup,
    toggleButtonProps,
    isCustomizedValue,
    handleValueOnPopupSave,
    readOnly,
    clearSelection,
    openMenu,
    customInputType,
    ...other
  } = props;

  const getCustomFormat = () => {
    switch (inputType) {
      case DOWNSHIFT_OPTION_CURRENCY:
        return CurrencyFormat;
      case DOWNSHIFT_OPTION_PERCENTAGE:
        return PercentFormat;
      case DOWNSHIFT_OPTION_A99:
        return InputMaskA999;
      case DOWNSHIFT_OPTION_AGE_DOB:
        return AgeDOBInput;
      default:
        return 'input';
    }
  };

  const handleOnClick = () => {
    if (
      (isDropdown && (inputType === DOWNSHIFT_OPTION_INPUT || typeof inputType === 'undefined')) ||
      isCustomizedValue
    ) {
      openMenu();
    }
  };

  const handleOnKeyDown = event => {
    if (readOnly) {
      event.preventDefault();
    }
    if (
      (isDropdown && inputType === DOWNSHIFT_OPTION_INPUT) ||
      isCustomizedValue
    ) {
      const keyCode = event.keyCode || event.charCode;
      if (!readOnly && (keyCode === 8 || keyCode === 46)) {
        event.preventDefault();
        clearSelection();
      }
      if (
        (customInputType === DOWNSHIFT_OPTION_A99 && keyCode === 65) ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
        if (
          customInputType === DOWNSHIFT_OPTION_A99 ||
          customInputType === DOWNSHIFT_OPTION_CURRENCY ||
          customInputType === DOWNSHIFT_OPTION_PERCENTAGE
        ) {
          clearSelection();
        }
      }
    }
  };

  const handleOnKeyDownCustomized = event => {
    if (readOnly) {
      event.preventDefault();
    }
    const keyCode = event.keyCode || event.charCode;
    if (!readOnly && (keyCode === 8 || keyCode === 46)) {
      event.preventDefault();
      clearSelection();
    }
  };

  if (isCustomizedValue) {
    return (
      <FilledInput
        className="isDropdown"
        readOnly={true}
        disableUnderline
        value={handleValueOnPopupSave(inputProps.value)}
        inputProps={{
          onClick: handleOnClick,
          onKeyDown: handleOnKeyDownCustomized,
        }}
        endAdornment={
          <InputAdornment position="end">
            {hasPopup && (
              <CustomIconButton handleOnClick={handleOpenPopup}>
                <CalendarSvg />
              </CustomIconButton>
            )}
            {isDropdown && (
              <CustomIconButton {...toggleButtonProps}>
                <ArrowDropDown color="secondary" />
              </CustomIconButton>
            )}
          </InputAdornment>
        }
      />
    );
  }

  return (
    <FilledInput
      className={inputType === DOWNSHIFT_OPTION_INPUT ? 'isDropdown' : ''}
      autoComplete="off"
      disableUnderline
      inputComponent={getCustomFormat()}
      readOnly={readOnly || inputType === DOWNSHIFT_OPTION_INPUT}
      inputProps={{
        ...inputProps,
        onClick: handleOnClick,
        onKeyDown: handleOnKeyDown,
      }}
      {...other}
      endAdornment={
        <InputAdornment position="end">
          {hasPopup && (
            <CustomIconButton handleOnClick={handleOpenPopup}>
              <CalendarSvg />
            </CustomIconButton>
          )}
          {isDropdown && (
            <CustomIconButton {...toggleButtonProps}>
              <ArrowDropDown color="secondary" />
            </CustomIconButton>
          )}
        </InputAdornment>
      }
    />
  );
};

renderInput.propTypes = {
  customInputTypeOptions: PropTypes.any
};

renderInput.defaultProps = {
  customInputTypeOptions: {}
}