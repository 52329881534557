import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const loaderLabel = 'Generating illustration…';

const styles = () => ({
  spinnerWrapper: {
    position: 'absolute',
    textAlign: 'center',
    top: '32%',
    '& h5': {
      color: '#003b5c',
      fontFamily: 'Gotham-Medium',
      fontSize: 21,
      marginTop: 20,
    },
  },
  spinner: {
    animation: `$rotate 2s linear infinite`,
    zIndex: 2,
    width: 100,
    height: 100,
    stroke: '#346094',
  },
  path: {
    stroke: 'hsl(210, 70, 75)',
    strokeLinecap: 'round',
    animation: `$dash 1.5s ease-in-out infinite`,
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dash': {
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-35',
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-124',
    },
  },
});

const PdfLoader = ({ classes }) => {
  return (
    <Grid container spacing={0} direction='column' alignItems='center'>
      <Grid item xs={12} className={classes.spinnerWrapper}>
        <svg className={classes.spinner} viewBox='0 0 50 50'>
          <circle
            className={classes.path}
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='5'
          ></circle>
        </svg>
        <Typography variant='h5'>{loaderLabel}</Typography>
      </Grid>
    </Grid>
  );
};

PdfLoader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PdfLoader);
