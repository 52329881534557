import { useEffect } from 'react';
import AuthHelper from '../utils/auth';

const SECONDS_BEFORE_TOKEN_EXPIRES = parseInt(process.env.REACT_APP_SECONDS_BEFORE_TOKEN_EXPIRES) || 0;
const TIMER_LOGOUT_WHEN_NO_ACTION = SECONDS_BEFORE_TOKEN_EXPIRES * 1000;

const useTokenExpiration = (showModalCallback, sessionExpiredCallback) => {
    const authHelper = new AuthHelper();
  
    useEffect(() => {
      const checkExpiration = () => {
        const expirationTime = authHelper?.getTokenExpiration();
        const currentTime = Date.now() / 1000;
  
        if (expirationTime && currentTime >= expirationTime - SECONDS_BEFORE_TOKEN_EXPIRES) {
          showModalCallback();
  
          // Set a timer to automatically logout if no action is taken
          setTimeout(() => {
            if (authHelper?.isTokenExpired()) {
              sessionExpiredCallback();
            }
          }, TIMER_LOGOUT_WHEN_NO_ACTION);
        }
      };
  
      const expirationTime = authHelper?.getTokenExpiration();
      const currentTime = Date.now() / 1000;
      const delay = Math.max((expirationTime - currentTime - SECONDS_BEFORE_TOKEN_EXPIRES) * 1000, 0);
  
      // timer set to check token expiration
      const timer = setTimeout(checkExpiration, delay);
      return () => clearTimeout(timer);
    }, [showModalCallback, sessionExpiredCallback]);
  };
  
  export default useTokenExpiration;