import axios from 'axios';
import { HTTP_STATUS } from '../constants';
import AuthHelper from './auth';
import { getCleanApiURL, bindEvent, removeEvent, toQueryString } from './utils';
import {TOKEN_NAME} from './auth';

const auth = new AuthHelper();
let cancelComparison = axios.CancelToken.source();
const cancelationEndpoints = ["comparison"];
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    Expires: 0,
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

// Add a request interceptor to insert latest auth token
api.interceptors.request.use(
  config => {
    const token = auth.getToken();
    
    const cancelation = cancelationEndpoints.find(ce => ce === config.url.split('/').find(uri => uri === ce));

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if(cancelation) {
      config.cancelToken = cancelComparison.token;
    }
    return config;
  },
  
  error => Promise.reject(error),
);

// Middleware used to check 401 error and refresh token
api.interceptors.response.use(
  response => response,
  error => {
    if(error.message && error.message?.status === HTTP_STATUS.Canceled) {
      return Promise.reject({ status: HTTP_STATUS.Canceled });
    }

    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    if (status === HTTP_STATUS.Unauthorized) {
      if (!isRefreshing) {
        isRefreshing = true;
        const apiURL = getCleanApiURL();
        const iframeId = 'refreshTokenIFrame';
        const messageListener = () => {
          isRefreshing = false;
          onRrefreshed();
          const iframeElement = document.getElementById(iframeId);
          if (document.body.contains(iframeElement)) {
            iframeElement.parentElement.removeChild(iframeElement);
          }
          removeEvent(window, 'message', messageListener);
        };
        // Create the iframe
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', apiURL);
        iframe.setAttribute('id', iframeId);
        iframe.style.display = 'none';
        bindEvent(window, 'message', messageListener);
        document.body.appendChild(iframe);
        // Validate if iframe is loaded
        setTimeout(() => {
          if (isRefreshing) {
            window.location.href = apiURL;
          }
        }, 15000);
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(() => {
          const t = auth.getToken();
          originalRequest.headers.Authorization = `Bearer ${t}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }
    return Promise.reject(error);
  },
);

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = () => {
  refreshSubscribers.map(cb => cb());
  refreshSubscribers = [];
};

export const getSettings = () => api.get('/api/Settings');

export const getIllustrations = payload => {
  const queryString = toQueryString(payload);
  return api.get(`/api/Illustrations?${queryString}`);
};

export const saveIllustration = values =>
  api.post('/api/Illustrations', values);

export const getIllustration = id => api.get(`/api/Illustrations/${id}`);

export const getIllustrationsCSV = payload => {
  const queryString = toQueryString(payload);
  return api.get(`/api/Illustrations/csv/?${queryString}`);
};

export const getComparisonIllustration = payload => 
  api.post('/api/Illustrations/comparison', payload);

export const removeIillustration = id => api.delete(`/api/Illustrations/${id}`);

export const cancelComparisonIllustrationRequest = () => {
  cancelComparison.cancel({ status: HTTP_STATUS.Canceled });
  cancelComparison = null;
  cancelComparison = axios.CancelToken.source();
}

export const validateQueryStringToken = (payload) => api.post('/api/Settings/ValidateToken',payload);

export const renewToken = async () => {
  let tokenRenewed = false;
  try {
    const currentToken = sessionStorage.getItem(TOKEN_NAME);

    if (!currentToken) {
      throw new Error('No token available for renewal.');
    }

    const response = await api.post('/Saml/RenewToken', { Token: currentToken });

    if (response?.data?.token) {
      sessionStorage.setItem(TOKEN_NAME, response.data.token);
      tokenRenewed = true;
    }
  } catch {
    return tokenRenewed;
  }
  return tokenRenewed;
};
