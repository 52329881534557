import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { DELETE_CONFIRMATION_MESSAGE } from '../../containers/Illustration/constants';

const useStyles = makeStyles(theme => ({
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
      width: 120,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        width: 50,
      },
    },
  },
}));

const styles = () => ({
  root: {
    margin: 0,
    padding: '16px 16px 0 16px',
    fontSize: 24,
  },
});

export default function DeleteConfirmationDialog({
  isOpen,
  handleCloseDialog,
  handleRemoveIllustration,
}) {
  const classes = useStyles();

  const DialogTitle = withStyles(styles)(props => {
    const { children, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className='title-wrapper' {...other}>
        <Typography variant='h6' className='title'>{children}</Typography>
        <IconButton aria-label='close' className='closeButton' onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Dialog
          className='deleteConfirmationDialog'
          open={isOpen}
          onClose={handleCloseDialog}
          aria-labelledby='confirmation-dialog'
          aria-describedby='confirmation-dialog-description'
        >
          <DialogTitle id='confirmation-dialog'>
            {'Delete Illustration?'}
          </DialogTitle>
          <DialogContent className='dialogContent'>
            <DialogContentText id='confirmation-dialog-description'>
              {DELETE_CONFIRMATION_MESSAGE.map(text => <Typography component='span' className='dialogContent' key={text}>{text}</Typography>)}
            </DialogContentText>
          </DialogContent>
          <DialogActions className='confirmationButtons'>
            <div className={classes.buttons}>
              <Button onClick={handleCloseDialog} color='primary' className='keepBtn' variant='outlined'>
                Keep
              </Button>
              <Button variant='contained' color='primary' className='deleteBtn' onClick={handleRemoveIllustration}>
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
DeleteConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  classes: PropTypes.shape(),
  onClose: PropTypes.func,
  handleRemoveIllustration: PropTypes.func,
  handleCloseDialog: PropTypes.func,
};

DeleteConfirmationDialog.defaultProps = {
  isOpen: false,
  onClose: f => f,
  classes: undefined,
  children: undefined,
  handleRemoveIllustration: f => f,
  handleCloseDialog: f => f,
};
