import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const NotificationMessages = ({
  isOpen,
  messages,
  onHandleClose,
  notificationType,
}) =>
  messages ? (
    <React.Fragment>
      <Snackbar
        className={notificationType === 'Error' ? 'error-type' : ''}
        open={isOpen}
        onClose={onHandleClose}
        TransitionComponent={TransitionUp}
        message={messages.map(message => (
          <div className="message-container" key={message.MessageId}>
            {message.userMessage}
          </div>
        ))}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onHandleClose}
          >
            x
          </IconButton>,
        ]}
      />
    </React.Fragment>
  ) : null;

NotificationMessages.propTypes = {
  isOpen: PropTypes.bool,
  messages: PropTypes.array,
  onHandleClose: PropTypes.func,
  notificationType: PropTypes.string,
};

export default NotificationMessages;
