import React, { useContext } from 'react';

// custom hook to create FormWizard context
export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error(
      'FormWizard components cannot be rendered outside the FormWizard components',
    );
  }
  return context;
};

const WizardContext = React.createContext();

export default WizardContext;
