import _ from 'lodash';
import { ERRORS_MESSAGES } from '../../../constants';
import { minValue, maxValue } from '../../../utils/validate';
import { getCurrencyFormat } from '../../../utils/utils';

export const RidersValidations = values => {
  const errors = {};

  // Long term
  if (values.riders.longTermCareRiderII) {
    const longTermCareRiderIIErrors = {};
    if (!values.riders.longTermCareRiderInfo.maximumMonthlyBenefit) {
      longTermCareRiderIIErrors.maximumMonthlyBenefit = true;
      errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
    }
    if (
      !values.riders.longTermCareRiderInfo.longTermCareAmount &&
      values.riders.longTermCareRiderInfo.longTermCareAmount !== 0
    ) {
      longTermCareRiderIIErrors.longTermCareAmount = true;
      errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
    } else {
      const totalSpecifiedAmount =
        values.faceAmountAndPremium.totalSpecifiedAmount[0]
          .specifiedAmountValue;
      if (_.isNumber(values.riders.longTermCareRiderInfo.longTermCareAmount)) {
        const { longTermCareAmount } = values.riders.longTermCareRiderInfo;
        const maxAmount =
          totalSpecifiedAmount > 1500000 || !_.isNumber(totalSpecifiedAmount)
            ? 1500000
            : totalSpecifiedAmount;
        const min = minValue(100000)(longTermCareAmount);
        const max = maxValue(maxAmount)(longTermCareAmount);
        if (max) {
          longTermCareRiderIIErrors.longTermCareAmount = `${
            ERRORS_MESSAGES.MAX_VALUE
          } ${getCurrencyFormat(maxAmount)}`;
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
        if (min) {
          longTermCareRiderIIErrors.longTermCareAmount = `${ERRORS_MESSAGES.MIN_VALUE} $100,000`;
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
      } else if (totalSpecifiedAmount > 1500000) {
        longTermCareRiderIIErrors.longTermCareAmount = `${ERRORS_MESSAGES.MAX_VALUE} $1,500,000`;
        errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
      }
    }
    if (!values.riders.longTermCareRiderInfo.rateClass) {
      longTermCareRiderIIErrors.rateClass = true;
      errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
    }

    if (values.riders.longTermCareRiderInfo.printLTCAnalysisRepot) {
      const printLTCAnalysisRepotErrors = {};
      const {
        hipaaPerDiemRate,
      } = values.riders.longTermCareRiderInfo.LTCAnalysisReportInfo;
      if (!hipaaPerDiemRate && hipaaPerDiemRate !== 0) {
        printLTCAnalysisRepotErrors.hipaaPerDiemRate = true;
        longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
          ...printLTCAnalysisRepotErrors,
        };
        errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
      } else {
        const max = maxValue(10)(hipaaPerDiemRate);
        if (max) {
          printLTCAnalysisRepotErrors.hipaaPerDiemRate = `${ERRORS_MESSAGES.MAX_VALUE} 10%`;
          longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
            ...printLTCAnalysisRepotErrors,
          };
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
      }

      const {
        ageToBeginLTCRiderBenefit,
      } = values.riders.longTermCareRiderInfo.LTCAnalysisReportInfo;
      const minAge = parseInt(values.insured.issueAgeOrDateOfBirth) + 1; // needs to update if it is D.O.B
      if (!ageToBeginLTCRiderBenefit && ageToBeginLTCRiderBenefit !== 0) {
        printLTCAnalysisRepotErrors.ageToBeginLTCRiderBenefit = true;
        longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
          ...printLTCAnalysisRepotErrors,
        };
        errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
      } else {
        const max = maxValue(118)(ageToBeginLTCRiderBenefit);
        if (max) {
          printLTCAnalysisRepotErrors.ageToBeginLTCRiderBenefit = `${ERRORS_MESSAGES.MAX_AGE} 118`;
          longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
            ...printLTCAnalysisRepotErrors,
          };
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }

        const min = minValue(minAge > 0 ? minAge : 0)(
          ageToBeginLTCRiderBenefit,
        );
        if (min) {
          printLTCAnalysisRepotErrors.ageToBeginLTCRiderBenefit = `${ERRORS_MESSAGES.MIN_AGE} ${minAge}`;
          longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
            ...printLTCAnalysisRepotErrors,
          };
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
      }

      const {
        desiredDailyLTCBenefit,
      } = values.riders.longTermCareRiderInfo.LTCAnalysisReportInfo;
      if (!desiredDailyLTCBenefit && desiredDailyLTCBenefit !== 0) {
        printLTCAnalysisRepotErrors.desiredDailyLTCBenefit = true;
        longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
          ...printLTCAnalysisRepotErrors,
        };
        errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
      } else {
        const min = minValue(25)(desiredDailyLTCBenefit);
        const max = maxValue(999999)(desiredDailyLTCBenefit);
        if (max) {
          printLTCAnalysisRepotErrors.desiredDailyLTCBenefit = `${ERRORS_MESSAGES.MAX_VALUE} $999,999`;
          longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
            ...printLTCAnalysisRepotErrors,
          };
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
        if (min) {
          printLTCAnalysisRepotErrors.desiredDailyLTCBenefit = `${ERRORS_MESSAGES.MIN_VALUE} $25`;
          longTermCareRiderIIErrors.LTCAnalysisReportInfo = {
            ...printLTCAnalysisRepotErrors,
          };
          errors.longTermCareRiderInfo = { ...longTermCareRiderIIErrors };
        }
      }
    }
  }

  // Accidental Death Benefit
  if (values.riders.accidentalDeathBenefit) {
    const adbAmountAndRatingErrors = {};
    if (
      !values.riders.adbAmountAndRating.ADBAmount &&
      values.riders.adbAmountAndRating.ADBAmount !== 0
    ) {
      adbAmountAndRatingErrors.ADBAmount = true;
      errors.adbAmountAndRating = { ...adbAmountAndRatingErrors };
    } else {
      const { ADBAmount } = values.riders.adbAmountAndRating;
      if (!isNaN(parseFloat(ADBAmount))) {
        const min = minValue(1000)(ADBAmount);
        const max = maxValue(200000)(ADBAmount);
        if (max) {
          adbAmountAndRatingErrors.ADBAmount = `${ERRORS_MESSAGES.MAX_VALUE} $200,000`;
          errors.adbAmountAndRating = { ...adbAmountAndRatingErrors };
        }
        if (min) {
          adbAmountAndRatingErrors.ADBAmount = `${ERRORS_MESSAGES.MIN_VALUE} $1,000`;
          errors.adbAmountAndRating = { ...adbAmountAndRatingErrors };
        }
      }
    }
    if (!values.riders.adbAmountAndRating.ADBRating) {
      adbAmountAndRatingErrors.ADBRating = true;
      errors.adbAmountAndRating = { ...adbAmountAndRatingErrors };
    }
  }

 // Waiver Rider
  if (values.riders.waiverRider) {
    const waiverRiderErrors = {};
    if (!values.riders.waiverRiderInfo.waiverType) {
      waiverRiderErrors.waiverType = true;
      errors.waiverRiderInfo = { ...waiverRiderErrors };
    }
    if (values.riders.waiverRiderInfo.waiverType === 'Premium Waiver') {
      if (
        !values.riders.waiverRiderInfo.premiumAmount &&
        values.riders.waiverRiderInfo.premiumAmount !== 0
      ) {
        waiverRiderErrors.premiumAmount = true;
        errors.waiverRiderInfo = { ...waiverRiderErrors };
      } else {
        const { premiumAmount } = values.riders.waiverRiderInfo;
        if (_.isNumber(premiumAmount)) {
          const min = minValue(50)(premiumAmount);
          const max = maxValue(2500)(premiumAmount);
          if (min) {
            waiverRiderErrors.premiumAmount = `${ERRORS_MESSAGES.MIN_VALUE} $50`;
            errors.waiverRiderInfo = { ...waiverRiderErrors };
          }
          if (max) {
            waiverRiderErrors.premiumAmount = `${ERRORS_MESSAGES.MAX_VALUE} $2500`;
            errors.waiverRiderInfo = { ...waiverRiderErrors };
          }
        }
      }
    }
    if (!values.riders.waiverRiderInfo.waiverRating) {
      waiverRiderErrors.waiverRating = true;
      errors.waiverRiderInfo = { ...waiverRiderErrors };
    }
  }
  if (_.isEmpty(errors)) {
    return {};
  }
  return { riders: { ...errors } };
};
