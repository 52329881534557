export const CREDITING_RATE = {
  DEFAULT: 4.00, 
  MAX_VALUE: 4.00, 
  MIN_VALUE: 1
};
export const CORE_INTEREST = {
  DEFAULT: 6.30,
  MIN_VALUE: 0,
  MAX_VALUE: 6.30,
  DEFAULT_1_SP500: 6.30,
  MAX_1_SP500: 6.30,
  MIN_1_SP500: 0.5,
  DEFAULT_2_SP500: 6.30,
  MAX_2_SP500: 6.30,
  MIN_2_SP500: 0,
  MIN_GOLDMAN: 0,
  MAX_GOLDMAN: 6.30,
  DEFAULT_GOLDMAN: 6.30
};

export const PLUS_INDEXED_INTEREST = {
  DEFAULT: 7.37,
  MIN_VALUE: 0,
  MAX_VALUE: 7.37,
  DEFAULT_1_SP500: 7.40,
  MAX_1_SP500: 7.40,
  MIN_1_SP500: 0.5
};

export const ERRORS_MESSAGES = {
  MIN_VALUE: 'Minimum value is',
  MAX_VALUE: 'Maximum value is',
  MIN_AGE: 'Minimum age is',
  MAX_AGE: 'Maximum age is',
};

export const MAX_NUMBER = {
  MAX_STRING: '$9,999,999,999',
  MAX_VALUE: 9999999999,
};

export const MIN_NUMBER = {
  MIN_STRING: '$100,000',
  MIN_VALUE: 100000,
}

export const MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT = {
  MAX_STRING: '$100,000,000',
  MAX_VALUE: 100000000,
}

export const POPUP_NAMES = {
  TOTAL_AMOUNT: 'Total Specified Amount',
  SCHEDULED_AMOUNT: 'Scheduled Premium',
};

export const TABLE = {
  MAX_ROWS: 10,
};

export const MAX = 'Max';
export const SOLVE = 'Solve';
export const LIFETIME = 'Lifetime';
export const CURRENT = 'Current';

export const NEW_PRODUCT = 'Nationwide New Heights Indexed UL Accumulator 2020';

export const RISK_CLASS_STANDARD_NON_TABACCO = 'Standard Nontobacco';
export const TEEN_AGE = 18;
export const MATURE_AGE = 70;
export const INCREASE_INITIALDB_X = 20;

export const FASE_AMOUNT_PREMIUM = {
  MinNonMec: {
    value: 'Minimum Non-MEC'
  },
  BalanceSolve: {
    value: 'Balanced Solve'
  },
  SpecAmount: {
    value: 'Specified Amount'
  },
};

export const NONMEC_PERCENT_INCREASE = {
  MIN_VALUE: 0,
  MAX_VALUE: 80, 
};

export const HTTP_STATUS = {
  Unauthorized: 401,
  Canceled: 499
}

export const ANSWER = {
  Yes: 'Yes',
  No: 'No'
}

export const USER_CLICK_EVENTS = {
  ESC_PRESSED: 'escapeKeyDown',
  BACKDROP_CLICK: 'backdropClick'
}

export const POPULATE_SOURCE_KEY = 'POPULATE_SOURCE';

export const MATURED_SEGMENT_ALLOCATION_DEFAULT = 'Reallocate to Matured Segment Strategy';

export const PDF_FILENAMES = Object.freeze({
  BALANCE_SOLVE: 'BalanceSolve.pdf',
  MIN_NON_MEC: 'MinNonMec.pdf',
  SPEC_AMOUNT: 'SpecAmnt.pdf'
});

export const TERM_LIFE_QUOTE_FACE_AMOUNT ={
  MIN_VALUE: 100000,
  MIN_STRING : '$100,000',
  MAX_VALUE: 1000000,
  MAX_STRING: '$1,000,000'
}

export const GENDER = {
  MALE: 'Male', 
  FEMALE: 'Female'
}

export const AGE_TERM_LIFE = {
  MIN: 18,
  MAX: 70
}

export const HTTP_MIN_ERROR_STATUS_CODE = 400;
