import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  getCurrencyFormat,
  isPercentFormat,
  getNumbersAndDecimalsOnly,
} from '../../utils/utils';

const CurrencyPercentFormat = ({
  inputRef,
  onChange,
  decimalscale,
  returntypevalue,
  ...rest
}) => {
  const handleOnChange = values => {
    let value = null;
    switch (returntypevalue) {
      case 'formattedValue':
        value =
          values.formattedValue === undefined ? '' : values.formattedValue;
        break;
      case 'floatValue':
        value = values.floatValue === undefined ? '' : values.floatValue;
        break;
      case 'value':
        value = values.value === undefined ? '' : values.value;
        break;
      default:
        value = values;
        break;
    }
    if (onChange) {
      onChange({
        currentTarget: {
          value: value,
        },
        target: {
          value: value,
        },
      });
    }
    return values;
  };

  const valueFormat = val => val;

  const handleRemoveFormatting = value => {
    if (!value && value !== 0) {
      return '';
    }
    const unformattedValue = getNumbersAndDecimalsOnly(value);
    if (isPercentFormat(value)) {
      return `${unformattedValue}%`;
    }
    if (/\d[.]?/g.test(value)) {
      return getCurrencyFormat(unformattedValue);
    }
    return '';
  };

  return (
    <NumberFormat
      {...rest}
      format={valueFormat}
      removeFormatting={handleRemoveFormatting}
      getInputRef={inputRef}
      onValueChange={values => {
        handleOnChange(values);
      }}
      thousandSeparator
      inputMode="decimal"
      allowNegative={false}
      decimalScale={decimalscale}
      allowEmptyFormatting={true}
      isNumericString={true}
    />
  );
};

CurrencyPercentFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalscale: PropTypes.number,
  returntypevalue: PropTypes.string.isRequired,
};

CurrencyPercentFormat.defaultProps = {
  decimalscale: 2,
  fixedDecimalScale: false,
  returntypevalue: 'formattedValue',
};

export default CurrencyPercentFormat;
