import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

export const InputMaskA999 = ({ inputRef, onChange, ...rest }) => {
  const [mask, setMask] = useState('a999');

  const handleOnChange = event => {
    let value = event.target.value;
    if (!value && value !== 0) {
      setMask('a999');
    }
    if (/(^[aA])/.test(value)) {
      value = value.toUpperCase();
      setMask('a999');
    } else {
      setMask('a99');
    }
    onChange({
      ...event,
      currentTarget: {
        value: value,
      },
      target: {
        value: value,
      },
    });
  };

  return (
    <InputMask
      {...rest}
      formatChars={{
        '9': '[0-9]',
        a: '[aA0-9]',
      }}
      inputRef={inputRef}
      maskChar={null}
      mask={mask}
      onChange={handleOnChange}
    />
  );
};

InputMaskA999.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputMaskA999;
