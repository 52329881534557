const popupOnSaveHandler = (values, field, form, isAdvanced) => {
  if (Array.isArray(values) && !isAdvanced) {
    values.map(item => {
      delete item.increase;
      delete item.max;
      delete item.step;
      return item;
    });
  }
  form.setFieldValue(field.name, values);
  form.setFieldTouched(field.name, true);
};

const getInitialAdvancedViewValue = value =>
  !!value.length && typeof value[0].max !== 'undefined' && value[0].max !== '';

const FieldWithAdvancedTable = {
  popupOnSaveHandler,
  getInitialAdvancedViewValue,
};

export default FieldWithAdvancedTable;
