import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { Formik, FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import variables from '../../../../themeVars';
import messages from '../../messages';

const styles = () => ({
  wrapper: {
    '& .MuiTableRow-root': {
      display: 'table-row',
      border: 'none',
      '& .MuiTableCell-body': {
        backgroundColor: '#f7f7f7',
        fontFamily: variables.fontRegular,
        '&:first-child': {
          backgroundColor: '#edeef0',
          fontFamily: variables.fontRegular,
        },
        '&:last-child': {
          backgroundColor: 'transparent',
          border: 'none',
        },
        '@media (max-width: 599.95px)': {
          backgroundColor: 'transparent',
          '&:first-child': {
            backgroundColor: 'transparent',
          },
        },
      },
      '&:nth-child(even)': {
        '& .MuiTableCell-body': {
          backgroundColor: '#ffffff',
          '&:first-child': {
            backgroundColor: '#edeef0',
            '& .MuiFilledInput-root': {
              backgroundColor: '#FCFCFC'
            }
          },
          '&.premium-table': {
            '&:first-child': {
              backgroundColor: '#ffffff !important',
            },
          },
        },
      },
      '@media (max-width: 599.95px)': {
        backgroundColor: 'transparent',
        borderTop: '1px solid #E6E6E6',
        paddingTop: 8,
        display: 'block',
        '&:first-child': {
          border: 'none',
          padding: 0,
        },
      },
    },
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        paddingRight: '0px !important',
        '& button': {
          padding: 12,
        },
        '& .MuiIconButton-root svg': {
          strokeWidth: 0.8,
          stroke: 'ghostwhite',
        },
      },
      '& .MuiInputBase-root': {
        border: 'none',
        borderRadius: 0,
      },
      '& .Mui-error input': {
        backgroundColor: 'rgba(255,0,0,0.3)',
      },
      padding: '14px 10px 14px 16px',
      '&.face-amount-cell': {
        '&:first-child': {
          minWidth: '170px',
        },
        '&.distribution-amount-action': {
          '& .dropdown-style': {
            width: 310,
          },
        },
      },
      '&.fixed-crediting-rate': {
        '& .dropdown-style': {
          width: 210,
        },
      },
      '@media (max-width: 599.95px)': {
        display: 'inline-block',
        width: '65%',
        marginBottom: '5px',
        minWidth: 'auto !important',
        border: 'none',
        backgroundColor: 'transparent !important',
        '& .MuiInputBase-root': {
          border: '1px solid #d9d9d9',
          borderRadius: 4,
        },
        '&:nth-child(odd)': {
          width: '35%',
        },
        '&.premium-table': {
          maxWidth: 'none',
          width: '45%',
          '&:nth-child(odd)': {
            width: '55%',
          },
        },
        '& .MuiInputAdornment-root': {
          '&.MuiInputAdornment-filled': {
            '&[class*="positionEnd"]': {
              marginLeft: 0,
            },
          },
          '& button:nth-child(1)': {
            '& svg.MuiSvgIcon-root': {
              marginRight: 0,
            },
          },
        },
      },
    },
    '& .MuiTableCell-head': {
      fontFamily: variables.fontBold,
      fontSize: '13px',
      color: variables.primaryColor,
      letterSpacing: '0',
      lineHeight: '15px',
      border: 'none',
      display: 'table-cell',
    },
    '& .MuiTableCell-body': {
      padding: 0,
      '& .MuiInputAdornment-root': {
        '&[class*="positionEnd"]': {
          marginLeft: '-3px',
        },
        '& button:nth-child(1)': {
          '& .MuiSvgIcon-root': {
            marginRight: '-3px',
          },
        },
      },
      '&.premium-table': {
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
          maxWidth: 115,
        },
        '&:first-child': {
          backgroundColor: '#f7f7f7 !important',
        },
        '&:nth-last-child(2)': {
          backgroundColor: '#edeef0',
        },
        '@media (max-width: 1279.95px)': {
          padding: '14px 0px',
          minWidth: 85,
          '&&:nth-last-child(3)': {
            minWidth: '120px',
          },
        },
      },
      '& input': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderRadius: 0,
        color: '#222222',
        fontSize: '15px',
        height: '51px',
        letterSpacing: '0',
        lineHeight: '17px',
        padding: '0px 0px 0px 15px',
      },
    },
  },
});

const TableDialog = ({
  handleOnClose,
  handleOnSave,
  handleValidateForm,
  initialValues,
  isAdvanced,
  isOpen,
  render,
  size,
  title,
  classes}) => {
  const intl = useIntl();

  const handleSubmit = (values) => {
    handleOnSave(values.valuesPopup, values.isAdvanced);
    handleOnClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      aria-labelledby="table-dialog-title"
      maxWidth={size}
    >
      <DialogTitle id="table-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent className={classes.wrapper}>
        <Formik
          initialValues={{
            isAdvanced,
            valuesPopup: initialValues, // Work with a FieldArray valuesPopup name
          }}
          validate={values =>
            handleValidateForm(values, { isAdvanced: values.isAdvanced })
          }
          onSubmit={handleSubmit}
          render={formikProps => (
            <form>
              <FieldArray
                name="valuesPopup"
                render={arrayHelpers => (
                  <React.Fragment>
                    {render({
                      formikProps,
                      arrayHelpers,
                      isAdvanced,
                    })}
                  </React.Fragment>
                )}
              />
              <DialogActions>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={formikProps.handleSubmit}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
                <Button
                  onClick={handleOnClose}
                  variant="outlined"
                  color="primary"
                >
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

TableDialog.propTypes = {
  handleOnSave: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleValidateForm: PropTypes.func.isRequired,
  initialValues: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isAdvanced: PropTypes.bool.isRequired,
  size: PropTypes.string,
  classes: PropTypes.object.isRequired,
  render: PropTypes.any,
  maxWidth: PropTypes.any,
};

TableDialog.defaultProps = {
  size: 'md',
  title: '',
  initialValues: [],
  isAdvanced: false,
};

export default withStyles(styles)(TableDialog);
