import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import variables from '../../themeVars';

const SkeletonComponent = props => {
    const {
        baseBolor,
        highlightColor,
        height,
        duration,
        className
    } = props;
    return (
      <>
        {
            (baseBolor || highlightColor) ? 
            (<SkeletonTheme baseColor={baseBolor} highlightColor={highlightColor}>
              <Skeleton className={className} height={height} duration={duration}/>
            </SkeletonTheme>) : 
          (<Skeleton className={className} height={height} duration={duration}/>)
        }
      </>
    )
}

SkeletonComponent.propTypes = {
    baseBolor: PropTypes.string,
    highlightColor: PropTypes.string,
    height: PropTypes.number,
    duration: PropTypes.number,
    className: PropTypes.string
}

SkeletonComponent.defaultProps = {
    baseBolor: undefined,
    highlightColor: variables.skeletonHighlight,
    height: 20,
    duration: 0.7,
    className: ''
}

export default SkeletonComponent;