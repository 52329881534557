/* eslint-disable no-console */
import moment from 'moment';
import FontFaceObserver from 'fontfaceobserver';

export const removeParamFromURL = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArr = [];

  const queryString =
    sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split('=')[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length > 0) {
      rtn = `${rtn}?${paramsArr.join('&')}`;
    }
  }
  return rtn;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const toQueryString = params =>
  Object.keys(params)
    .filter(key => params[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const getIdFromUrl = () => {
  const path = window.location.pathname;
  if(path.split('/').includes('viewPDF')){
    const idIndex = path.split('/').indexOf('viewPDF') + 1
    return path.split('/')[idIndex]
  }
  return path.split('/')[2] ?? 0;
};

export const getCleanApiURL = () => {
  const urlWithoutToken = removeParamFromURL('token', window.location.href);
  return `${process.env.REACT_APP_SAML_SSO_URL}${encodeURIComponent(
    urlWithoutToken,
  )}`;
};

export const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

export const removeEvent = (element, eventName, eventHandler) => {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler, false);
  } else if (element.detachEvent) {
    element.detachEvent(`on${eventName}`, eventHandler);
  }
};

export const getCurrencyFormat = value => {
  value += '';
  const x = value.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `${'$1'}${','}${'$2'}`);
  }
  return `$${x1}${x2}`;
};

export const getFirstName = value => {
  value = value?.trim();
  const values = value.split(' ');
  return values[0];
};

export const getMiddleName = value => {
  value = value?.trim();
  const values = value.split(' ');
  if (values.length > 2) {
    return values[1];
  }
  return '';
};

export const getLastName = value => {
  value = value?.trim();
  const values = value.split(' ');
  if (values.length >= 3) {
    let finalValue = '';
    for (let x = 2; x < values.length; x++) {
      finalValue += `${values[x]} `;
    }
    return finalValue.trim();
  }
  return values[1];
};

export const isDateFormat = date => /^\d{2}([/])\d{2}\1\d{4}$/.test(date);

export const isCurrencyFormat = value => {
  return /\$\d[.,]?/.test(value);
};

export const isPercentFormat = value => {
  return /\d+(%)/.test(value);
};

export const getYearsDateOfBirth = date => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};


/**
 * Get birthday in years, months and days
 * @param {Date} today 
 * @param {Date} birthday 
 */
function _calculateAge(today, date) { // birthday is a date
  const birthday = new Date(date);
  const startdateMoment = moment(today);
  const enddateMoment = moment(birthday);

  // getting the difference in years
  const years = startdateMoment.diff(enddateMoment, 'years');
  // moment returns the total months between the two dates, subtracting the years
  const months = startdateMoment.diff(enddateMoment, 'months') - (years * 12);
  // to calculate the days, first get the previous month and then subtract it
  enddateMoment.add(years, 'years').add(months, 'months');
  const days = startdateMoment.diff(enddateMoment, 'days')

  return {
    years,
    months,
    days
  };
}

/**
 * The age, expressed as an integral number of years,
 * of an insurance policyholder, which spreads over the period
 * from six months prior to a birthday to six months after a birthday.
 * @param {string} date
 * @returns {number} age nearest to product
 */
export const getAgeNearestProduct = (today, date) => {
  try {
    const {days, months, years} = _calculateAge(today, date);
    let age = years;
  
    if (months >= 6 && days > 0) {
      age++;
    }
    return age;
  } catch (err) {
    console.log(err)
    return '';
  }
};

export const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Get numbers and decimals from a formatted value, otherwise returns null
 * @param {string} value Formatted value
 */
export const getNumbersAndDecimalsOnly = value => {
  return typeof value === 'string'
    ? (value.match(/\d*[.]?/g) || []).join('')
    : null;
};

export const splitDecimal = (numStr, allowNegative = true) => {
  const hasNagation = numStr[0] === '-';
  const addNegation = hasNagation && allowNegative;
  numStr = numStr.replace('-', '');

  const parts = numStr.split('.');
  const beforeDecimal = parts[0];
  const afterDecimal = parts[1] || '';

  return {
    beforeDecimal,
    afterDecimal,
    hasNagation,
    addNegation,
  };
};

/**
 * Get a number and decimals with currency format 10.20% or 10%
 * @param {string} numStr number to be formatted
 * @param {scale} scale number of decimals to display
 */
export const getCurrencyFormatWithDecimals = (numStr, scale = 2) => {
  return `$${formatNumberWithDecimals(numStr, scale)}`;
};
/**
 * Get a number and decimals with percent format 10.20% or 10%
 * @param {string} numStr number to be formatted
 * @param {scale} scale number of decimals to display
 */
export const getPercentFormat = (numStr, scale = 2) => {
  return `${formatNumberWithDecimals(numStr, scale)}%`;
};
export const formatNumberWithDecimals = (numStr, scale = 2) => {
  numStr += '';
  // split decimals
  let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr, true);
  // appends zeros

  if (afterDecimal) {
    afterDecimal = limitToScale(afterDecimal, scale, true);
  }
  // has decimal
  const hasDecimalSeparator = numStr.indexOf('.') !== -1 && !!afterDecimal;
  // add negation
  if (addNegation) beforeDecimal = '-' + beforeDecimal;
  // thousands format
  beforeDecimal = applyThousandSeparator(beforeDecimal, ',');
  return `${beforeDecimal}${hasDecimalSeparator ? '.' : ''}${afterDecimal}`;
};

export const limitToScale = (numStr, scale, fixedDecimalScale) => {
  var str = '';
  var filler = fixedDecimalScale ? '0' : '';

  for (var i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler;
  }
  // if (str === '00') {
  //   return '';
  // }
  return str;
};

export const applyThousandSeparator = (str, thousandSeparator) => {
  var thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  var index = str.search(/[1-9]/);
  index = index === -1 ? str.length : index;
  return (
    str.substring(0, index) +
    str
      .substring(index, str.length)
      .replace(thousandsGroupRegex, '$1' + thousandSeparator)
  );
};

export const removeCharacter = (strToRemove = '', str = '') => {
  let reg = new RegExp(strToRemove);
  return str.toString().match(strToRemove) ? str.replace(reg, '') : str;
};
export const createCSVDownload = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const waitForFonts = (names = []) =>
    Promise.all(
        names.map(name =>
            new FontFaceObserver(name).load().catch(err => {
                console.warn(`Error loading font ${name}`, err);
            })
        )
    );

export async function tryLoadFonts() {
    try {
        await waitForFonts([
            'Gotham-Book',
            'Gotham-Medium',
            'Lato',
        ]);
    } catch (err) {
        console.warn('Could not load fonts', err);
    }
}

export const isMobile = /Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent);

const createPdfBlob = (base64Pdf) => {
  const pdfData = atob(base64Pdf);
  const pdfBytes = new Uint8Array(pdfData.length);
  for (let i = 0; i < pdfData.length; i++) {
    pdfBytes[i] = pdfData.charCodeAt(i);
  }
  return new Blob([pdfBytes], { type: 'application/pdf' });
};

const openPdf = (url, fileName) => {
  if (isMobile) {
    const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.document.title = fileName;
      }
  } else {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  }
};

export const downloadPdf = (base64Pdf, fileName = 'file.pdf') => {
  const pdfBlob = createPdfBlob(base64Pdf);
  const url = URL.createObjectURL(pdfBlob);
  openPdf(url, fileName);
  setTimeout(() => URL.revokeObjectURL(url), 1000);
};
