import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppContext from './AppContext';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthHelper from '../../utils/auth';
import { USER_CLICK_EVENTS } from '../../constants';
import { inIframe, getCleanApiURL, removeParamFromURL, getIdFromUrl } from '../../utils/utils';
import useTokenExpiration from '../../hooks/useTokenExpiration';
import './App.css';
import Illustration from '../Illustration';
import PDFLoader from '../Illustration/pdfLoader';
import IllustrationHistory from '../IllustrationHistory';
import NotFoundPage from '../../components/NotFoundPage';
import { getSettings } from '../../utils/api';
import TagManager from 'react-gtm-module';
import { NEW_PRODUCT } from '../../constants';
import { withRouter } from 'react-router';
import ExpirationModal from '../../components/ExpirationModal';
import PDFViewer from '../../components/PDFViewer';
import { renewToken } from '../../utils/api';

const auth = new AuthHelper();

const Content = () => (
  <Switch>
    <Route exact path='/' component={Illustration} />
    <Route path='/illustration/:id(\d+)' component={Illustration} />
    <Route path='/illustration/viewPDF/:id' component={PDFViewer} />
    <Route exact path='/view-history' component={IllustrationHistory} />
    <Route path='/illustration/pdfLoader' component={PDFLoader} />
    <Route component={NotFoundPage} />
  </Switch>
);

const handleLogout = (_event, reason) => {
  if (reason && (reason === USER_CLICK_EVENTS.BACKDROP_CLICK || reason === USER_CLICK_EVENTS.ESC_PRESSED)) {
    return;
  }
  auth.logout();
};

function App({ location }) {
  const [settings, setSettings] = useState();
  const [advisorDetails, setAdvisorDetails] = useState();
  const [illustrationId, setIllustrationId] = useState();
  const [token, setToken] = useState();
  const [isIframe, setIsIframe] = useState(false);
  const isAuthenticated = auth.isAuthenticated();
  const [currentProduct, setCurrentProduct] = useState(NEW_PRODUCT);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSessionExpired, setSessionExpired] = useState(false);

  const showModal = () => {
    if (!isSessionExpired) {
      setModalOpen(true);
    }
  }; 

  const closeModal = (_event, reason) => {
    if (reason && (reason === USER_CLICK_EVENTS.BACKDROP_CLICK || reason === USER_CLICK_EVENTS.ESC_PRESSED)) {
      return;
    }
    setModalOpen(false);
  }; 

  const handleSessionExpiration = () => {
    setSessionExpired(true);
    setModalOpen(true);
  };
  
const handleRenewSession = async () => {
  const success = await renewToken();
  if (success) {
    setModalOpen(false);
  } else {
    handleLogout();
  }

  return;
};

  useTokenExpiration(showModal, handleSessionExpiration);

  useEffect(() => {
    
    async function setInitialSettings() {
      let didCancel = false;
      // Validate user authentication
      if (!isAuthenticated) {
        window.location.href = getCleanApiURL();
        return;
      }

      const token = await auth.getToken();
      setToken(token);
      window.history.replaceState(null, null, removeParamFromURL('token', window.location.href));
      // send message to parent when is iframe
      setIsIframe(inIframe());
      if (isIframe) {
        window.parent.postMessage('Get new access token', '*');
      }

      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
        dataLayer: auth.getDataLayer(token),
      };
      await TagManager.initialize(tagManagerArgs);

      setIllustrationId(await getIdFromUrl());
      await getSettings().then(result => {
        if (!didCancel) {
          const settings = result.data.settings;
          const maxAllowedIssueAge = Math.max.apply(Math, settings.riskClasses.map(o => o.maxAge));
          setSettings({
            ...settings,
            maxAllowedIssueAge,
            serverDateTime: new Date(result.data.serverDateTime),
            featureFlip: result.data.featureFlip,
          });
          setAdvisorDetails(result.data.user);
        }
      });
      return () => {
        didCancel = true;
      };
    }
    if (!isSessionExpired) {
      setInitialSettings();
    }
  }, [isIframe, isAuthenticated, isSessionExpired]);

  useEffect(() => {
    auth.setToken(token);
  }, [token]);

  const getPathName = path => {
    return location.pathname.indexOf(path) === -1;
  };

  if (!settings) return null;

  return (isAuthenticated || isSessionExpired) && !isIframe ? (
    <AppContext.Provider
      value={{
        isAuthenticated,
        token,
        illustrationId,
        settings,
        advisorDetails,
        currentProduct,
        setCurrentProduct,
      }}
    >
      <React.Fragment>
        <CssBaseline />
        {getPathName('/illustration/viewPDF') && getPathName('/illustration/pdfLoader') ? (
          <>
            <Header />
            <Grid
              container
              spacing={0}
              alignItems='center'
              justify='center'
              className={getPathName('/view-history') ? null : 'wrap'}
            >
              <Grid item xs={12} sm={10} md={9} lg={8} xl={6}>
                <ExpirationModal 
                isOpen={isModalOpen} 
                onRenewSession={handleRenewSession} 
                onClose={closeModal}
                onLogout={handleLogout}
                isSessionExpired={isSessionExpired}
                tokenLeftExpirationTime={settings?.tokenExpireTimeOnHours}
            />
                <Content />
              </Grid>
            </Grid>
            <Footer
              serverDateTime={settings && settings.serverDateTime ? settings.serverDateTime : ''}
            />
          </>
        ) : (
          <Content />
        )}
      </React.Fragment>
    </AppContext.Provider>
  ) : null;
}

App.propTypes = {
  location: PropTypes.any,
};

export default withRouter(App);
