import { isDateFormat, getAgeNearestProduct } from './utils';

/**
 * Validate Birthday or Age field as required
 * @param {object} values
 * @param {object} settings
 * @return {number | null} calculatedIssueAge
 */
export const ageCalculation = (issueAge, settings) => {
  if (!issueAge && issueAge !== 0) {
    return null;
  }

  let calculatedIssueAge = null;
  if (isDateFormat(issueAge)) {
    calculatedIssueAge = getAgeNearestProduct(
      settings.serverDateTime,
      issueAge,
    );
    if (
      !issueAge.match(
        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
      )
    ) {
      return null;
    }
  } else {
    calculatedIssueAge = issueAge;
  }

  if (calculatedIssueAge === null || isNaN(calculatedIssueAge)) {
    return null;
  }

  return calculatedIssueAge
};
