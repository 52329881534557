import React from 'react';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import _ from 'lodash';
import InputFormik from '../../../components/InputFormik';
import SelectFormik from '../../../components/SelectFormik';
import { EstimateSvg } from '../../../components/EstimateIcon/estimate-svg';
import PercentFormat from '../../../components/PercentFormat';
import CurrencyFormat from '../../../components/CurrencyFormat';
import NumericFormat from '../../../components/NumericFormat';
import Hint from '../../../components/Hint';
import CustomCheckBoxInputFormik from '../../../components/CustomCheckBoxInputFormik';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import messages from '../messages';
import CalendarSvg from '../components/CalendarSvg';
import { MAX_NUMBER, POPUP_NAMES, MAX, FASE_AMOUNT_PREMIUM } from '../../../constants';
import {
  Advanced as AdvancedTable,
  RateIndexFromThru,
  AmountFromThru,
} from '../components/Tables';
import {
  faceAmountFromThruValidator,
  rateIndexFromThruValidations,
} from '../components/Tables/validators';
import { useAppContext } from '../../App/AppContext';
import {
  getCurrencyFormatWithDecimals,
  isCurrencyFormat,
} from '../../../utils/utils';
import { FieldWithAdvancedTable } from '../utils';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';
import DownshiftFormikField from '../../../components/DownshiftFormik';
import {
  DOWNSHIFT_OPTION_CURRENCY,
  DOWNSHIFT_OPTION_A99,
} from '../../../components/DownshiftFormik/constants';

const FaceAmount = () => {
  const { values, setFieldValue } = useWizardContext();
  const { settings } = useAppContext();
  const intl = useIntl();

  const deathBenefitOptions = settings.section7702
    .filter(item => item.value === values.faceAmountAndPremium.section7702)
    .map(i => i.deathBenefitOptions)[0];

  const scheduledPremiums = settings.section7702
    .filter(item => item.value === values.faceAmountAndPremium.section7702)
    .map(i => i.scheduledPremiums)[0];

  const scheduledPremiumsPopUp = settings.section7702
    .filter(item => item.value === values.faceAmountAndPremium.section7702)
    .map(i => i.scheduledPremiumsPopUp)[0];

  const monthToBeginScheduledPremiums = settings.premiumModes
    .filter(item => item.value === values.faceAmountAndPremium.premiumMode)
    .map(i => i.monthToBeginScheduledPremiums)[0];

  const updateSection7702 = event => {
    // update scheduled premium if it is not available
    const scheduledPremium = settings.section7702
      .filter(item => item.value === event.target.value)
      .map(i => i.scheduledPremiums)[0];
    let selectedScheduledPremium = true;
    for (
      let x = 0;
      x < values.faceAmountAndPremium.scheduledPremium.length;
      x++
    ) {
      if (
        !isCurrencyFormat(
          values.faceAmountAndPremium.scheduledPremium[x]
            .scheduledPremiumAmount,
        ) &&
        !_.isNumber(
          values.faceAmountAndPremium.scheduledPremium[x]
            .scheduledPremiumAmount,
        )
      ) {
        selectedScheduledPremium = scheduledPremium.filter(
          item =>
            (item.value ===
            values.faceAmountAndPremium.scheduledPremium[x]
              .scheduledPremiumAmount) || values.faceAmountAndPremium.scheduledPremium[x]
              .scheduledPremiumAmount === '0',
        )[0];
        if (!selectedScheduledPremium) break;
      }
    }
    if (!selectedScheduledPremium) {
      setFieldValue('faceAmountAndPremium.scheduledPremium', [
        {
          scheduledPremiumAmount: '',
          increase: '',
          step: '',
          max: '',
          scheduledPremiumFrom: '1',
          scheduledPremiumThru: MAX,
        },
      ]);
    }

    // Update the Death Benefit if it is not available
    const deathBenefits = settings.section7702
      .filter(item => item.value === event.target.value)
      .map(i => i.deathBenefitOptions)[0];
    let selectedDeathBenefit = true;
    for (
      let x = 0;
      x < values.faceAmountAndPremium.deathBenefitOption.length;
      x++
    ) {
      selectedDeathBenefit = deathBenefits.filter(
        item =>
          item.value ===
          values.faceAmountAndPremium.deathBenefitOption[x].benefitOption,
      )[0];
      if (!selectedDeathBenefit) break;
    }
    if (!selectedDeathBenefit) {
      setFieldValue('faceAmountAndPremium.deathBenefitOption', [
        {
          benefitOption: '',
          benefitFrom: '1',
          benefitThru: 'Max',
        },
      ]);
    }
  };

  const updatePremiumMode = event => {
    if (event.target.value !== 'Annual') {
      const monthToBeginScheduledPremiums = settings.premiumModes
        .filter(item => item.value === event.target.value)
        .map(i => i.monthToBeginScheduledPremiums)[0];
      const selectedMonth = monthToBeginScheduledPremiums.filter(
        item =>
          parseInt(item.value) ===
          parseInt(values.faceAmountAndPremium.monthToBeginScheduledPremium),
      )[0];
      if (!selectedMonth) {
        setFieldValue(
          'faceAmountAndPremium.monthToBeginScheduledPremium',
          undefined,
        );
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="faceAmountAndPremium.totalSpecifiedAmount"
            label={intl.formatMessage(messages.totalSpecifiedAmount)}
            initialInputValue={
              values.faceAmountAndPremium.totalSpecifiedAmount.length
                ? values.faceAmountAndPremium.totalSpecifiedAmount[0]
                    .specifiedAmountValue
                : ''
            }
            defaultDownshiftCurrencyValue={100000}
            customInputType={DOWNSHIFT_OPTION_CURRENCY}
            customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
            // Popup options
            hasPopup
            popupIcon={CalendarSvg}
            popupTitle={intl.formatMessage(messages.totalSpecifiedAmount)}
            popupComponent={AdvancedTable}
            isAdvanced={FieldWithAdvancedTable.getInitialAdvancedViewValue(
              values.faceAmountAndPremium.totalSpecifiedAmount,
            )}
            tableProps={{
              amountFieldName: 'specifiedAmountValue',
              fromFieldName: 'specifiedAmountFrom',
              thruFieldName: 'specifiedAmountThru',
              items: settings.totalSpecifiedAmountsPopUp,
              maxLength: 18,
              decimalScale: 0,
            }}
            isErrorMessageDisplayed
            popupValidations={(values, { isAdvanced }) =>
              faceAmountFromThruValidator(values, {
                amountFieldName: 'specifiedAmountValue',
                fromFieldName: 'specifiedAmountFrom',
                thruFieldName: 'specifiedAmountThru',
                increaseFieldName: 'increase',
                stepFieldName: 'step',
                maxFieldName: 'max',
                amountMinValue: 100000,
                amountMaxValue: MAX_NUMBER.MAX_VALUE,
                increaseMinValue: 0,
                increaseMaxValue: MAX_NUMBER.MAX_VALUE,
                maxMinValue: 0,
                maxMaxValue: MAX_NUMBER.MAX_VALUE,
                indexName: POPUP_NAMES.TOTAL_AMOUNT,
                isAdvanced,
              })
            }
            popupOnSaveHandler={FieldWithAdvancedTable.popupOnSaveHandler}
            // Dropdown options
            items={settings.totalSpecifiedAmounts}
            maxLength={18}
            decimalScale={0}
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.totalSpecifiedAmount', [
                  {
                    specifiedAmountValue: value,
                    increase: '',
                    step: '',
                    max: '',
                    specifiedAmountFrom: '1',
                    specifiedAmountThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.totalSpecifiedAmount', [
                  {
                    specifiedAmountValue: '',
                    increase: '',
                    step: '',
                    max: '',
                    specifiedAmountFrom: '1',
                    specifiedAmountThru: 'Max',
                  },
                ]);
              }
            }}
            handleCustomValueOnPopupSave={inputValue => {
              if (_.isNumber(inputValue)) {
                inputValue = getCurrencyFormatWithDecimals(inputValue);
              }
              return `${inputValue}...`;
            }}
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;
              const fieldValue = value[0];
              if (
                fieldValue.specifiedAmountFrom !== '1' ||
                fieldValue.specifiedAmountThru !== 'Max' ||
                (typeof fieldValue.max !== 'undefined' &&
                  (fieldValue.increase !== '' ||
                    fieldValue.max !== '' ||
                    fieldValue.step !== ''))
              ) {
                return true;
              }
              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {values.faceAmountAndPremium.totalSpecifiedAmount.length === 1 && values.faceAmountAndPremium.totalSpecifiedAmount[0]
            .specifiedAmountValue === FASE_AMOUNT_PREMIUM.MinNonMec.value && (
              <CustomCheckBoxInputFormik
                name="faceAmountAndPremium.increaseInitialDBbyXChk"
                nameInput="faceAmountAndPremium.increaseInitialDBbyX"
                label={intl.formatMessage(
                  messages.increaseInitialDeathBenefit,
                )}
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormik
            name="faceAmountAndPremium.section7702"
            label={intl.formatMessage(messages.section7702)}
            options={settings.section7702}
            handleOnChange={updateSection7702}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List>
            <ListItemCheckboxFormik
              name="faceAmountAndPremium.reduceSpecifiedAmount"
              label={intl.formatMessage(messages.reduceSpecifiedAmount)}
            />
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="faceAmountAndPremium.deathBenefitOption"
            label={intl.formatMessage(messages.deathBenefitOption)}
            initialInputValue={
              values.faceAmountAndPremium.deathBenefitOption.length
                ? values.faceAmountAndPremium.deathBenefitOption[0]
                    .benefitOption
                : ''
            }
            isReadOnly
            // Popup options
            size="sm"
            popupInitialValuesHanlder={value => {
              const valueClonedObject = _.cloneDeep(value);
              if (
                Array.isArray(valueClonedObject) &&
                valueClonedObject.length === 1
              ) {
                if (valueClonedObject[0].benefitOption === 'Optimal Switch') {
                  valueClonedObject[0].benefitOption = '';
                }
              }

              return valueClonedObject;
            }}
            tableProps={{
              amountFieldName: 'benefitOption',
              fromFieldName: 'benefitFrom',
              thruFieldName: 'benefitThru',
              dropdownOptions: deathBenefitOptions.filter(
                option => option.value !== 'Optimal Switch',
              ),
              amountColumnLabel: 'Option',
              isReadOnly: true,
              inputFormat: null,
            }}
            hasPopup
            popupIcon={CalendarSvg}
            popupTitle={intl.formatMessage(messages.deathBenefitOption)}
            popupComponent={AmountFromThru}
            popupValidations={values =>
              faceAmountFromThruValidator(values, {
                amountFieldName: 'benefitOption',
                fromFieldName: 'benefitFrom',
                thruFieldName: 'benefitThru',
              })
            }
            // Dropdown options
            items={deathBenefitOptions}
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.deathBenefitOption', [
                  {
                    benefitOption: value,
                    benefitFrom: '1',
                    benefitThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.deathBenefitOption', [
                  {
                    benefitOption: '',
                    benefitFrom: '1',
                    benefitThru: 'Max',
                  },
                ]);
              }
            }}
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;
              const fieldValue = value[0];
              if (
                fieldValue.benefitFrom !== '1' ||
                fieldValue.benefitThru !== MAX
              ) {
                return true;
              }
              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List className="link">
            <ListItem className="list-container">
              <Link
                href="https://lifesales.nationwide.com/life/insurance-amount/2"
                target="_blank"
              >
                <EstimateSvg />
                {intl.formatMessage(messages.estimateClient)}
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} className="sub-title">
          <Typography variant="h2" color="textSecondary">
            {intl.formatMessage(messages.premium)}
            <Hint
              top={-2}
              isInTitle
              position="right"
              text={<FormattedHTMLMessage {...messages.premiumHint} />}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="faceAmountAndPremium.scheduledPremium"
            label={intl.formatMessage(messages.scheduledPremium)}
            initialInputValue={
              values.faceAmountAndPremium.scheduledPremium.length
                ? `${values.faceAmountAndPremium.scheduledPremium[0].scheduledPremiumAmount}`
                : ''
            }
            defaultDownshiftCurrencyValue={0}
            customInputType={DOWNSHIFT_OPTION_CURRENCY}
            customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
            // Popup options
            hasPopup
            popupIcon={CalendarSvg}
            popupTitle={intl.formatMessage(messages.scheduledPremium)}
            popupComponent={AdvancedTable}
            isAdvanced={FieldWithAdvancedTable.getInitialAdvancedViewValue(
              values.faceAmountAndPremium.scheduledPremium,
            )}
            tableProps={{
              amountColumnLabel: 'Premium',
              amountFieldName: 'scheduledPremiumAmount',
              fromFieldName: 'scheduledPremiumFrom',
              thruFieldName: 'scheduledPremiumThru',
              items: scheduledPremiumsPopUp,
              maxLength: 18,
              decimalScale: 2,
            }}
            popupValidations={(values, { isAdvanced }) =>
              faceAmountFromThruValidator(values, {
                amountFieldName: 'scheduledPremiumAmount',
                fromFieldName: 'scheduledPremiumFrom',
                thruFieldName: 'scheduledPremiumThru',
                increaseFieldName: 'increase',
                stepFieldName: 'step',
                maxFieldName: 'max',
                amountMinValue: 0,
                amountMaxValue: MAX_NUMBER.MAX_VALUE,
                increaseMinValue: 0,
                increaseMaxValue: MAX_NUMBER.MAX_VALUE,
                maxMinValue: 0,
                maxMaxValue: MAX_NUMBER.MAX_VALUE,
                indexName: POPUP_NAMES.SCHEDULED_AMOUNT,
                isAdvanced,
              })
            }
            popupOnSaveHandler={FieldWithAdvancedTable.popupOnSaveHandler}
            // Dropdown options
            items={scheduledPremiums}
            maxLength={18}
            decimalScale={2}
            isErrorMessageDisplayed
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.scheduledPremium', [
                  {
                    scheduledPremiumAmount: value,
                    increase: '',
                    step: '',
                    max: '',
                    scheduledPremiumFrom: '1',
                    scheduledPremiumThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.scheduledPremium', [
                  {
                    scheduledPremiumAmount: '',
                    increase: '',
                    step: '',
                    max: '',
                    scheduledPremiumFrom: '1',
                    scheduledPremiumThru: 'Max',
                  },
                ]);
              }
            }}
            handleCustomValueOnPopupSave={inputValue => {
              if (_.isNumber(inputValue)) {
                inputValue = getCurrencyFormatWithDecimals(inputValue);
              }
              return `${inputValue}...`;
            }}
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;

              const fieldValue = value[0];
              if (
                (!_.isNumber(fieldValue.scheduledPremiumAmount) &&
                  typeof fieldValue.max === 'undefined') ||
                fieldValue.scheduledPremiumFrom !== '1' ||
                fieldValue.scheduledPremiumThru !== 'Max' ||
                (typeof fieldValue.max !== 'undefined' &&
                  (fieldValue.increase !== '' ||
                    fieldValue.max !== '' ||
                    fieldValue.step !== ''))
              ) {
                return true;
              }
              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormik
            name="faceAmountAndPremium.premiumMode"
            label={intl.formatMessage(messages.premiumMode)}
            options={settings.premiumModes}
            handleOnChange={updatePremiumMode}
          />
        </Grid>
        {values.faceAmountAndPremium.premiumMode &&
          values.faceAmountAndPremium.premiumMode !== 'Annual' && (
            <Grid item xs={12}>
              <SelectFormik
                name="faceAmountAndPremium.monthToBeginScheduledPremium"
                label={intl.formatMessage(
                  messages.monthToBeginScheduledPremium,
                )}
                options={monthToBeginScheduledPremiums}
              />
            </Grid>
          )}
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="faceAmountAndPremium.lumpSumPremium"
            label={intl.formatMessage(messages.lumpSumPremium)}
            isErrorMessageDisplayed
            initialInputValue={
              values.faceAmountAndPremium.lumpSumPremium.length
                ? values.faceAmountAndPremium.lumpSumPremium[0].lumpSumPremiumAmount.toString().includes('$') 
                  ? values.faceAmountAndPremium.lumpSumPremium[0].lumpSumPremiumAmount.replace('$', '') 
                  : values.faceAmountAndPremium.lumpSumPremium[0].lumpSumPremiumAmount
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_CURRENCY}
            maxLength={18}
            decimalScale={2}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.lumpSumPremium)}
            popupComponent={RateIndexFromThru}
            tableProps={{
              rateColumnLabel: 'Amount',
              rateFieldName: 'lumpSumPremiumAmount',
              fromFieldName: 'lumpSumPremiumFrom',
              thruFieldName: 'lumpSumPremiumThru',
              inputFormat: CurrencyFormat,
              maxLength: 18,
            }}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'lumpSumPremiumAmount',
                fromFieldName: 'lumpSumPremiumFrom',
                thruFieldName: 'lumpSumPremiumThru',
                rateMinValue: 0,
                rateMaxValue: MAX_NUMBER.MAX_VALUE,
                indexName: intl.formatMessage(messages.lumpSumPremium),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.lumpSumPremium', [
                  {
                    lumpSumPremiumAmount: value,
                    lumpSumPremiumFrom: '1',
                    lumpSumPremiumThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.lumpSumPremium', [
                  {
                    lumpSumPremiumAmount: '',
                    lumpSumPremiumFrom: '1',
                    lumpSumPremiumThru: 'Max',
                  },
                ]);
              }
            }}
            handleCustomValueOnPopupSave={inputValue =>
              `${getCurrencyFormatWithDecimals(inputValue)}...`
            }
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;
              const fieldValue = value[0];
              if (
                fieldValue.lumpSumPremiumFrom !== '1' ||
                fieldValue.lumpSumPremiumThru !== MAX
              ) {
                return true;
              }
              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormik
            name="faceAmountAndPremium.lumpSumMonthFirstYear"
            label={intl.formatMessage(messages.lumpSumMonthFirstYear)}
            options={settings.lumpSumMonthFirstYears}
          />
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItemCheckboxFormik
              name="faceAmountAndPremium._1035Exchange"
              label={intl.formatMessage(messages.exchange1035)}
            />
            {values.faceAmountAndPremium._1035Exchange && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputFormik
                      name="faceAmountAndPremium.exchangeInfo.exchangeAmount"
                      label={intl.formatMessage(messages.amount)}
                      numberFormatCustom={CurrencyFormat}
                      maxLength={18}
                      decimalscale={2}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputFormik
                      name="faceAmountAndPremium.exchangeInfo.loanBalance1035"
                      label={intl.formatMessage(messages.loanBalance)}
                      numberFormatCustom={CurrencyFormat}
                      maxLength={18}
                      decimalscale={2}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputFormik
                      name="faceAmountAndPremium.exchangeInfo.exchangeBasis"
                      label={intl.formatMessage(messages.exchangeBasis)}
                      numberFormatCustom={CurrencyFormat}
                      maxLength={18}
                      decimalscale={2}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <List>
                      <ListItemCheckboxFormik
                        name="faceAmountAndPremium.exchangeInfo.existingContractMEC"
                        label={intl.formatMessage(messages.existingContractMEC)}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <SelectFormik
                      name="faceAmountAndPremium.exchangeInfo.month1035Received"
                      label={intl.formatMessage(messages.monthReceived)}
                      options={settings.month1035ReceivedList}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {values.faceAmountAndPremium.premiumMode === 'Annual' && (
              <ListItemCheckboxFormik
                name="faceAmountAndPremium.advancedPremiumFund"
                label={intl.formatMessage(messages.advancedPremiumFund)}
              />
            )}
            {values.faceAmountAndPremium.premiumMode === 'Annual' &&
              values.faceAmountAndPremium.advancedPremiumFund && (
                <ListItem className="list-container">
                  <Grid container className="list-container" spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <DownshiftFormikField
                        name="faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount"
                        label={intl.formatMessage(messages.amount)}
                        initialInputValue={
                          values.faceAmountAndPremium.advancedPremiumFundInfo
                            .advancedPremiumFundAmount !== ''
                            ? values.faceAmountAndPremium
                                .advancedPremiumFundInfo
                                .advancedPremiumFundAmount
                            : ''
                        }
                        customInputType={DOWNSHIFT_OPTION_CURRENCY}
                        customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
                        // Dropdown options
                        items={[{ value: 'Solve', name: 'Solve' }]}
                        maxLength={18}
                        decimalScale={2}
                        isErrorMessageDisplayed
                        handleValueOnChange={value => {
                          if (value !== '') {
                            setFieldValue(
                              'faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount',
                              value,
                            );
                          } else {
                            // default object
                            setFieldValue(
                              'faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount',
                              '',
                            );
                          }
                        }}
                        handleCustomValueOnPopupSave={inputValue => {
                          if (_.isNumber(inputValue)) {
                            inputValue = getCurrencyFormatWithDecimals(
                              inputValue,
                            );
                          }
                          return `${inputValue}...`;
                        }}
                        handleIsCustomizedValue={() => false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputFormik
                        name="faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAgeOrYear"
                        label={intl.formatMessage(messages.ageYear)}
                        numberFormatCustom={NumericFormat}
                        decimalscale={0}
                        maxLength={4}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputFormik
                        name="faceAmountAndPremium.advancedPremiumFundInfo.discountRate"
                        label={intl.formatMessage(messages.discountRate)}
                        disabled
                        numberFormatCustom={PercentFormat}
                        maxLength={6}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            <ListItemCheckboxFormik
              name="faceAmountAndPremium.avoidMEC"
              label={intl.formatMessage(messages.avoidMEC)}
            />
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} className="sub-title">
          <Typography variant="h2" color="textSecondary" display="inline">
            {intl.formatMessage(messages.targeting)}
          </Typography>
          <Typography color="textSecondary" display="inline">
            {intl.formatMessage(messages.whenSolves)}
          </Typography>
          <Hint
            position="right"
            text={<FormattedHTMLMessage {...messages.targetingHint} />}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={values.faceAmountAndPremium.targetCashValue === 'Endow' ? 4 : 6}
        >
          <DownshiftFormikField
            name="faceAmountAndPremium.targetCashValue"
            label={intl.formatMessage(messages.targetCashValue)}
            initialInputValue={values.faceAmountAndPremium.targetCashValue}
            customInputType={DOWNSHIFT_OPTION_CURRENCY}
            customInputTypeOptions={[DOWNSHIFT_OPTION_CURRENCY]}
            // Dropdown options
            items={[{ value: 'Endow', name: 'Endow' }]}
            maxLength={18}
            isErrorMessageDisplayed
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.targetCashValue', value);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.targetCashValue', '');
              }
            }}
            handleIsCustomizedValue={() => false}
          />
        </Grid>
        {values.faceAmountAndPremium.targetCashValue === 'Endow' && (
          <Grid item xs={12} md={4}>
            <InputFormik
              name="faceAmountAndPremium.targetDeathBenefitYear"
              label={intl.formatMessage(messages.targetDeathBenefitYear)}
              numberFormatCustom={NumericFormat}
              maxLength={4}
              decimalscale={0}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={values.faceAmountAndPremium.targetCashValue === 'Endow' ? 4 : 6}
        >
          <DownshiftFormikField
            name="faceAmountAndPremium.targetYear"
            label={intl.formatMessage(messages.targetYear)}
            initialInputValue={values.faceAmountAndPremium.targetYear}
            customInputType={DOWNSHIFT_OPTION_A99}
            customInputTypeOptions={[DOWNSHIFT_OPTION_A99]}
            decimalScale={0}
            // Dropdown options
            items={settings.targetYears}
            isErrorMessageDisplayed
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('faceAmountAndPremium.targetYear', value);
              } else {
                // default object
                setFieldValue('faceAmountAndPremium.targetYear', '');
              }
            }}
            handleIsCustomizedValue={() => false}
          />
        </Grid>
      </Grid>
    </>
  );
};

FaceAmount.propTypes = {};

export default FaceAmount;
