const oneYearPremiumAllocationFiftyFiftyIndex =
  '1-Yr JP Morgan Mozaic II / 1-Yr NYSE Zebra Edge';
const twoYearsPremiumAllocationFiftyFiftyIndex =
  '2-Yr JP Morgan Mozaic II / 2-Yr NYSE Zebra Edge';
const oneYearPremiumAllocationFiftyFiftyGoldman =
  '1-Yr Capped S&P 500 / 1-Yr High Par 5 Goldman Sachs New Horizons';

const realocationIndex = 'Reallocate to Matured Segment Strategy';

const getInitialInputValueFromIndexInfoNode = indexAllocationNode => {
  if (indexAllocationNode.length === 0) return '';

  const fiftyFiftySelected = fiftyFiftySelectedOption(indexAllocationNode);

  // Check if one of fifty / fifty virtual options is selected to return the display value.
  if (fiftyFiftySelected !== null) {
    return fiftyFiftySelected;
  }

  // If fifty / fifty options is not selected return the display values for the first Index with allocation.
  const indexWithAllocation = getFirstIndexWithAllocation(indexAllocationNode);

  return indexWithAllocation !== null ? indexWithAllocation.key : '';
};

const getFiftyFiftyDisplayNameIfApply = (
  listOfInndexesWithAllocation,
  oneYearPremiumAllocationFiftyFiftyIndexesKeys,
  fiftyFiftyIndexDisplayName,
) => {
  let indexWithCustomAllocation = null;
  const defaultAllocation = 50;

  // If the first index with allocation does not match with first index of fifty / fifty option app should not display fifty / fifty option
  if (
    listOfInndexesWithAllocation[0].key !==
    oneYearPremiumAllocationFiftyFiftyIndexesKeys[0]
  ) {
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  for (const index in listOfInndexesWithAllocation) {
    if (listOfInndexesWithAllocation[index].allocation !== defaultAllocation) {
      indexWithCustomAllocation = listOfInndexesWithAllocation[index];
      break;
    }
  }

  // If allocation is not the default the app should not display fifty / fifty option
  if (indexWithCustomAllocation) {
    return null;
  }

  return fiftyFiftyIndexDisplayName;
};

/**
 * Returns the selected fifty / fifty option if apply, if not return null
 */
const fiftyFiftySelectedOption = indexAllocationNode => {
  const defaultIndexFrom = '1';
  const defaultIndexThru = 'Max';
  const totalOfIndexWithAllocationForFiftyFiftyOption = 3;
  const firstRowIndex = indexAllocationNode[0];

  // If From and Thru are not the default values app should not display fifty / fifty option
  if (
    !firstRowIndex ||
    firstRowIndex.indexFrom !== defaultIndexFrom ||
    firstRowIndex.indexThru !== defaultIndexThru
  ) {
    return null;
  }

  const listOfInndexesWithAllocation = getIndexWithAllocation(
    indexAllocationNode[0].indexInfo,
  );

  if (
    listOfInndexesWithAllocation.length >
    totalOfIndexWithAllocationForFiftyFiftyOption
  ) {
    return null;
  }

  // Check if 1-Yr 50/50 option is selected.
  let fiftyFiftyOptionDisplayName = getFiftyFiftyDisplayNameIfApply(
    listOfInndexesWithAllocation,
    ['1-Yr J.P. Morgan Mozaic II', '1-Yr NYSE Zebra Edge'],
    oneYearPremiumAllocationFiftyFiftyIndex,
  );

  if (fiftyFiftyOptionDisplayName !== null) {
    return fiftyFiftyOptionDisplayName;
  }

  // Check if 2-Yr 50/50 option is selected.
  fiftyFiftyOptionDisplayName = getFiftyFiftyDisplayNameIfApply(
    listOfInndexesWithAllocation,
    ['2-Yr J.P. Morgan Mozaic II', '2-Yr NYSE Zebra Edge'],
    twoYearsPremiumAllocationFiftyFiftyIndex,
  );

  if (fiftyFiftyOptionDisplayName !== null) {
    return fiftyFiftyOptionDisplayName;
  }


  fiftyFiftyOptionDisplayName = getFiftyFiftyDisplayNameIfApply(
    listOfInndexesWithAllocation,
    ['1-Yr Capped S&P 500', '1-Yr High Par 5 Goldman Sachs New Horizons'],
    oneYearPremiumAllocationFiftyFiftyGoldman,
  );

  if (fiftyFiftyOptionDisplayName !== null) {
    return fiftyFiftyOptionDisplayName;
  }

  return null;
};

const getIndexWithAllocation = indexInfo =>
  indexInfo.filter(indexItem => indexItem.allocation > 0);

const getMaturedSegmentAllocationInitialInputValue = indexAllocationNode => {
  const indexWithAllocation = getFirstIndexWithAllocation(indexAllocationNode);

  return indexWithAllocation !== null ? indexWithAllocation.key : '';
};

const getFirstIndexWithAllocation = indexAllocationNode => {
  const invalidData = indexAllocationNode.find(index => index.total !== 100);
  if (invalidData) return null;

  let indexWithAllocation = null;
  let total = 0;
  const allocation = 0;
  if (indexAllocationNode.length) {
    const {indexInfo} = indexAllocationNode[0];
    // eslint-disable-next-line no-unused-vars
    for (const index in indexInfo) {
      if (indexInfo[index].allocation > 0) {
        total = total + indexInfo[index].allocation;
      }
      if (indexInfo[index].allocation > allocation) {
        if (indexWithAllocation === null) {
          indexWithAllocation = indexInfo[index];
        }
      }
    }
  }

  return total === 100 ? indexWithAllocation : null;
};

const PremiumAndMaturedAllocations = {
  oneYearPremiumAllocationFiftyFiftyIndex,
  twoYearsPremiumAllocationFiftyFiftyIndex,
  oneYearPremiumAllocationFiftyFiftyGoldman,
  realocationIndex,
  getInitialInputValueFromIndexInfoNode: getInitialInputValueFromIndexInfoNode,
  getMaturedSegmentAllocationInitialInputValue: getMaturedSegmentAllocationInitialInputValue,
  fiftyFiftySelectedOption: fiftyFiftySelectedOption,
  getIndexWithAllocation: getIndexWithAllocation,
  getFirstIndexWithAllocation: getFirstIndexWithAllocation,
};

export default PremiumAndMaturedAllocations;
