import _ from 'lodash';
import { ERRORS_MESSAGES, MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT, MIN_NUMBER,TERM_LIFE_QUOTE_FACE_AMOUNT } from '../../../constants';
import { minValue, maxValue } from '../../../utils/validate';
/**
 * Validate Output Design Form
 * @param {object} values
 * @return {object} Return an object with the list of errors on Output Design form
 */
export const OutputDesignValidations = values => {
  let errors = {};

  const numbers = values.illustrationIndicators.yearsForMonthlyOutput
    .split('-')
    .map(Number);

  if (values.illustrationIndicators.monthlyCostSummary) {
    if (!values.illustrationIndicators.whichIllustration) {
      errors.whichIllustration = true;
    }

    if (!values.illustrationIndicators.yearsForMonthlyOutput) {
      errors.yearsForMonthlyOutput = true;
    }

    if (numbers[0] > numbers[1]) {
      errors.yearsForMonthlyOutput = true;
    }

    if (numbers[0] === 0 || numbers[1] === 0) {
      errors.yearsForMonthlyOutput = true;
    }
  }

  if (_.isEmpty(values.presentedBy)) {
    return { presentedBy: true, illustrationIndicators: { ...errors } };
  } else {
    if (values.presentedBy.length > 100) {
      return { presentedBy: 'Maximum 100 characters', illustrationIndicators: { ...errors } };
    }
    if (!/\s/.test(values.presentedBy)) {
      return { presentedBy: 'First name and last name required.', illustrationIndicators: { ...errors } };
    } else {
      const splitValue = values.presentedBy.split(' ');
      if (!splitValue[1]) {
        return { presentedBy: 'First name and last name required.', illustrationIndicators: { ...errors } };
      }
    }
  }

  if (values.illustrationIndicators.deathBenefitAmount || +values.illustrationIndicators.deathBenefitAmount === 0) {
    const min = minValue(100000)(+values.illustrationIndicators.deathBenefitAmount);
    const max = maxValue(MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT.MAX_VALUE)(
      +values.illustrationIndicators.deathBenefitAmount,
    );
    if (min) {
      errors.deathBenefitAmount = `${ERRORS_MESSAGES.MIN_VALUE} ${MIN_NUMBER.MIN_STRING}`;
    }
    if (max) {
      errors.deathBenefitAmount = `${ERRORS_MESSAGES.MAX_VALUE}  ${MAX_NUMBER_TOTAL_SPECIFIED_AMOUNT.MAX_STRING}`;
    }
  }

  if(values.illustrationIndicators.basicTermLifeInsurance?.includeTermLifeQuote){
    const basicTermLifeInsuranceErrors = {};
    if(!values.illustrationIndicators.basicTermLifeInsurance.faceAmount && values.illustrationIndicators.basicTermLifeInsurance.faceAmount !== 0){
      basicTermLifeInsuranceErrors.faceAmount = true;
      errors.basicTermLifeInsurance =  {...basicTermLifeInsuranceErrors};
    }
    if(!isNaN(values.illustrationIndicators.basicTermLifeInsurance.faceAmount)){
      const min = minValue(TERM_LIFE_QUOTE_FACE_AMOUNT.MIN_VALUE)( +values.illustrationIndicators.basicTermLifeInsurance.faceAmount);
      const max = maxValue(TERM_LIFE_QUOTE_FACE_AMOUNT.MAX_VALUE)( +values.illustrationIndicators.basicTermLifeInsurance.faceAmount);
      if (max) {
        basicTermLifeInsuranceErrors.faceAmount = `${ERRORS_MESSAGES.MAX_VALUE} ${TERM_LIFE_QUOTE_FACE_AMOUNT.MAX_STRING}`;
        errors.basicTermLifeInsurance =  {...basicTermLifeInsuranceErrors};
      }
      if (min) {
        basicTermLifeInsuranceErrors.faceAmount = `${ERRORS_MESSAGES.MIN_VALUE} ${TERM_LIFE_QUOTE_FACE_AMOUNT.MIN_STRING}`;
        errors.basicTermLifeInsurance =  {...basicTermLifeInsuranceErrors};
      }
    }
    if(!values.illustrationIndicators.basicTermLifeInsurance.premiumMode){
      basicTermLifeInsuranceErrors.premiumMode = true;
      errors.basicTermLifeInsurance =  {...basicTermLifeInsuranceErrors};
    }
    if(!values.illustrationIndicators.basicTermLifeInsurance.productTerm){
      basicTermLifeInsuranceErrors.productTerm = true;
      errors.basicTermLifeInsurance =  {...basicTermLifeInsuranceErrors};
    }
  }
  if (_.isEmpty(errors)) {
    return {};
  }

  return { illustrationIndicators: { ...errors } };
};
