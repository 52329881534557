import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ClickAwayListener } from '@material-ui/core';

import {
  splitDecimal,
  limitToScale,
  getNumbersAndDecimalsOnly,
  applyThousandSeparator,
} from '../../utils/utils';

const CurrencyFormat = ({
  inputRef,
  onChange,
  decimalscale,
  fixedDecimalScale,
  returntypevalue,
  thousandSeparator,
  prefix,
  suffix,
  decimalSeparator,
  ...rest
}) => {
  
  const {value} = {...rest};
  const [showPrefix, setShowPrefix] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  
  const handleOnChange = values => {
    let value = null;
    switch (returntypevalue) {
      case 'formattedValue':
        value =
          values.formattedValue === undefined ? '' : values.formattedValue;
        break;
      case 'floatValue':
        value = values.floatValue === undefined ? '' : values.floatValue;
        break;
      default:
        value = values.value === undefined ? '' : values.value;
        break;
    }
    setCurrentValue(value);
    return onChange({
      currentTarget: {
        value: value,
      },
      target: {
        value: value,
      },
    });
  };

  const handleOnFocus = () => (
    onChange({
      currentTarget: {
        value: currentValue === '0' || currentValue === 0 ? '': currentValue,
      },
      target: {
        value: currentValue === '0' || currentValue === 0 ? '': currentValue,
      },
    })
  );
  
  const handleFormat = formattedValue => {
    //if decimal scale is zero remove decimal and number after decimalSeparator
    if (decimalSeparator && decimalscale === 0) {
      formattedValue = formattedValue.split(decimalSeparator)[0];
    }
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
      formattedValue,
      false,
    );

    const hasDecimalSeparator =
      formattedValue.indexOf('.') !== -1 ||
      (decimalscale && fixedDecimalScale && !!afterDecimal);

    if (decimalscale !== undefined && afterDecimal !== '')
      afterDecimal = limitToScale(
        afterDecimal,
        decimalscale,
        fixedDecimalScale,
      );
    if (thousandSeparator) {
      beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator);
    }
    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal = afterDecimal + suffix; //restore negation sign
    if (addNegation) beforeDecimal = '-' + beforeDecimal;
    return (
      beforeDecimal +
      ((hasDecimalSeparator && decimalSeparator) || '') +
      afterDecimal
    );
  };
  const handleRemoveFormatting = val => getNumbersAndDecimalsOnly(val);
  return (
    <ClickAwayListener onClickAway={() => setShowPrefix(false)}>
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={values => {
          handleOnChange(values);
        }}
        thousandSeparator
        inputMode="decimal"
        allowNegative={false}
        decimalScale={decimalscale}
        prefix={'$'}
        format={showPrefix ? null : handleFormat}
        removeFormatting={handleRemoveFormatting}
        allowEmptyFormatting={showPrefix}
        onClick={() => setShowPrefix(true)}
        onFocus={() => {
          rest.onFocus();
          handleOnFocus();
          setShowPrefix(true);
        }}
        onBlurCapture={() => setShowPrefix(false)}
      />
    </ClickAwayListener>
  );
};

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalscale: PropTypes.number,
  returntypevalue: PropTypes.string.isRequired,
  fixedDecimalScale: PropTypes.any,
  thousandSeparator: PropTypes.any,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  decimalSeparator: PropTypes.any
};

CurrencyFormat.defaultProps = {
  decimalscale: 0,
  fixedDecimalScale: true,
  returntypevalue: 'floatValue',
  thousandSeparator: ',',
  decimalSeparator: '.',
  prefix: '$',
};

export default CurrencyFormat;
