import { minValue, maxValue } from '../../../../../utils/validate';
import { POPUP_NAMES, MAX } from '../../../../../constants';
import _ from 'lodash';

export const validateA999 = (fieldName, value, errors, arrayErrors, index) => {
  const fromFieldValue = Number(value.replace(/[^0-9.-]+/g, ''));
  // validate empty or zero
  if (!fromFieldValue) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  const minVal = minValue(0)(fromFieldValue);
  const maxVal = maxValue(120)(fromFieldValue);
  if (minVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  if (maxVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
};

/**
 * Validator
 * @param {object} values.valuesPopup
 * @return {object} Return an object with the list of errors
 */
export const faceAmountFromThruValidator = (values, validationConfig) => {
  const arrayErrors = [];

  if (values.valuesPopup.length > 0) {
    const faceAmounts = values.valuesPopup;
    for (let index = 0; index < faceAmounts.length; index++) {
      const faceAmount = faceAmounts[index];
      const errors = {};
      if (!faceAmount) {
        return;
      }
      if (
        !faceAmount[validationConfig.amountFieldName] &&
        faceAmount[validationConfig.amountFieldName] !== 0
      ) {
        errors[validationConfig.amountFieldName] = true;
        arrayErrors[index] = errors;
      }
      if (_.isNumber(faceAmount[validationConfig.amountFieldName])) {
        const min = minValue(validationConfig.amountMinValue)(
          faceAmount[validationConfig.amountFieldName],
        );
        const max = maxValue(validationConfig.amountMaxValue)(
          faceAmount[validationConfig.amountFieldName],
        );
        if (min) {
          errors[validationConfig.amountFieldName] = true;
          arrayErrors[index] = errors;
        }
        if (max) {
          errors[validationConfig.amountFieldName] = true;
          arrayErrors[index] = errors;
        }
      }

      if (validationConfig.isAdvanced) {
        if (
          !faceAmount[validationConfig.increaseFieldName] &&
          faceAmount[validationConfig.increaseFieldName] !== 0
        ) {
          errors[validationConfig.increaseFieldName] = true;
          arrayErrors[index] = errors;
        } else {
          const min = minValue(Number(validationConfig.increaseMinValue))(
            faceAmount[validationConfig.increaseFieldName],
          );
          const max = maxValue(Number(validationConfig.increaseMaxValue))(
            faceAmount[validationConfig.increaseFieldName],
          );
          if (min) {
            errors[validationConfig.increaseFieldName] = true;
            arrayErrors[index] = errors;
          }
          if (max) {
            errors[validationConfig.increaseFieldName] = true;
            arrayErrors[index] = errors;
          }
        }

        if (
          !faceAmount[validationConfig.maxFieldName] &&
          faceAmount[validationConfig.maxFieldName] !== 0
        ) {
          errors[validationConfig.maxFieldName] = true;
          arrayErrors[index] = errors;
        } else {
          const min = minValue(Number(validationConfig.maxMinValue))(
            faceAmount[validationConfig.maxFieldName],
          );
          const max = maxValue(Number(validationConfig.maxMaxValue))(
            faceAmount[validationConfig.maxFieldName],
          );
          if (min) {
            errors[validationConfig.maxFieldName] = true;
            arrayErrors[index] = errors;
          }
          if (max) {
            errors[validationConfig.maxFieldName] = true;
            arrayErrors[index] = errors;
          }
        }
      }

      if (!faceAmount[validationConfig.fromFieldName]) {
        errors[validationConfig.fromFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        if (
          validationConfig.indexName === POPUP_NAMES.TOTAL_AMOUNT ||
          validationConfig.indexName === POPUP_NAMES.SCHEDULED_AMOUNT
        ) {
          if (
            faceAmount[validationConfig.amountFieldName] === 'Corridor' ||
            faceAmount[validationConfig.amountFieldName] === 'Final'
          ) {
            if (faceAmount[validationConfig.fromFieldName] < 2) {
              errors[validationConfig.fromFieldName] = true;
              arrayErrors[index] = errors;
            }
          }
        }
        validateA999(
          validationConfig.fromFieldName,
          faceAmount[validationConfig.fromFieldName],
          errors,
          arrayErrors,
          index,
        );
      }
      if (!faceAmount[validationConfig.thruFieldName]) {
        errors[validationConfig.thruFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        if (faceAmount[validationConfig.thruFieldName] !== MAX) {
          validateA999(
            validationConfig.thruFieldName,
            faceAmount[validationConfig.thruFieldName],
            errors,
            arrayErrors,
            index,
          );
        }
      }
    }
  }
  if (arrayErrors.length > 0) {
    return { valuesPopup: arrayErrors };
  }
  return {};
};
