import React, { useEffect } from 'react';
import _ from 'lodash';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
import { useResize } from 'react-browser-hooks';
import { getIn } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SelectFormik from '../../../components/SelectFormik';

import Hint from '../../../components/Hint';
import messages from '../messages';
import { RateIndexFromThru, AmountFromThru } from '../components/Tables';
import {
  rateIndexFromThruValidations,
  faceAmountFromThruValidator,
} from '../components/Tables/validators';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import { useAppContext } from '../../App/AppContext';
import {
  CORE_INTEREST,
  PLUS_INDEXED_INTEREST,
  MAX_NUMBER,
  MAX,
  CURRENT,
  CREDITING_RATE,
} from '../../../constants';
import PremiumAllocationDropDown from '../components/steps/InterestRate/PremiumAllocationDropDown';
import MaturedSegmentAllocationDropDown from '../components/steps/InterestRate/MaturedSegmentAllocationDropDown';
import { FromThruIndexes } from '../utils';
import CalendarSvg from '../components/CalendarSvg';
import DistributionAmount from '../components/Tables/DistributionAmount';
import { distributionAmountValidator } from '../components/Tables/validators/distributionAmountValidator';
import { FieldWithAdvancedTable } from '../utils';
import { getPercentFormat } from '../../../utils/utils';
import DownshiftFormikField from '../../../components/DownshiftFormik';
import {
  DOWNSHIFT_OPTION_PERCENTAGE,
  DOWNSHIFT_OPTION_CURRENCY,
} from '../../../components/DownshiftFormik/constants';
import { List, ListItem } from '@material-ui/core';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';

const InterestRate = () => {
  const { values, setFieldValue, errors } = useWizardContext();
  const intl = useIntl();
  const { settings } = useAppContext();
  const hasErrors = fieldName => (getIn(errors, fieldName) ? 'hasErrors' : '');
  const { width } = useResize();
  const smallTooltip = width > 574 && width < 800;
  const isWithdrawalCapEnabled = _.some(
    values.interestRateAndIncome.incomeLoanType,
    incomeLoanType => incomeLoanType.loanType === 'Declared Rate',
  );
  useEffect(() => {
    if (
      !isWithdrawalCapEnabled &&
      values.interestRateAndIncome.incomeLoanType.length > 0 &&
      values.interestRateAndIncome.incomeLoanType[0].loanType ===
        'Alternative' &&
      values.interestRateAndIncome.incomeLoanType[0].loanFrom === '1' &&
      values.interestRateAndIncome.incomeLoanType[0].loanThru === 'Max' &&
      values.interestRateAndIncome.incomeWithdrawalCap !== 'Basis'
    ) {
      setFieldValue('interestRateAndIncome.incomeWithdrawalCap', 'Basis');
    }
  }, [
    isWithdrawalCapEnabled,
    setFieldValue,
    values.interestRateAndIncome.incomeLoanType,
    values.interestRateAndIncome.incomeWithdrawalCap,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PremiumAllocationDropDown />
        </Grid>

        <Grid item xs={12} md={6}>
          <MaturedSegmentAllocationDropDown />
        </Grid>

        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.fixedCreditingRate"
            label={intl.formatMessage(messages.fixedCreditingRate)}
            initialInputValue={
              values.interestRateAndIncome.fixedCreditingRate.length
                ? values.interestRateAndIncome.fixedCreditingRate[0].creditRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            customInputTypeOptions={[DOWNSHIFT_OPTION_PERCENTAGE]}
            maxLength={6}
            decimalscale={2}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.fixedCreditingRate)}
            popupComponent={AmountFromThru}
            tableProps={{
              amountFieldName: 'creditRate',
              fromFieldName: 'creditRateFrom',
              thruFieldName: 'creditRateThru',
              amountColumnLabel: 'Rate',
              dropdownOptions: settings.fixedCreditingRates,
              inputFormat: DOWNSHIFT_OPTION_PERCENTAGE,
              customInputTypeOptions: [DOWNSHIFT_OPTION_PERCENTAGE],
              items: settings.fixedCreditingRates
            }}
            popupValidations={values =>
              faceAmountFromThruValidator(values, {
                amountFieldName: 'creditRate',
                fromFieldName: 'creditRateFrom',
                thruFieldName: 'creditRateThru',
                amountMinValue: CREDITING_RATE.MIN_VALUE,
                amountMaxValue: CREDITING_RATE.MAX_VALUE,
                indexName: intl.formatMessage(messages.fixedCreditingRate),
              })
            }
            popupOnSaveHandler={FieldWithAdvancedTable.popupOnSaveHandler}
            // Dropdown options
            items={settings.fixedCreditingRates}
            isErrorMessageDisplayed
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue('interestRateAndIncome.fixedCreditingRate', [
                  {
                    creditRate: value,
                    creditRateFrom: '1',
                    creditRateThru: 'Max',
                  },
                ]);
              } else {
                // default object
                setFieldValue('interestRateAndIncome.fixedCreditingRate', [
                  {
                    creditRate: '',
                    creditRateFrom: '1',
                    creditRateThru: 'Max',
                  },
                ]);
              }
            }}
            handleCustomValueOnPopupSave={inputValue => {
              if (inputValue === CURRENT) return `${inputValue}...`;
              return inputValue ? `${getPercentFormat(inputValue)}...` : '';
            }}
            handleIsCustomizedValue={value => {
              if (!value.length) return false;
              if (value.length > 1) return true;
              const fieldValue = value[0];
              if (
                fieldValue.creditRateFrom !== '1' ||
                fieldValue.creditRateThru !== MAX
              ) {
                return true;
              }
              return false;
            }}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.fixedCreditingRate',
            )}`}
          >
            {intl.formatMessage(messages.fixedCreditRateNote, {
              minValue: CREDITING_RATE.MIN_VALUE,
              maxValue: CREDITING_RATE.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} className="sub-title">
          <Typography variant="h2" color="textSecondary" display="inline">
            {intl.formatMessage(
              messages.coreNewHeightsIndexedInterestStrategies,
            )}
          </Typography>
          <Hint
            position={smallTooltip ? 'top' : 'right'}
            text={
              <FormattedHTMLMessage
                {...messages.indexedInterestStrategiesHint}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrCappedSP500"
            label={intl.formatMessage(messages.oneYrCappedSP500)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._1YrCappedSP500.length
                ? values.interestRateAndIncome
                  .coreNewHeightsIndexedInterestStrategies._1YrCappedSP500[0]
                  .indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.oneYrCappedSP500)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_1_SP500,
                rateMaxValue: CORE_INTEREST.MAX_1_SP500,
                indexName: intl.formatMessage(messages.oneYrCappedSP500),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrCappedSP500',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrCappedSP500',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrCappedSP500',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_1_SP500,
              maxValue: CORE_INTEREST.MAX_1_SP500.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500"
            label={intl.formatMessage(messages.twoYrSP500)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._2YrSP500.length
                ? values.interestRateAndIncome
                    .coreNewHeightsIndexedInterestStrategies._2YrSP500[0]
                    .indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.twoYrSP500)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_2_SP500,
                rateMaxValue: CORE_INTEREST.MAX_2_SP500,
                indexName: intl.formatMessage(messages.twoYrSP500),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_2_SP500,
              maxValue: CORE_INTEREST.MAX_2_SP500.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons"
            label={intl.formatMessage(messages.oneHighYrGoldman)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons.length
                ? values.interestRateAndIncome
                  .coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons[0]
                  .indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.oneHighYrGoldman)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_GOLDMAN,
                rateMaxValue: CORE_INTEREST.MAX_GOLDMAN,
                indexName: intl.formatMessage(messages.oneHighYrGoldman),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_GOLDMAN,
              maxValue: CORE_INTEREST.MAX_GOLDMAN.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons"
            label={intl.formatMessage(messages.oneYrGoldman)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons.length
                ? values.interestRateAndIncome
                  .coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons[0]
                  .indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.oneYrGoldman)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_GOLDMAN,
                rateMaxValue: CORE_INTEREST.MAX_GOLDMAN,
                indexName: intl.formatMessage(messages.oneYrGoldman),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_GOLDMAN,
              maxValue: CORE_INTEREST.MAX_GOLDMAN.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons"
            label={intl.formatMessage(messages.twoYrGoldman)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons.length
                ? values.interestRateAndIncome
                  .coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons[0]
                  .indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.twoYrGoldman)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_GOLDMAN,
                rateMaxValue: CORE_INTEREST.MAX_GOLDMAN,
                indexName: intl.formatMessage(messages.twoYrGoldman),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_GOLDMAN,
              maxValue: CORE_INTEREST.MAX_GOLDMAN.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII"
            label={intl.formatMessage(messages.oneYrJPMorganMozaicII)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII
                .length
                ? values.interestRateAndIncome
                    .coreNewHeightsIndexedInterestStrategies
                    ._1YrJPMorganMozaicII[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.oneYrJPMorganMozaicII)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_VALUE,
                rateMaxValue: CORE_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(messages.oneYrJPMorganMozaicII),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_VALUE,
              maxValue: CORE_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII"
            label={intl.formatMessage(messages.twoYrJPMorganMozaicII)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII
                .length
                ? values.interestRateAndIncome
                    .coreNewHeightsIndexedInterestStrategies
                    ._2YrJPMorganMozaicII[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.twoYrJPMorganMozaicII)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_VALUE,
                rateMaxValue: CORE_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(messages.twoYrJPMorganMozaicII),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_VALUE,
              maxValue: CORE_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge"
            label={intl.formatMessage(messages.oneYrNYSEZebraEdge)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge
                .length
                ? values.interestRateAndIncome
                    .coreNewHeightsIndexedInterestStrategies
                    ._1YrNYSEZebraEdge[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.oneYrNYSEZebraEdge)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_VALUE,
                rateMaxValue: CORE_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(messages.oneYrNYSEZebraEdge),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_VALUE,
              maxValue: CORE_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge"
            label={intl.formatMessage(messages.twoYrNYSEZebraEdge)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome
                .coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge
                .length
                ? values.interestRateAndIncome
                    .coreNewHeightsIndexedInterestStrategies
                    ._2YrNYSEZebraEdge[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(messages.twoYrNYSEZebraEdge)}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: CORE_INTEREST.MIN_VALUE,
                rateMaxValue: CORE_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(messages.twoYrNYSEZebraEdge),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: CORE_INTEREST.MIN_VALUE,
              maxValue: CORE_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className="sub-title">
          <Typography variant="h2" color="textSecondary" display="inline">
            {intl.formatMessage(messages.choicePlusIndexedStrategies)}
          </Typography>
          <Hint
            position={smallTooltip ? 'top' : 'right'}
            text={
              <FormattedHTMLMessage
                {...messages.indexedInterestStrategiesHint}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusJPMorganMozaicII"
            label={intl.formatMessage(messages.oneYrChoicePlusJPMorganMozaicII)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome.choicePlusIndexedStrategies
                ._1YrChoicePlusJPMorganMozaicII.length
                ? values.interestRateAndIncome.choicePlusIndexedStrategies
                    ._1YrChoicePlusJPMorganMozaicII[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(
              messages.oneYrChoicePlusJPMorganMozaicII,
            )}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: PLUS_INDEXED_INTEREST.MIN_VALUE,
                rateMaxValue: PLUS_INDEXED_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(
                  messages.oneYrChoicePlusJPMorganMozaicII,
                ),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusJPMorganMozaicII',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusJPMorganMozaicII',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: PLUS_INDEXED_INTEREST.MIN_VALUE,
              maxValue: PLUS_INDEXED_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DownshiftFormikField
            name="interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusNYSEZebraEdge"
            label={intl.formatMessage(messages.oneYrChoicePlusNYSEZebraEdge)}
            isErrorMessageDisplayed
            initialInputValue={
              values.interestRateAndIncome.choicePlusIndexedStrategies
                ._1YrChoicePlusNYSEZebraEdge.length
                ? values.interestRateAndIncome.choicePlusIndexedStrategies
                    ._1YrChoicePlusNYSEZebraEdge[0].indexStrategyRate
                : ''
            }
            customInputType={DOWNSHIFT_OPTION_PERCENTAGE}
            maxLength={6}
            // Popup options
            size="sm"
            hasPopup
            popupTitle={intl.formatMessage(
              messages.oneYrChoicePlusNYSEZebraEdge,
            )}
            popupComponent={RateIndexFromThru}
            popupValidations={values =>
              rateIndexFromThruValidations(values, {
                rateFieldName: 'indexStrategyRate',
                fromFieldName: 'indexStrategyFrom',
                thruFieldName: 'indexStrategyThru',
                rateMinValue: PLUS_INDEXED_INTEREST.MIN_VALUE,
                rateMaxValue: PLUS_INDEXED_INTEREST.MAX_VALUE,
                indexName: intl.formatMessage(
                  messages.oneYrChoicePlusNYSEZebraEdge,
                ),
              })
            }
            // Dropdown options
            handleValueOnChange={value => {
              if (value !== '') {
                setFieldValue(
                  'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: value,
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              } else {
                // default object
                setFieldValue(
                  'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusNYSEZebraEdge',
                  [
                    {
                      indexStrategyRate: '',
                      indexStrategyFrom: '1',
                      indexStrategyThru: 'Max',
                    },
                  ],
                );
              }
            }}
            handleCustomValueOnPopupSave={
              FromThruIndexes.handleCustomValueOnPopupSave
            }
            handleIsCustomizedValue={FromThruIndexes.handleIsCustomizedValue}
          />
          <Typography
            color="secondary"
            className={`input-note ${hasErrors(
              'interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusNYSEZebraEdge',
            )}`}
          >
            {intl.formatMessage(messages.indexedNote, {
              minValue: PLUS_INDEXED_INTEREST.MIN_VALUE,
              maxValue: PLUS_INDEXED_INTEREST.MAX_VALUE.toFixed(2),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <List>
            <ListItemCheckboxFormik
              name="interestRateAndIncome.hasIncome"
              label={intl.formatMessage(messages.income)}
            >
              <Hint
                top={6}
                position="right"
                text={<FormattedHTMLMessage {...messages.incomeHint} />}
              />
            </ListItemCheckboxFormik>
            {values.interestRateAndIncome.hasIncome && (
              <ListItem className="list-container padding-space">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DownshiftFormikField
                      name="interestRateAndIncome.incomeDistributionAmounts"
                      label={intl.formatMessage(
                        messages.incomeDistributionAmounts,
                      )}
                      isReadOnly
                      initialInputValue={
                        values.interestRateAndIncome.incomeDistributionAmounts
                          .length
                          ? values.interestRateAndIncome
                              .incomeDistributionAmounts[0].distributionAmount
                          : ''
                      }
                      customInputType={DOWNSHIFT_OPTION_CURRENCY}
                      // Popup options
                      size="xl"
                      hasPopup
                      popupIcon={CalendarSvg}
                      popupTitle={intl.formatMessage(
                        messages.incomeDistributionAmounts,
                      )}
                      popupComponent={DistributionAmount}
                      tableProps={{
                        amountFieldName: 'distributionAmount',
                        actionFieldName: 'amountAction',
                        fromFieldName: 'amountFrom',
                        thruFieldName: 'amountThru',
                        maxLength: 18,
                      }}
                      isErrorMessageDisplayed
                      isAdvanced={FieldWithAdvancedTable.getInitialAdvancedViewValue(
                        values.interestRateAndIncome.incomeDistributionAmounts,
                      )}
                      popupInitialValuesHanlder={value => {
                        const valueClonedObject = _.cloneDeep(value);
                        if (
                          Array.isArray(valueClonedObject) &&
                          valueClonedObject.length === 1
                        ) {
                          if (
                            valueClonedObject[0].distributionAmount === '$0'
                          ) {
                            valueClonedObject[0].distributionAmount = 'Solve';
                          }
                        }
                        return valueClonedObject;
                      }}
                      popupValidations={(values, { isAdvanced }) =>
                        distributionAmountValidator(values, {
                          amountFieldName: 'distributionAmount',
                          actionFieldName: 'amountAction',
                          fromFieldName: 'amountFrom',
                          thruFieldName: 'amountThru',
                          increaseFieldName: 'increase',
                          stepFieldName: 'step',
                          maxFieldName: 'max',
                          amountMinValue: 0,
                          amountMaxValue: MAX_NUMBER.MAX_VALUE,
                          increaseMinValue: 0,
                          increaseMaxValue: MAX_NUMBER.MAX_VALUE,
                          maxMinValue: 0,
                          maxMaxValue: MAX_NUMBER.MAX_VALUE,
                          isAdvanced,
                        })
                      }
                      popupOnSaveHandler={
                        FieldWithAdvancedTable.popupOnSaveHandler
                      }
                      // Dropdown options
                      maxLength={14}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          // Not mapping because is readonly
                        } else {
                          // default object
                          setFieldValue(
                            'interestRateAndIncome.incomeDistributionAmounts',
                            [
                              {
                                distributionAmount: '$0',
                                amountAction: 'Borrow',
                                increase: '',
                                step: '',
                                max: '',
                                amountFrom: 'A66',
                                amountThru: 'A80',
                              },
                            ],
                          );
                        }
                      }}
                      handleCustomValueOnPopupSave={inputValue =>
                        inputValue ? `${inputValue}...` : ''
                      }
                      handleIsCustomizedValue={value => {
                        if (!value.length) return false;
                        if (value.length > 1) return true;
                        const fieldValue = value[0];
                        if (
                          fieldValue.distributionAmount !== '$0' ||
                          fieldValue.amountAction !== 'Borrow' ||
                          fieldValue.amountFrom !== 'A66' ||
                          fieldValue.amountThru !== 'A80' ||
                          (typeof fieldValue.max !== 'undefined' &&
                            (fieldValue.increase !== '' ||
                              fieldValue.max !== '' ||
                              fieldValue.step !== ''))
                        ) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DownshiftFormikField
                      name="interestRateAndIncome.incomeLoanType"
                      label={intl.formatMessage(messages.incomeLoanType)}
                      initialInputValue={
                        values.interestRateAndIncome.incomeLoanType.length
                          ? values.interestRateAndIncome.incomeLoanType[0]
                              .loanType
                          : ''
                      }
                      isReadOnly
                      // Popup options
                      size="sm"
                      popupInitialValuesHanlder={value => {
                        return value;
                      }}
                      tableProps={{
                        amountFieldName: 'loanType',
                        fromFieldName: 'loanFrom',
                        thruFieldName: 'loanThru',
                        dropdownOptions: settings.incomeLoanTypes,
                        amountColumnLabel: 'Option',
                        isReadOnly: true,
                        inputFormat: null,
                      }}
                      hasPopup
                      popupIcon={CalendarSvg}
                      popupTitle={intl.formatMessage(messages.incomeLoanType)}
                      popupComponent={AmountFromThru}
                      popupValidations={values =>
                        faceAmountFromThruValidator(values, {
                          amountFieldName: 'loanType',
                          fromFieldName: 'loanFrom',
                          thruFieldName: 'loanThru',
                        })
                      }
                      // Dropdown options
                      items={settings.incomeLoanTypes}
                      handleValueOnChange={value => {
                        if (value !== '') {
                          setFieldValue(
                            'interestRateAndIncome.incomeLoanType',
                            [
                              {
                                loanType: value,
                                loanFrom: '1',
                                loanThru: 'Max',
                              },
                            ],
                          );
                        } else {
                          // default object
                          setFieldValue(
                            'interestRateAndIncome.incomeLoanType',
                            [
                              {
                                loanType: '',
                                loanFrom: '1',
                                loanThru: 'Max',
                              },
                            ],
                          );
                        }
                      }}
                      handleIsCustomizedValue={value => {
                        if (!value.length) return false;
                        if (value.length > 1) return true;
                        const fieldValue = value[0];
                        if (
                          fieldValue.loanFrom !== '1' ||
                          fieldValue.loanThru !== MAX
                        ) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="interestRateAndIncome.incomeWithdrawalCap"
                      label={intl.formatMessage(messages.incomeWithdrawalCap)}
                      options={settings.incomeWithdrawalCaps}
                      disabled={!isWithdrawalCapEnabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="interestRateAndIncome.incomeLoanInterestPaymentMethod"
                      label={intl.formatMessage(
                        messages.incomeLoanInterestPaymentMethod,
                      )}
                      options={settings.incomeLoanInterestPaymentMethods}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectFormik
                      name="interestRateAndIncome.incomeDistributionMode"
                      label={intl.formatMessage(
                        messages.incomeDistributionMode,
                      )}
                      options={settings.incomeDistributionModes}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

InterestRate.propTypes = {};

export default InterestRate;
