import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

export const AgeDOBInput = props => {
  const { inputRef, onChange, ...rest } = props;
  const [mask, setMask] = useState(null);

  const handleOnChange = event => {
    let value = event.target.value;
    if (value.length && value.length >= 3) {
      setMask('99/99/9999');
    } else {
      setMask('');
    }

    if (onChange) {
      onChange({
        ...event,
        currentTarget: {
          value: value,
        },
        target: {
          value: value,
        },
      });
    }
  };

  return (
    <InputMask
      {...rest}
      inputRef={inputRef}
      mask={mask}
      maskChar={null}
      onChange={handleOnChange}
    />
  );
};

AgeDOBInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AgeDOBInput;
