import _ from 'lodash';
import {
  getFirstName,
  getMiddleName,
  getLastName,
  isDateFormat,
  getAgeNearestProduct,
  getCurrencyFormatWithDecimals,
  getPercentFormat,
  isPercentFormat,
  isCurrencyFormat,
  getNumbersAndDecimalsOnly,
  // getPercentFormat,
} from '../../../utils/utils';
import { MATURE_AGE, TEEN_AGE, FASE_AMOUNT_PREMIUM } from '../../../constants';

import { PremiumAndMaturedAllocations } from './';

const MapValuesToApi = (values, serverDateTime, initialValues) => {
  const deepClonedObject = _.cloneDeep(values);

  deepClonedObject.illustrationId = null;
  deepClonedObject.product = deepClonedObject.product || initialValues.product;

  //Insured
  let ageCalculated = null;
  if (isDateFormat(deepClonedObject.insured.issueAgeOrDateOfBirth)) {
    const age = getAgeNearestProduct(
      serverDateTime,
      deepClonedObject.insured.issueAgeOrDateOfBirth,
    );
    ageCalculated = isNaN(age) ? null : age;
  } else {
    ageCalculated = deepClonedObject.insured.issueAgeOrDateOfBirth;
  }
  const applyflatExtra =
    deepClonedObject.insured.riskClass === 'Standard Tobacco' ||
    (deepClonedObject.insured.riskClass === 'Standard Nontobacco' && ageCalculated > 14);

  if (
    deepClonedObject.insured.riskClass !== 'Standard Tobacco' &&
    deepClonedObject.insured.riskClass !== 'Standard Nontobacco'
  ) {
    delete deepClonedObject.insured.rating;
  }
  if (!deepClonedObject.insured.rating) {
    delete deepClonedObject.insured.tableRating;
  }
  if (!applyflatExtra) {
    delete deepClonedObject.insured.flatExtra;
  }

  //face amount.
  if (
    deepClonedObject.faceAmountAndPremium.increaseInitialDBbyXChk &&
    deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue ===
    FASE_AMOUNT_PREMIUM.MinNonMec.value
  ) {
    deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX = deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX
      ?.toString()
      .includes('%')
      ? deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX
      : `${deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX}%`;
  } else {
    deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX = '';
  }
  if (!deepClonedObject.faceAmountAndPremium._1035Exchange) {
    delete deepClonedObject.faceAmountAndPremium.exchangeInfo.exchangeBasis;
    deepClonedObject.faceAmountAndPremium.exchangeInfo.exchangeAmount = 0;
    delete deepClonedObject.faceAmountAndPremium.exchangeInfo.loanBalance1035;
    delete deepClonedObject.faceAmountAndPremium.exchangeInfo.existingContractMEC;
    delete deepClonedObject.faceAmountAndPremium.exchangeInfo.month1035Received;
  }

  deepClonedObject.faceAmountAndPremium.exchangeInfo['1035LoanBalance'] =
    deepClonedObject.faceAmountAndPremium.exchangeInfo.loanBalance1035;
  delete deepClonedObject.faceAmountAndPremium.exchangeInfo.loanBalance1035;

  deepClonedObject.faceAmountAndPremium['1035ExchangeInfo'] =
    deepClonedObject.faceAmountAndPremium.exchangeInfo;
  delete deepClonedObject.faceAmountAndPremium.exchangeInfo;

  deepClonedObject.faceAmountAndPremium['1035Exchange'] =
    deepClonedObject.faceAmountAndPremium._1035Exchange;
  delete deepClonedObject.faceAmountAndPremium._1035Exchange;

  if (
    !deepClonedObject.faceAmountAndPremium.advancedPremiumFund ||
    deepClonedObject.faceAmountAndPremium.premiumMode !== 'Annual'
  ) {
    delete deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo;
  } else {
    if (
      _.isNumber(
        deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount,
      )
    ) {
      deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount = getCurrencyFormatWithDecimals(
        deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount,
      );
    }
  }
  if (deepClonedObject.faceAmountAndPremium.premiumMode === 'Annual') {
    delete deepClonedObject.faceAmountAndPremium.monthToBeginScheduledPremium;
  }
  if (deepClonedObject.faceAmountAndPremium.targetCashValue !== 'Endow') {
    delete deepClonedObject.faceAmountAndPremium.targetDeathBenefitYear;
  }
  deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount.forEach(element => {
    if (_.isNumber(element.specifiedAmountValue)) {
      element.specifiedAmountValue = getCurrencyFormatWithDecimals(element.specifiedAmountValue);
    }
  });
  deepClonedObject.faceAmountAndPremium.scheduledPremium.forEach(element => {
    if (_.isNumber(element.scheduledPremiumAmount) || element.scheduledPremiumAmount === '0') {
      element.scheduledPremiumAmount = getCurrencyFormatWithDecimals(
        element.scheduledPremiumAmount,
      );
    }
  });

  deepClonedObject.faceAmountAndPremium.lumpSumPremium.forEach(element => {
    if (_.isNumber(element.lumpSumPremiumAmount)) {
      element.lumpSumPremiumAmount = getCurrencyFormatWithDecimals(element.lumpSumPremiumAmount);
    }
  });

  //interestRateAndIncome
  deepClonedObject.interestRateAndIncome.premiumAllocation.forEach(element => {
    delete element.total;
    // TODO: Uncomment the filter a soon API fix the problems with multiple rows and different amount of indexes
    // element.indexInfo = element.indexInfo.filter(
    //   indexItem => indexItem.allocation !== '',
    // );
  });

  deepClonedObject.interestRateAndIncome.maturedSegmentAllocation.forEach(element => {
    delete element.total;
    // TODO: Uncomment the filter a soon API fix the problems with multiple rows and different amount of indexes
    // element.indexInfo = element.indexInfo.filter(
    //   indexItem => indexItem.allocation !== '',
    // );

    const reallocationIndex = element.indexInfo.filter(
      indexItem => indexItem.key === PremiumAndMaturedAllocations.realocationIndex,
    );

    if (reallocationIndex.length && reallocationIndex[0].allocation > 0) {
      // Keep just the Reallocation Index
      element.indexInfo = element.indexInfo.filter(
        indexItem => indexItem.key === PremiumAndMaturedAllocations.realocationIndex,
      );
    } else {
      // Remove the Reallocation Index
      element.indexInfo = element.indexInfo.filter(
        indexItem => indexItem.key !== PremiumAndMaturedAllocations.realocationIndex,
      );
    }
  });

  deepClonedObject.interestRateAndIncome.fixedCreditingRate.forEach(element => {
    if (_.isNumber(element.creditRate) && element.creditRate.toString().indexOf('%') < 0) {
      element.creditRate = getPercentFormat(element.creditRate);
    }
  });

  deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '1-YrChoicePlusJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '1-YrChoicePlusNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies._1YrChoicePlusNYSEZebraEdge;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrCappedS&P500'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrCappedSP500;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies['2-YrS&P500'] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '2-YrJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrNYSEZebraEdge;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '2-YrNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrNYSEZebraEdge;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrHighPar5GoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrHighPar5GoldmanSachsNewHorizons;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrGoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._1YrGoldmanSachsNewHorizons;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '2-YrGoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrGoldmanSachsNewHorizons;

  _.forEach(deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies, function(element) {
    _.forEach(element, function(prop) {
      if (prop.indexStrategyRate.toString().indexOf('%') < 0) {
        prop.indexStrategyRate = getPercentFormat(prop.indexStrategyRate);
      }
    });
  });

  _.forEach(
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies,
    function(element) {
      _.forEach(element, function(prop) {
        if (prop.indexStrategyRate.toString().indexOf('%') < 0) {
          prop.indexStrategyRate = getPercentFormat(prop.indexStrategyRate);
        }
      });
    },
  );
  _.forEach(deepClonedObject.interestRateAndIncome.incomeDistributionAmounts, element => {
    if (isPercentFormat(element.distributionAmount)) {
      const unformattedValue = getNumbersAndDecimalsOnly(element.distributionAmount);
      element.distributionAmount = getPercentFormat(unformattedValue);
    }
    if (isCurrencyFormat(element.distributionAmount)) {
      const unformattedValue = getNumbersAndDecimalsOnly(element.distributionAmount);
      element.distributionAmount = getCurrencyFormatWithDecimals(unformattedValue);
    }
  });

  // Delete old elements from cloned object
  delete deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies
    ._1YrChoicePlusJPMorganMozaicII;
  delete deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies
    ._1YrChoicePlusNYSEZebraEdge;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._1YrCappedSP500;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies._2YrSP500;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._1YrJPMorganMozaicII;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._2YrJPMorganMozaicII;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._1YrNYSEZebraEdge;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._2YrNYSEZebraEdge;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._1YrHighPar5GoldmanSachsNewHorizons;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._1YrGoldmanSachsNewHorizons;
  delete deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
    ._2YrGoldmanSachsNewHorizons;

  // Reset to defaults if has no income
  deepClonedObject.hasIncome = deepClonedObject.interestRateAndIncome.hasIncome;
  if (!deepClonedObject.interestRateAndIncome.hasIncome) {
    deepClonedObject.interestRateAndIncome.incomeDistributionAmounts =
      initialValues.interestRateAndIncome.incomeDistributionAmounts;
    deepClonedObject.interestRateAndIncome.incomeLoanType =
      initialValues.interestRateAndIncome.incomeLoanType;
    deepClonedObject.interestRateAndIncome.incomeWithdrawalCap =
      initialValues.interestRateAndIncome.incomeWithdrawalCap;
    deepClonedObject.interestRateAndIncome.incomeLoanInterestPaymentMethod =
      initialValues.interestRateAndIncome.incomeLoanInterestPaymentMethod;
    deepClonedObject.interestRateAndIncome.incomeDistributionMode =
      initialValues.interestRateAndIncome.incomeDistributionMode;
  }
  //Riders
  if (!deepClonedObject.riders.longTermCareRiderII) {
    delete deepClonedObject.riders.longTermCareRiderInfo;
  } else {
    if (_.isNumber(deepClonedObject.riders.longTermCareRiderInfo.longTermCareAmount)) {
      deepClonedObject.riders.longTermCareRiderInfo.longTermCareAmount = getCurrencyFormatWithDecimals(
        deepClonedObject.riders.longTermCareRiderInfo.longTermCareAmount,
      );
    }
    if (!deepClonedObject.riders.longTermCareRiderInfo.printLTCAnalysisRepot) {
      delete deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo;
    } else {
      deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo.hipaaPerDiemRate = getPercentFormat(
        deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo.hipaaPerDiemRate,
      );
    }
  }
  if (!deepClonedObject.riders.accidentalDeathBenefit) {
    delete deepClonedObject.riders.adbAmountAndRating;
  } else {
    if (_.isNumber(deepClonedObject.riders.adbAmountAndRating.ADBAmount)) {
      deepClonedObject.riders.adbAmountAndRating.ADBAmount = getCurrencyFormatWithDecimals(
        deepClonedObject.riders.adbAmountAndRating.ADBAmount,
      );
    }
  }
  if (!deepClonedObject.riders.waiverRider) {
    delete deepClonedObject.riders.waiverRiderInfo;
  } else {
    if (deepClonedObject.riders.waiverRiderInfo.waiverType !== 'Premium Waiver') {
      delete deepClonedObject.riders.waiverRiderInfo.premiumAmount;
    }
  }

  delete deepClonedObject.riders.conditionalReturnOfPremium;
  delete deepClonedObject.riders.returnOfPremium;

  if (!deepClonedObject.riders.surrenderValueEnhancementRider) {
    delete deepClonedObject.riders.surrenderValueEnhancementRiderInfo;
  }
  if (
    deepClonedObject.riders.accidentalDeathBenefit ||
    deepClonedObject.riders.waiverRider ||
    deepClonedObject.riders.surrenderValueEnhancementRider ||
    (ageCalculated < TEEN_AGE || ageCalculated > MATURE_AGE)
  ) {
    deepClonedObject.riders.changeOfInsuredRiderInfo = false;
  }

  //Output.
  const advisorDetails = {
    firstName: getFirstName(deepClonedObject.presentedBy),
    middleName: getMiddleName(deepClonedObject.presentedBy),
    lastName: getLastName(deepClonedObject.presentedBy),
  };
  deepClonedObject.advisorDetails = advisorDetails;
  delete deepClonedObject.presentedBy;

  if (!deepClonedObject.illustrationIndicators.monthlyCostSummary) {
    delete deepClonedObject.illustrationIndicators.whichIllustration;
    delete deepClonedObject.illustrationIndicators.yearsForMonthlyOutput;
  }
  if (!deepClonedObject.illustrationIndicators.netCostPerDollar) {
    delete deepClonedObject.illustrationIndicators.costOfMoney;
  }

  if (!deepClonedObject.illustrationIndicators.basicTermLifeInsurance?.includeTermLifeQuote) {
    delete deepClonedObject.illustrationIndicators.basicTermLifeInsurance.productTerm;
    delete deepClonedObject.illustrationIndicators.basicTermLifeInsurance.faceAmount;
    delete deepClonedObject.illustrationIndicators.basicTermLifeInsurance.premiumMode;
  }

  return deepClonedObject;
};

export default MapValuesToApi;

