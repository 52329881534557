import { maxValue, minValue } from '../../../utils/validate';
import { CORE_INTEREST, PLUS_INDEXED_INTEREST } from '../../../constants';

export const errorOnCoreStratety = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(CORE_INTEREST.MAX_VALUE)(indexStrategyRate);
        if (max) {
          return true;
        }
      }
    }
  }
}

export const errorOnCoreStratetySP500 = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(CORE_INTEREST.MAX_1_SP500)(indexStrategyRate);
        const min = minValue(CORE_INTEREST.MIN_1_SP500)(indexStrategyRate);
        if (max || min) {
          return true;
        }
      }
    }
  }
}

export const errorOnCoreStratety2SP500 = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(CORE_INTEREST.MAX_2_SP500)(indexStrategyRate);
        const min = minValue(CORE_INTEREST.MIN_2_SP500)(indexStrategyRate);
        if (max || min) {
          return true;
        }
      }
    }
  }
}

export const errorOnPlusStratety = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(PLUS_INDEXED_INTEREST.MAX_VALUE)(indexStrategyRate);
        if (max) {
          return true;
        }
      }
    }
  }
}

export const errorOnPlusStratetySP500 = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(PLUS_INDEXED_INTEREST.MAX_1_SP500)(indexStrategyRate);
        const min = minValue(PLUS_INDEXED_INTEREST.MIN_1_SP500)(indexStrategyRate);
        if (max || min) {
          return true;
        }
      }
    }
  }
}

export const errorOnCoreStratetyGoldman = strategy => {
  if (strategy.length > 0) {
    if (!strategy[0].indexStrategyRate && strategy[0].indexStrategyRate !== 0) {
      return true;
    } else {
      const indexStrategyRate = Number(strategy[0].indexStrategyRate);
      if (indexStrategyRate || indexStrategyRate === 0) {
        const max = maxValue(CORE_INTEREST.MAX_GOLDMAN)(indexStrategyRate);
        const min = minValue(CORE_INTEREST.MIN_GOLDMAN)(indexStrategyRate);
        if (max || min) {
          return true;
        }
      }
    }
  }
}


