import React from 'react';
import PDFIcon from '../../../../images/icn_donwload_pdf.svg';
import PDFDisabledIcon from '../../../../images/icn_donwload_disabled.svg';
import { downloadPdf } from '../../../../utils/utils';


const PdfDownloadLink = (props) => {
    const { pdfData, fileName, altText } = { ...props }

    const handleDownloadPdf = (e) => {
        e.preventDefault();
        downloadPdf(pdfData.previewPdf, fileName);
    };

    return pdfData !== null ? (
      <a href="#" onClick={handleDownloadPdf}>
        <img className='download-pdf' src={PDFIcon} alt={altText} />
      </a>
    ) : (
      <img className='download-pdf disabled' src={PDFDisabledIcon} alt="Icon" />
    );
};

export default PdfDownloadLink;
