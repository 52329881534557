import { isDateFormat, getAgeNearestProduct } from '../../../utils/utils';
import { ERRORS_MESSAGES } from '../../../constants';

/**
 * Validate Birthday or Age field as required
 * @param {object} values
 * @param {object} settings
 * @param {object} errors
 * @return {object} Errors object
 */
export const ageValidator = (values, settings, errors) => {
  const issueAge = values.insured.issueAgeOrDateOfBirth;
  const maxAllowedAge = settings.maxAllowedIssueAge;
  const maxAge = settings.riskClasses
    .filter(item => item.value === values.insured.riskClass)
    .map(i => i.maxAge)[0];

  const minAge = settings.riskClasses
    .filter(item => item.value === values.insured.riskClass)
    .map(i => i.minAge)[0];

  if (!issueAge && issueAge !== 0) {
    errors.issueAgeOrDateOfBirth = true;
    return errors;
  }
  let calculatedIssueAge = null;
  if (isDateFormat(issueAge)) {
    calculatedIssueAge = getAgeNearestProduct(
      settings.serverDateTime,
      issueAge,
    );
    if (
      !issueAge.match(
        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
      )
    ) {
      errors.issueAgeOrDateOfBirth = 'Enter a valid D.O.B.';
      return errors;
    }
  } else {
    calculatedIssueAge = issueAge;
  }
  if (calculatedIssueAge === null || isNaN(calculatedIssueAge)) {
    errors.issueAgeOrDateOfBirth = true;
    return errors;
  }

  if (calculatedIssueAge > maxAllowedAge) {
    errors.issueAgeOrDateOfBirth = `Maximum age is 80 for Preferred/Standard Plus and 85 for Standard`;
    return errors;
  }

  if (calculatedIssueAge < minAge) {
    errors.issueAgeOrDateOfBirth = `${ERRORS_MESSAGES.MIN_AGE} ${minAge}`;
    return errors;
  }
  if (calculatedIssueAge > maxAge) {
    errors.issueAgeOrDateOfBirth = `${ERRORS_MESSAGES.MAX_AGE} ${maxAge}`;
    return errors;
  }
  return errors;
};
