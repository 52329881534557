export const required = value => (value ? undefined : 'Required field');

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength100 = maxLength(100);
export const maxLength50 = maxLength(50);
export const maxLength35 = maxLength(35);
export const maxLength25 = maxLength(25);

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or less` : undefined;

export const minValue = min => value =>
  (value || value === 0) &&
  Number(value.toString().replace(/[^\d.-]/g, '')) < min
    ? `Must be at least ${min}`
    : undefined;

export const maxValue = max => value =>
  (value || value === 0) &&
  Number(value.toString().replace(/[^\d.]/g, '')) > max
    ? `Must be max ${max}`
    : undefined;
