import { minValue, maxValue } from '../../../../../utils/validate';
import { MAX, SOLVE } from '../../../../../constants';
import {
  isCurrencyFormat,
  isPercentFormat,
  getNumbersAndDecimalsOnly,
} from '../../../../../utils/utils';

export const validateA999 = (fieldName, value, errors, arrayErrors, index) => {
  const fromFieldValue = Number(value.replace(/[^0-9.-]+/g, ''));
  // validate empty or zero
  if (!fromFieldValue) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  const minVal = minValue(0)(fromFieldValue);
  const maxVal = maxValue(120)(fromFieldValue);
  if (minVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
  if (maxVal) {
    errors[fieldName] = true;
    arrayErrors[index] = errors;
  }
};

/**
 * Validator
 * @param {object} values.valuesPopup
 * @return {object} Return an object with the list of errors
 */
export const distributionAmountValidator = (values, validationConfig) => {
  const arrayErrors = [];

  if (values.valuesPopup.length > 0) {
    const distributionAmounts = values.valuesPopup;
    for (let index = 0; index < distributionAmounts.length; index++) {
      const distributionAmount = distributionAmounts[index];
      const errors = {};
      if (!distributionAmount) {
        return;
      }
      if (
        !distributionAmount[validationConfig.amountFieldName] &&
        distributionAmount[validationConfig.amountFieldName] !== 0
      ) {
        errors[validationConfig.amountFieldName] = true;
        arrayErrors[index] = errors;
      }
      // validate amount as currency
      const unformattedValue = getNumbersAndDecimalsOnly(
        distributionAmount[validationConfig.amountFieldName],
      );
      let min = null;
      let max = null;
      if (
        isCurrencyFormat(distributionAmount[validationConfig.amountFieldName])
      ) {
        min = minValue(validationConfig.amountMinValue)(unformattedValue);
        max = maxValue(validationConfig.amountMaxValue)(unformattedValue);
      }
      // validate amount as percentage
      if (
        isPercentFormat(distributionAmount[validationConfig.amountFieldName])
      ) {
        min = minValue(0)(unformattedValue);
        max = maxValue(100)(unformattedValue);
      }
      if (min) {
        errors[validationConfig.amountFieldName] = true;
        arrayErrors[index] = errors;
      }
      if (max) {
        errors[validationConfig.amountFieldName] = true;
        arrayErrors[index] = errors;
      }

      if (validationConfig.isAdvanced) {
        if (
          !distributionAmount[validationConfig.increaseFieldName] &&
          distributionAmount[validationConfig.increaseFieldName] !== 0
        ) {
          errors[validationConfig.increaseFieldName] = true;
          arrayErrors[index] = errors;
        } else {
          const min = minValue(Number(validationConfig.increaseMinValue))(
            distributionAmount[validationConfig.increaseFieldName],
          );
          const max = maxValue(Number(validationConfig.increaseMaxValue))(
            distributionAmount[validationConfig.increaseFieldName],
          );
          if (min) {
            errors[validationConfig.increaseFieldName] = true;
            arrayErrors[index] = errors;
          }
          if (max) {
            errors[validationConfig.increaseFieldName] = true;
            arrayErrors[index] = errors;
          }
        }

        if (
          !distributionAmount[validationConfig.maxFieldName] &&
          distributionAmount[validationConfig.maxFieldName] !== 0
        ) {
          errors[validationConfig.maxFieldName] = true;
          arrayErrors[index] = errors;
        } else {
          const min = minValue(Number(validationConfig.maxMinValue))(
            distributionAmount[validationConfig.maxFieldName],
          );
          const max = maxValue(Number(validationConfig.maxMaxValue))(
            distributionAmount[validationConfig.maxFieldName],
          );
          if (min) {
            errors[validationConfig.maxFieldName] = true;
            arrayErrors[index] = errors;
          }
          if (max) {
            errors[validationConfig.maxFieldName] = true;
            arrayErrors[index] = errors;
          }
        }
      }

      // Validate action
      if (
        !distributionAmount[validationConfig.actionFieldName] &&
        distributionAmount[validationConfig.actionFieldName] !== 0
      ) {
        errors[validationConfig.actionFieldName] = true;
        arrayErrors[index] = errors;
      }
      // Validate from field
      if (!distributionAmount[validationConfig.fromFieldName]) {
        errors[validationConfig.fromFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        validateA999(
          validationConfig.fromFieldName,
          distributionAmount[validationConfig.fromFieldName],
          errors,
          arrayErrors,
          index,
        );
      }
      // Validate Thru field
      if (!distributionAmount[validationConfig.thruFieldName]) {
        errors[validationConfig.thruFieldName] = true;
        arrayErrors[index] = errors;
      } else {
        if (
          distributionAmount[validationConfig.thruFieldName] !== MAX &&
          distributionAmount[validationConfig.thruFieldName] !== SOLVE
        ) {
          validateA999(
            validationConfig.thruFieldName,
            distributionAmount[validationConfig.thruFieldName],
            errors,
            arrayErrors,
            index,
          );
        }
      }
    }
  }
  if (arrayErrors.length > 0) {
    return { valuesPopup: arrayErrors };
  }
  return {};
};
