import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumericFormat = ({
  inputRef,
  onChange,
  decimalscale,
  returntypevalue,
  ...rest
}) => {
  const handleOnChange = values => {
    // values : {formattedValue: "$34", value: "34", floatValue: 34}
    let value = '';
    switch (returntypevalue) {
      case 'formattedValue':
        value =
          values.formattedValue === undefined ? '' : values.formattedValue;
        break;
      case 'floatValue':
        value = values.floatValue === undefined ? '' : values.floatValue;
        break;
      default:
        value = values.value === undefined ? '' : values.value;
        break;
    }
    return onChange({
      currentTarget: {
        value: value,
      },
      target: {
        value: value,
      },
    });
  };
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => {
        handleOnChange(values);
      }}
      allowNegative={false}
      inputMode="decimal"
      decimalScale={decimalscale}
    />
  );
};

NumericFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalscale: PropTypes.number,
  returntypevalue: PropTypes.string.isRequired,
};

NumericFormat.defaultProps = {
  decimalscale: 2,
  fixedDecimalScale: false,
  returntypevalue: 'floatValue',
};

export default NumericFormat;
