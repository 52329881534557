import React from 'react';

export const RESULT_MESSAGES_SUCCESS = 'Success';
export const RESULT_MESSAGES_ERROR = 'Error';
export const SERVER_ERROR_MESSAGE =
    'Server error: Unable to parse input values.';
export const SERVER_ERROR_COMPARISON_MESSAGE = 'Server error: Unable to get Minimum Non-MEC comparison values';
export const REQUEST_ERROR_MESSAGE = 'Error: There was a problem with your request, check your Internet connection and try again later';
export const POP_BLOCKED = 'Please turn off your Pop-Up blocker to allow both Illustration Outputs to open';
export const DELETE_CONFIRMATION_MESSAGE = ['Warning:  Click \'Delete\' to permanently remove this scenario from your Illustration History view.',<><br /><br /></>,
'This action cannot be undone.  (Click \'Keep\' to cancel)'];
