import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import InputFormik from '../../../../components/InputFormik';
import PercentFormat from '../../../../components/PercentFormat';
import messages from '../../messages';
import InputMaskA999 from '../../../../components/InputMaskA999';
import { TABLE } from '../../../../constants';
import { DOWNSHIFT_OPTION_A99 } from '../../../../components/DownshiftFormik/constants';
import DownshiftFormikField from '../../../../components/DownshiftFormik';
import PropTypes from 'prop-types';

const PremiumAllocation = props => {
  const {
    formikProps,
    arrayHelpers,
    fromColumnLabel,
    thruColumnLabel,
    fromFieldName,
    thruFieldName,
    catalogSettings,
    maxRows,
  } = props;

  const indexList = catalogSettings.map(index => index.value);

  const defaultIndexInfoValues = indexList.map(index => ({
    key: index,
    allocation: '',
  }));
  const intl = useIntl();

  return (
    <>
      <Table>
        <Hidden xsDown>
          <TableHead>
            <TableRow>
              {indexList.map((indexName, index) => (
                <TableCell key={`column-${index}`} align="left">
                  {indexName}
                </TableCell>
              ))}
              <TableCell align="left">{fromColumnLabel}</TableCell>
              <TableCell align="left">{thruColumnLabel}</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
        </Hidden>
        <TableBody>
          {formikProps.values.valuesPopup &&
          formikProps.values.valuesPopup.length > 0
            ? formikProps.values.valuesPopup.map((row, index) => (
              <TableRow
                  key={`${index.toString()}-${formikProps.values.valuesPopup.length.toString()}`}
                >
                {indexList.map((indexName, index2) => (
                  <Fragment
                      key={`fragment-row-${index}-${index2}-${indexName}`}
                    >
                    <Hidden
                        key={`row-sm-${index}-${index2}-${indexName}`}
                        smUp
                      >
                      <TableCell align="left" className="premium-table">
                        {indexName}
                      </TableCell>
                    </Hidden>
                    <TableCell
                        key={`row-${index}-${index2}-${indexName}`}
                        align="left"
                        className="premium-table"
                      >
                      <InputFormik
                          name={`valuesPopup[${index}].indexInfo[${index2}].allocation`}
                          numberFormatCustom={PercentFormat}
                          maxLength={6}
                          decimalscale={0}
                          isErrorMessageDisplayed={false}
                          handleAfterOnChange={(newValue) => {
                            const rowValues =
                              formikProps.values.valuesPopup[index].indexInfo;
                            let total = 0;

                            // eslint-disable-next-line no-unused-vars
                            for (const indexData in rowValues) {
                              if (rowValues[indexData].key === indexName) {
                                total += newValue || 0;
                              } else {
                                total += rowValues[indexData].allocation || 0;
                              }
                            }

                            formikProps.setFieldValue(
                              `valuesPopup[${index}].total`,
                              total,
                            );
                            formikProps.setFieldTouched(
                              `valuesPopup[${index}].total`,
                              true,
                            );
                          }}
                        />
                    </TableCell>
                  </Fragment>
                  ))}

                <Hidden smUp>
                  <TableCell className="premium-table" align="left">
                    {fromColumnLabel}
                  </TableCell>
                </Hidden>
                <TableCell className="premium-table" align="left">
                  <InputFormik
                      name={`valuesPopup[${index}][${fromFieldName}]`}
                      numberFormatCustom={InputMaskA999}
                      isErrorMessageDisplayed={false}
                    />
                </TableCell>
                <Hidden smUp>
                  <TableCell className="premium-table" align="left">
                    {thruColumnLabel}
                  </TableCell>
                </Hidden>
                <TableCell className="premium-table" align="left">
                  <DownshiftFormikField
                      name={`valuesPopup[${index}][${thruFieldName}]`}
                      initialInputValue={row[thruFieldName]}
                      // Dropdown options
                      customInputType={DOWNSHIFT_OPTION_A99}
                      customInputTypeOptions={[DOWNSHIFT_OPTION_A99]}
                      items={[{ value: 'Max', name: 'Max' }]}
                      handleValueOnChange={value => {
                        formikProps.setFieldValue(
                          `valuesPopup[${index}][${thruFieldName}]`,
                          value,
                        );
                      }}
                      handleIsCustomizedValue={() => false}
                    />
                </TableCell>
                <Hidden smUp>
                  <TableCell className="premium-table" align="left">
                    Total
                  </TableCell>
                </Hidden>
                <TableCell className="premium-table" align="left">
                  <InputFormik
                      name={`valuesPopup[${index}].total`}
                      numberFormatCustom={PercentFormat}
                      maxLength={6}
                      decimalscale={2}
                      isErrorMessageDisplayed={false}
                      readOnly
                    />
                </TableCell>

                <Hidden xsDown>
                  <TableCell className="premium-table" align="left">
                    <IconButton
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label="delete"
                      >
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell className="premium-table" align="left">
                    <Link onClick={() => arrayHelpers.remove(index)}>
                      Remove
                    </Link>
                  </TableCell>
                </Hidden>
              </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
      {formikProps.values.valuesPopup.length < maxRows && (
        <Button
          color="primary"
          onClick={() =>
            arrayHelpers.push({
              indexInfo: defaultIndexInfoValues,
              [fromFieldName]: '',
              [thruFieldName]: '',
              total: 0,
            })
          }
        >
          <AddCircleOutlineTwoToneIcon />
          {intl.formatMessage(messages.addNewAllocation)}
        </Button>
      )}
    </>
  );
};

PremiumAllocation.propTypes = {
  formikProps: PropTypes.any,
  arrayHelpers: PropTypes.any,
  fromColumnLabel: PropTypes.any,
  thruColumnLabel: PropTypes.any,
  fromFieldName: PropTypes.any,
  thruFieldName: PropTypes.any,
  catalogSettings: PropTypes.any,
  maxRows: PropTypes.number,
};
PremiumAllocation.defaultProps = {
  isOpen: false,
  rateColumnLabel: 'Rate',
  fromColumnLabel: 'From year',
  thruColumnLabel: 'Thru year',
  rateFieldName: 'indexStrategyRate',
  fromFieldName: 'indexFrom',
  thruFieldName: 'indexThru',
  catalogSettings: [],
  maxRows: TABLE.MAX_ROWS,
};

export default PremiumAllocation;
