import React, { useLayoutEffect, createRef } from 'react';
import { Field, ErrorMessage, getIn } from 'formik';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import { useWizardContext } from '../FormWizard/WizardContext';
import RootRef from '@material-ui/core/RootRef';
import PropTypes from 'prop-types';

const SelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue },
  options,
  label,
  extraIcon,
  handleOnChange,
  labelClassName,
  ...props
}) => {
  const domRef = createRef();
  const ExtraIcon = extraIcon;
  const hasErrors = !!(getIn(touched, field.name) && getIn(errors, field.name));
  useLayoutEffect(() => {
    const elem = domRef.current;
    const ctrl = elem.getElementsByTagName('select')[0];
    if (field.value == null) {
      ctrl.selectedIndex = -1;
    }
  });
  const { fieldsChanged } = useWizardContext();
  const handleSelectOnChange = event => {
    if (handleOnChange) {
      handleOnChange(event);
    }
    setFieldValue(field.name, event.target.value);
  };
  return (
    <div>
      <FormControl variant='filled' fullWidth disabled={props.disabled}>
        <InputLabel
          htmlFor='filled-age-simple'
          error={hasErrors}
          shrink={!!field.value}
          className={labelClassName}
        >
          {label}
        </InputLabel>
        <RootRef rootRef={domRef}>
          <Select
            inputProps={{
              defaultChecked: false,
            }}
            autoWidth
            native
            IconComponent={() =>
              !props.disabled && (
                <div className='icons-container'>
                  <div className='arrow'>
                    <ArrowDropDown color='secondary' />
                  </div>
                </div>
              )
            }
            input={<FilledInput name={field.name} disableUnderline fullWidth />}
            {...props}
            {...field}
            error={hasErrors}
            onChange={handleSelectOnChange}
            className={_.find(fieldsChanged, n => n === field.name) ? `option-changed` : ''}
          >
            {options &&
              options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
          </Select>
        </RootRef>
        {!!extraIcon && (
          <div className='extra-icon'>
            <ExtraIcon />
          </div>
        )}
      </FormControl>
      <ErrorMessage component='span' name={field.name} />
    </div>
  );
};

SelectComponent.propTypes = {
  field: PropTypes.any,
  form: PropTypes.any,
  options: PropTypes.any,
  label: PropTypes.any,
  extraIcon: PropTypes.any,
  handleOnChange: PropTypes.any,
  disabled: PropTypes.any,
  labelClassName: PropTypes.string,
};

const SelectField = props => <Field component={SelectComponent} {...props} />;

export default SelectField;

