import React from 'react';
import variables from '../../themeVars';

export const EmptyCheckBoxSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <defs>
      <clipPath id="bmk8a">
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="#d9d9d9"
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
          clipPath='url("#bmk8a")'
        />
      </g>
    </g>
  </svg>
);

export const FilledCheckBoxSvg = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Ico-/-Selector-/-Check-on" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="bg" stroke="#0047BB" fill="#0047BB" x="0.5" y="0.5" width="17" height="17" rx="4"></rect>
      <polygon id="Icon-Path" fill="#FFFFFF" points="4 7.33333333 2.33333333 9 7.33333333 14 15.6666667 5.66666667 14 4 7.33333333 10.6666667"></polygon>
    </g>
  </svg>
);

export const PartialCheckBoxSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <defs>
      <clipPath id="za2ca">
        <path
          fill="#fff"
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill={variables.checkboxCheckedFill}
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        />
        <path
          fill="none"
          stroke={variables.checkboxCheckedFill}
          strokeMiterlimit="50"
          strokeWidth="2"
          d="M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
          clipPath='url("#za2ca")'
        />
      </g>
      <g>
        <path fill="#fff" d="M4 8h10v2H4z" />
      </g>
    </g>
  </svg>
);
