/*
 * Illustration Messages
 *
 * This contains all the text for the Illustration component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Illustration';

export default defineMessages({
  wizardNewTitle: {
    id: `${scope}.wizardNewTitle`,
    defaultMessage: 'New Illustration',
  },
  wizardEditTitle: {
    id: `${scope}.wizardEditTitle`,
    defaultMessage: 'Edit Illustration',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  runIllustration: {
    id: `${scope}.runIllustration`,
    defaultMessage: 'Run Illustration',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'submit',
  },
  presentedBy: {
    id: `${scope}.presentedBy`,
    defaultMessage: 'Presented By',
  },
  illustrationTitle: {
    id: `${scope}.illustrationTitle`,
    defaultMessage: 'Title of Illustration (optional)',
  },
  insuredInformation: {
    id: `${scope}.insuredInformation`,
    defaultMessage: 'Insured Information',
  },
  insuredInformationHint: {
    id: `${scope}.insuredInformationHint`,
    defaultMessage: `<div><div class="subtitle">Issue State</div>
          <div>Enter the state in which the policy will be issued.</div>
          <div class="subtitle" style="margin-top:10px">First Name/Last Name</div>
          <div>Enter the First and Last Name of the insured as you wish it to appear on the illustration.</div>
          <div class="subtitle" style="margin-top:10px">Sex</div>
          <div>Enter the gender of the insured. Valid choices are Male and Female.</div>
          <div class="subtitle" style="margin-top:10px">Issue Age or D.O.B.</div>
          <div>Enter the insured’s issue age or Date of Birth.</div>
          <div class="subtitle" style="margin-top:10px">Risk Class</div>
          <div>Select the Risk Class of the insured. Selections vary based on Product and Issue Age.</div>
          <div class="subtitle" style="margin-top:10px">Tax Bracket</div>
          <div>Enter the applicable tax bracket. This field may vary by duration. Valid entries are between 0% and 100%.</div>
        </div>`,
  },
  faceAmount: {
    id: `${scope}.faceAmount`,
    defaultMessage: 'Face Amount',
  },
  faceAmountHint: {
    id: `${scope}.faceAmountHint`,
    defaultMessage: `<div><div class="subtitle">Total Specified Amount Option</div>
          <div>Enter the total death benefit coverage amount desired. This amount includes both Base and Term face amounts. Inputs may be numeric, or they can be keywords.</div>
          <div class="subtitle" style="margin-top:10px">Section 7702</div>
          <div>Enter the method of compliance with IRS code section 7702, for the Definition of Life Insurance.</div>
        </div>`,
  },
  interestRate: {
    id: `${scope}.interestRate`,
    defaultMessage: 'Interest Rate',
  },
  riderOptions: {
    id: `${scope}.riderOptions`,
    defaultMessage: 'Rider Options',
  },
  basicIllustration: {
    id: `${scope}.basicIllustration`,
    defaultMessage: 'Basic Illustration',
  },
  personalizedSalesIdeas: {
    id: `${scope}.personalizedSalesIdeas`,
    defaultMessage: 'Personalized Sales Ideas',
  },

  //insured step
  issueState: {
    id: `${scope}.issueState`,
    defaultMessage: 'Issue State',
  },
  revisedIllustration: {
    id: `${scope}.revisedIllustration`,
    defaultMessage: 'Revised Illustration',
  },
  revisedIllustrationHint: {
    id: `${scope}.revisedIllustrationHint`,
    defaultMessage:
      '<div><div>Designate if you are running a revised illustration</div><div style="margin-top:10px">An NAIC model regulation, adopted by most states, requires that the illustrative output must reflect that it is a revised illustration when a universal life application is approved by Underwriting differently than how it was originally illustrated (e.g. a different underwriting class or addition of a rating). “Revised” must be present on the illustrative output. Currently, this requirement only applies to universal life products.</div></div>',
  },
  financePremiums: {
    id: `${scope}.financePremiums`,
    defaultMessage: 'Premium Financed',
  },
  financePremiumsHint: {
    id: `${scope}.financePremiums`,
    defaultMessage:
      '<div>Premium Financing is defined as borrowing premiums from an unrelated 3rd party lender where the policy will be used as any part of the collateral for the loan.</div>',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Sex',
  },
  issueAgeOrDateOfBirth: {
    id: `${scope}.issueAgeOrDateOfBirth`,
    defaultMessage: 'Issue Age or D.O.B. (MM/DD/YYYY)',
  },
  ageNearestBirthday: {
    id: `${scope}.ageNearestBirthday`,
    defaultMessage: '(Age Nearest Birthday)',
  },
  issueAge: {
    id: `${scope}.issueAge`,
    defaultMessage: 'Issue Age',
  },
  riskClass: {
    id: `${scope}.riskClass`,
    defaultMessage: 'Risk Class',
  },
  riskClassHint: (data = []) => {
    const riskClassesHtml = data.map(item => {
    return `
        <tr>
          <td>${item.name.replace('Non-Tobacco', '<nobr>Non-Tobacco</nobr>')}</td>
          <td>${item.minAge}</td>
          <td>${item.maxAge}</td>
        </tr>`;
    })

    const defaultMessage = `
    <table className="risk-class-hint-table">
      <tr>
        <th>Class</th>
        <th>Minimum <br/>Issue Age</th>
        <th>Maximum <br/>Issue Age</th>
      </tr>
      ${riskClassesHtml.join('')}
    </table>
    `;
    return {
      id: `${scope}.riskClassHint`,
      defaultMessage
    };
  },
  taxBracket: {
    id: `${scope}.taxBracket`,
    defaultMessage: 'Tax Bracket',
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: 'Rating',
  },
  tableRating: {
    id: `${scope}.tableRating`,
    defaultMessage: 'Table Rating',
  },
  flatExtra: {
    id: `${scope}.flatExtra`,
    defaultMessage: 'Flat Extra',
  },

  //Face amount and premium
  totalSpecifiedAmount: {
    id: `${scope}.totalSpecifiedAmount`,
    defaultMessage: 'Total Specified Amount Option',
  },
  increaseInitialDeathBenefit: {
    id: `${scope}.increaseInitialDeathBenefit`,
    defaultMessage: 'Increase Initial Death Benefit',
  },
  section7702: {
    id: `${scope}.section7702`,
    defaultMessage: 'Section 7702',
  },
  estimateClient: {
    id: `${scope}.estimateClient`,
    defaultMessage: "Estimate Client's Life Insurance Need",
  },
  reduceSpecifiedAmount: {
    id: `${scope}.reduceSpecifiedAmount`,
    defaultMessage: 'Reduce Specified Amount',
  },
  deathBenefitOption: {
    id: `${scope}.deathBenefitOption`,
    defaultMessage: 'Death Benefit Option',
  },
  //Premium
  premium: {
    id: `${scope}.premium`,
    defaultMessage: 'Premium',
  },
  premiumHint: {
    id: `${scope}.premiumHint`,
    defaultMessage: `<div><div class="subtitle">Scheduled Premium</div>
          <div>Enter periodic premium amounts paid into the policy.</div>
          <div class="subtitle" style="margin-top:10px">Premium Mode</div>
          <div>Enter the number of times per year that premium payments will be made.</div>
          <div class="subtitle" style="margin-top:10px">Lump Sum Premium</div>
          <div>Enter any lump sum payments here.</div>
          <div class="subtitle" style="margin-top:10px">1035 Exchange</div>
          <div>Check box if you wish to illustrate a 1035 exchange.</div>
          <div class="subtitle" style="margin-top:10px">Avoid MEC</div>
          <div>Check box if you wish for the system to restrain premiums and withdrawals as necessary to avoid becoming a Modified Endowment Contract.</div>
        </div>`,
  },
  scheduledPremium: {
    id: `${scope}.scheduledPremium`,
    defaultMessage: 'Scheduled Premium',
  },
  premiumMode: {
    id: `${scope}.premiumMode`,
    defaultMessage: 'Premium Mode',
  },
  monthToBeginScheduledPremium: {
    id: `${scope}.monthToBeginScheduledPremium`,
    defaultMessage: 'Month to Begin Scheduled Premium',
  },
  lumpSumPremium: {
    id: `${scope}.lumpSumPremium`,
    defaultMessage: 'Lump Sum Premium',
  },
  lumpSumMonthFirstYear: {
    id: `${scope}.lumpSumMonthFirstYear`,
    defaultMessage: 'Lump Sum Month First Year',
  },
  exchange1035: {
    id: `${scope}.exchange1035`,
    defaultMessage: '1035 Exchange',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  loanBalance: {
    id: `${scope}.loanBalance`,
    defaultMessage: 'Loan Balance',
  },
  exchangeBasis: {
    id: `${scope}.exchangeBasis`,
    defaultMessage: 'Exchange Basis',
  },
  existingContractMEC: {
    id: `${scope}.existingContractMEC`,
    defaultMessage: 'Existing Contract is a MEC',
  },
  monthReceived: {
    id: `${scope}.monthReceived`,
    defaultMessage: 'Month 1035 is Received',
  },
  advancedPremiumFund: {
    id: `${scope}.advancedPremiumFund`,
    defaultMessage: 'Advanced Premium Fund',
  },
  ageYear: {
    id: `${scope}.ageYear`,
    defaultMessage: 'Age/Year',
  },
  discountRate: {
    id: `${scope}.discountRate`,
    defaultMessage: 'Discount Rate',
  },
  avoidMEC: {
    id: `${scope}.avoidMEC`,
    defaultMessage: 'Avoid MEC',
  },

  //targeting
  targeting: {
    id: `${scope}.targeting`,
    defaultMessage: 'Targeting ',
  },
  targetingHint: {
    id: `${scope}.targetingHint`,
    defaultMessage: `<div><div class="subtitle">Target Cash Value</div>
          <div>Enter the net surrender value that you wish to achieve if performing a solve.</div>
          <div class="subtitle" style="margin-top:10px">Target Year</div>
          <div>Specify the year in which you would like to achieve your desired net surrender value. Can be entered as a durational value, or as an attained age value (ex: A99).</div>
        </div>`,
  },
  whenSolves: {
    id: `${scope}.whenSolves`,
    defaultMessage: '(when Solves utilized)',
  },
  targetCashValue: {
    id: `${scope}.targetCashValue`,
    defaultMessage: 'Target Cash Value',
  },
  targetDeathBenefitYear: {
    id: `${scope}.targetDeathBenefitYear`,
    defaultMessage: 'Target Death Benefit Year',
  },
  targetYear: {
    id: `${scope}.targetYear`,
    defaultMessage: 'Target Year',
  },

  //Interest Rate & Income
  premiumAllocation: {
    id: `${scope}.premiumAllocation`,
    defaultMessage: 'Premium Allocation',
  },
  maturedSegmentAllocation: {
    id: `${scope}.maturedSegmentAllocation`,
    defaultMessage: 'Matured Segment Allocation',
  },
  fixedCreditingRate: {
    id: `${scope}.fixedCreditingRate`,
    defaultMessage: 'Fixed Crediting Rate',
  },
  fixedCreditRateNote: {
    id: `${scope}.fixedCreditRateNote`,
    defaultMessage: 'Range is {minValue}% - {maxValue}%',
  },
  indexedNote: {
    id: `${scope}.indexedNote`,
    defaultMessage: 'Range is {minValue}% - {maxValue}%',
  },
  coreNewHeightsIndexedInterestStrategies: {
    id: `${scope}.coreNewHeightsIndexedInterestStrategies`,
    defaultMessage: 'Core Indexed Interest Strategies',
  },
  oneYrCappedSP500: {
    id: `${scope}.oneYrCappedSP500`,
    defaultMessage: '1-Yr Capped S&P 500',
  },
  oneYrCappedSP500Link: {
    id: `${scope}.oneYrCappedSP500Link`,
    defaultMessage: 'Click Here to Get Info on Index Strategies',
  },
  twoYrSP500: {
    id: `${scope}.twoYrSP500`,
    defaultMessage: '2-Yr S&P 500',
  },
  oneHighYrGoldman: {
    id: `${scope}.oneHighYrGoldman`,
    defaultMessage: '1-Yr High Par 5 Goldman Sachs New Horizons',
  },
  oneYrGoldman: {
    id: `${scope}.oneYrGoldman`,
    defaultMessage: '1-Yr Goldman Sachs New Horizons',
  },
  twoYrGoldman: {
    id: `${scope}.twoYrGoldman`,
    defaultMessage: '2-Yr Goldman Sachs New Horizons',
  },
  oneYrJPMorganMozaicII: {
    id: `${scope}.oneYrJPMorganMozaicII`,
    defaultMessage: '1-Yr J.P. Morgan Mozaic II',
  },
  twoYrJPMorganMozaicII: {
    id: `${scope}.twoYrJPMorganMozaicII`,
    defaultMessage: '2-Yr J.P. Morgan Mozaic II',
  },
  oneYrNYSEZebraEdge: {
    id: `${scope}.oneYrNYSEZebraEdge`,
    defaultMessage: '1-Yr NYSE Zebra Edge',
  },
  twoYrNYSEZebraEdge: {
    id: `${scope}.twoYrNYSEZebraEdge`,
    defaultMessage: '2-Yr NYSE Zebra Edge',
  },
  choicePlusIndexedStrategies: {
    id: `${scope}.choicePlusIndexedStrategies`,
    defaultMessage: 'Choice Plus Indexed Interest Strategies',
  },
  oneYrChoicePlusJPMorganMozaicII: {
    id: `${scope}.oneYrChoicePlusJPMorganMozaicII`,
    defaultMessage: '1-Yr Choice Plus J.P. Morgan Mozaic II',
  },
  oneYrChoicePlusNYSEZebraEdge: {
    id: `${scope}.oneYrChoicePlusNYSEZebraEdge`,
    defaultMessage: '1-Yr Choice Plus NYSE Zebra Edge',
  },
  oneYrChoicePlusCappedSP500: {
    id: `${scope}.oneYrChoicePlusCappedSP500`,
    defaultMessage: '1-Yr Choice Plus Capped S&P 500',
  },
  income: {
    id: `${scope}.income`,
    defaultMessage: 'Income',
  },
  incomeDistributionAmounts: {
    id: `${scope}.incomeDistributionAmounts`,
    defaultMessage: 'Distribution Amounts',
  },
  incomeWithdrawalCap: {
    id: `${scope}.incomeWithdrawalCap`,
    defaultMessage: 'Withdrawal Cap',
  },
  incomeLoanInterestPaymentMethod: {
    id: `${scope}.incomeLoanInterestPaymentMethod`,
    defaultMessage: 'Loan Interest Payment Method',
  },
  incomeDistributionMode: {
    id: `${scope}.incomeDistributionMode`,
    defaultMessage: 'Distribution Mode',
  },
  incomeLoanType: {
    id: `${scope}.incomeLoanType`,
    defaultMessage: 'Loan Type',
  },

  //Riders Tab
  longTermCareRiderII: {
    id: `${scope}.longTermCareRiderII`,
    defaultMessage: 'Long Term Care Rider II',
  },
  accidentalDeathBenefit: {
    id: `${scope}.accidentalDeathBenefit`,
    defaultMessage: 'Accidental Death Benefit',
  },
  maximumMonthlyBenefit: {
    id: `${scope}.maximumMonthlyBenefit`,
    defaultMessage: 'Maximum Monthly Benefit',
  },
  longTermCareAmount: {
    id: `${scope}.longTermCareAmount`,
    defaultMessage: 'Long Term Care Amount',
  },
  rateClass: {
    id: `${scope}.rateClass`,
    defaultMessage: 'Rate Class',
  },
  rateClassNote: {
    id: `${scope}.rateClassNote`,
    defaultMessage:
      'Life Insurance ratings of Standard or better should generally use Preferred LTC II ratings',
  },
  marriedOrCivilUnionOrDomesticPartnership: {
    id: `${scope}.marriedOrCivilUnionOrDomesticPartnership`,
    defaultMessage: 'Married/Civil Union/Domestic',
  },
  printLTCAnalysisRepot: {
    id: `${scope}.printLTCAnalysisRepot`,
    defaultMessage: 'Print LTC Analysis',
  },
  hipaaPerDiemRate: {
    id: `${scope}.hipaaPerDiemRate`,
    defaultMessage: 'HIPAA Per Diem Rate',
  },
  ageToBeginLTCRiderBenefit: {
    id: `${scope}.ageToBeginLTCRiderBenefit`,
    defaultMessage: 'Age to begin LTC rider benefit',
  },
  desiredDailyLTCBenefit: {
    id: `${scope}.desiredDailyLTCBenefit`,
    defaultMessage: 'Desired Daily LTC benefit',
  },
  desiredDailyLTCBenefitNote: {
    id: `${scope}.desiredDailyLTCBenefitNote`,
    defaultMessage: "In today's dollars",
  },
  ADBAmount: {
    id: `${scope}.ADBAmount`,
    defaultMessage: 'Amount',
  },
  ADBRating: {
    id: `${scope}.ADBRating`,
    defaultMessage: 'Rating',
  },
  spouseTermInsuranceAmount: {
    id: `${scope}.spouseTermInsuranceAmount`,
    defaultMessage: 'Spouse Term',
  },
  waiverRider: {
    id: `${scope}.waiverRider`,
    defaultMessage: 'Waiver Rider',
  },
  waiverType: {
    id: `${scope}.waiverType`,
    defaultMessage: 'Type',
  },
  waiverPremiumAmount: {
    id: `${scope}.waiverPremiumAmount`,
    defaultMessage: 'Amount',
  },
  waiverRating: {
    id: `${scope}.waiverRating`,
    defaultMessage: 'Rating',
  },
  returnOfPremium: {
    id: `${scope}.returnOfPremium`,
    defaultMessage: 'Return of Premium',
  },
  indexInterestStrategyInformation: {
    id: `${scope}.indexInterestStrategyInformation`,
    defaultMessage: 'Index Interest Strategy Information',
  },
  generalLedger: {
    id: `${scope}.generalLedger`,
    defaultMessage: 'General Ledger',
  },
  annualCostSummary: {
    id: `${scope}.annualCostSummary`,
    defaultMessage: 'Annual Cost Summary',
  },
  internalRateOfReturnReport: {
    id: `${scope}.internalRateOfReturnReport`,
    defaultMessage: 'Internal Rate of Return',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium Summary',
  },
  nationwideFinancialStrength: {
    id: `${scope}.nationwideFinancialStrength`,
    defaultMessage: 'Nationwide Financial Strength',
  },
  valueOfLifeInsuranceReport: {
    id: `${scope}.valueOfLifeInsuranceReport`,
    defaultMessage: 'Value of Life Insurance Report',
  },
  commissionSummary: {
    id: `${scope}.commissionSummary`,
    defaultMessage: 'Commission Summary',
  },
  occReport: {
    id: `${scope}.occReport`,
    defaultMessage: 'OCC Report',
  },
  outlayTaxReport: {
    id: `${scope}.outlayTaxReport`,
    defaultMessage: 'Outlay/Tax Report',
  },
  surrenderChargeReport: {
    id: `${scope}.surrenderChargeReport`,
    defaultMessage: 'Surrender Charge Report',
  },
  monthlyCostSummary: {
    id: `${scope}.monthlyCostSummary`,
    defaultMessage: 'Monthly Cost Summary',
  },
  whichIllustration: {
    id: `${scope}.whichIllustration`,
    defaultMessage: 'Select Illustration',
  },
  yearsForMonthlyOutput: {
    id: `${scope}.yearsForMonthlyOutput`,
    defaultMessage: 'Years for Monthly Output',
  },
  monthlyCostOfInsuranceReport: {
    id: `${scope}.monthlyCostOfInsuranceReport`,
    defaultMessage: 'Monthly COI Report',
  },
  costAndBenefitReport: {
    id: `${scope}.costAndBenefitReport`,
    defaultMessage: 'Cost and Benefit Report',
  },
  netCostPerDollar: {
    id: `${scope}.netCostPerDollar`,
    defaultMessage: 'Net Cost Per Dollar',
  },
  costOfMoney: {
    id: `${scope}.costOfMoney`,
    defaultMessage: 'Cost of Money',
  },
  rangeOfPossibleOutcomes: {
    id: `${scope}.rangeOfPossibleOutcomes`,
    defaultMessage: 'Range of Possible Outcomes',
  },
  iulAsRothIRASupplement: {
    id: `${scope}.iulAsRothIRASupplement`,
    defaultMessage:
      'IUL as a Roth IRA Supplement (Distributions must be illustrated)',
  },
  insuranceBasedIncomeSolution: {
    id: `${scope}.insuranceBasedIncomeSolution`,
    defaultMessage:
      'Insurance Based Income Solution (Distributions must be illustrated)',
  },
  basicTermLifeInsurance: {
    id: `${scope}.basicTermLifeInsurance`,
    defaultMessage:
      'Include Term Life Quote',
  },
  basicTermLifeInsuranceTerm: {
    id: `${scope}.basicTermLifeInsuranceTerm`,
    defaultMessage:
      'Term',
  },
  basicTermLifeInsurancefaceAmount: {
    id: `${scope}.basicTermLifeInsurancefaceAmount`,
    defaultMessage:
      'Face Amount',
  },
  basicTermLifeInsurancepremiumMode: {
    id: `${scope}.basicTermLifeInsurancepremiumMode`,
    defaultMessage:
      'Premium Mode',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
    },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
    },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
    },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Add New {type}',
  },
  addNewRate: {
    id: `${scope}.addNewRate`,
    defaultMessage: 'Add New Rate',
  },
  addNewFaceAmount: {
    id: `${scope}.addNewFaceAmount`,
    defaultMessage: 'Add New Face Amount',
  },
  addNewAllocation: {
    id: `${scope}.addNewAllocation`,
    defaultMessage: 'Add New Allocation',
  },
  standard: {
    id: `${scope}.standard`,
    defaultMessage: 'Standard',
  },
  advanced: {
    id: `${scope}.advanced`,
    defaultMessage: 'Advanced',
  },

  //change the tooltip using  the correct text
  outputHint: {
    id: `${scope}.outputHint`,
    defaultMessage: `<div>
          <div>Select the output reports and any desired additional reports or personalized sales ideas to be included in the illustration PDF.</div>
        </div>`,
  },
  indexedInterestStrategiesHint: {
    id: `${scope}.indexedInterestStrategiesHint`,
    defaultMessage: `<div>
          <div>Specify percentage for desired crediting rates.</div>
          <div style="margin-top:10px">Default crediting rates are pre-loaded, allowed ranges displayed below each input.</div>
          <div style="margin-top:10px">Varying crediting rates based on duration can be specified via the schedule box.</div>
        </div>`,
  },
  incomeHint: {
    id: `${scope}.incomeHint`,
    defaultMessage: `<div><div class="subtitle">Distribution Amounts</div>
          <div>Distribution amounts take the form of withdrawals, loans, or loan repayments. Specify an amount to be taken (repaid), and then specify the logic that should be followed when performing the income (repayment) transaction.</div>
          <div class="subtitle" style="margin-top:10px">Withdrawal Cap</div>
          <div>Enter the desired limit on cumulative withdrawals taken from the policy.</div>
          <div class="subtitle" style="margin-top:10px">Loan Interest Payment Method</div>
          <div>Enter “Borrow” if the insured is going to borrow capitalized loan interest as well as principle when loans are taken. Enter “Cash” if the insured is going to pay the accrued loan interest out of pocket at the end of each year after loans are taken.</div>
          <div class="subtitle" style="margin-top:10px">Distribution Mode</div>
          <div>Choose frequency of distribution.</div>
        </div>`,
  },
  riderHint: {
    id: `${scope}.riderHint`,
    defaultMessage: `<div>
          <div>Choose desired Riders.</div>
        </div>`,
  },
  interestRateHint: {
    id: `${scope}.interestRateHint`,
    defaultMessage: `<div><div class="subtitle">Premium Allocation</div>
          <div>Specify what strategies (indexed and/or fixed) the premium will be allocated to:<ul><li>If 100% allocation to a single strategy, a dropdown should be used.</li><li>If a prorated allocation to multiple strategies is desired, then utilize the schedule box (click icon to expand).</li></ul></div>
          <div class="subtitle" style="margin-top:10px">Matured Segment Allocation</div>
          <div>Specify what strategies (index and/or fixed) the matured indexed interest strategies will be allocated to:<ul><li>If 100% allocation to a single strategy, a dropdown should be used.</li><li>If a prorated allocation to multiple strategies is desired, then utilize the schedule box (click icon to expand).</li></ul></div>
          <div class="subtitle" style="margin-top:10px">Fixed Crediting Rate</div>
          <div>Specify percentage of fixed interest crediting to policy fixed interest strategy.</div>
        </div>`,
  },
  presentValueIntRate: {
    id: `${scope}.presentValueIntRate`,
    defaultMessage: 'Present Value Int Rate',
  },
  popupMessageWarning: {
    id: `${scope}.popupMessageWarning`,
    defaultMessage:
      'Note: Please <b>turn off your Pop-Up blocker</b> to allow the Illustration Output to open.',
  },
  increaseDeathBenefit20percent: {
    id: `${scope}.increaseDeathBenefit20percent`,
    defaultMessage: 'Increase Death Benefit by 20%',
  },
  surrenderValueEnhancementRider: {
    id: `${scope}.surrenderValueEnhancementRider`,
    defaultMessage: 'Surrender Value Enhancement Rider',
  },
  changeOfInsuredRider: {
    id: `${scope}.changeOfInsuredRider`,
    defaultMessage: 'Change of Insured Rider',
  },
});
