import { Fade, Slide } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const SlideFadeIn = ({ slideDirection, transitionIn, slideTimeout, fadeEnterTimeout, fadeExitTimeout, children }) => {
  return (
    <Slide direction={slideDirection} in={transitionIn} timeout={slideTimeout} unmountOnExit>
      <div>
        <Fade in={transitionIn} timeout={{ enter: fadeEnterTimeout, exit: fadeExitTimeout }}>
          {children}
        </Fade>
      </div>
    </Slide>
  )
}

SlideFadeIn.propTypes = {
  slideDirection: PropTypes.string,
  transitionIn: PropTypes.bool,
  slideTimeout: PropTypes.number,
  fadeEnterTimeout: PropTypes.number,
  fadeExitTimeout: PropTypes.number,
  children: PropTypes.shape()
}

SlideFadeIn.defaultProps = {
  slideDirection: "right",
  transitionIn: false,
  slideTimeout: 1000,
  fadeEnterTimeout: 1000,
  fadeExitTimeout: 1000
}

export default SlideFadeIn