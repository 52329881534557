import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button, Box, Table, TableHead, TableBody, TableRow, TableCell, Modal, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import messages from '../../../messages';
import { EmptyRadioButtonSvg, FilledRadioButtonSvg } from '../../../../../components/RadioButtonMinNonMec/radioButton-svg';
import {
  FASE_AMOUNT_PREMIUM, NONMEC_PERCENT_INCREASE, PDF_FILENAMES,
} from '../../../../../constants';
import InputFormik from '../../../../../components/InputFormik';
import CurrencyFormat from '../../../../../components/CurrencyFormat';
import PercentFormat from '../../../../../components/PercentFormat';
import { useWizardContext } from '../../../../../components/FormWizard/WizardContext';
import PropTypes from 'prop-types';
import { useAppContext } from '../../../../App/AppContext';
import useStyles from './styles';
import SkeletonComponent from '../../../../../components/Skeleton';
import SlideFadeIn from '../../../../../utils/transitions/SlideFadeIn';
import PdfDownloadLink from '../../PdfDownloadLink';

const MinNonMECComparison = props => {
  const { settings } = useAppContext();
  const { values, setFieldValue, errors } = useWizardContext();
  const {
    ValidateSavePopup,
    ValidateCancelPopup,
    opens,
    handleSubmit,
    data,
    handleOnChange,
    radioButtonsValue,
    fetchDeathBenData,
    increaseInitialDeathBen,
    specAmountGetted,
    cancelationComparisonRequest
  } = props;

  const SkeletonConfig = {
    height: 20
  }
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [open, setOpen] = useState(!opens);
  const [deathBenefitAmountRowSelected, setDeathBenefitAmountRowSelected] = useState(false);
  const intl = useIntl();
  const [specifiedAmountRow, setSpecifiedAmountRow] = useState(false);
  const [disabledAddNewRow, setDisabledAddNewRow] = useState(false);
  const [initialRadioValue] = useState(radioButtonsValue);
  const [deathBenefitAmount, setDeathBenefitAmount] = useState(values.illustrationIndicators.deathBenefitAmount);
  const titles = {
    deathBenefit: 'Death Benefit',
    targetPremium: 'Target Premium',
    income: "Income",
    cashValue10: 'Year 10 Cash Value',
    cashValue90: 'Cash Value at Age 90',
    pdf: 'PDF'
  }

  useEffect(() => {
    if (!values.illustrationId) {
      setSpecifiedAmountRow(specAmountGetted);
      setDisabledAddNewRow(specAmountGetted);
    }
  }, [data.SpecAmnt, specAmountGetted, values.illustrationId]);

  useEffect(() => {
    if ((data?.MinNonMec?.responseSpecifiedAmount && !data?.SpecAmnt?.responseSpecifiedAmount) || !specifiedAmountRow) {
      setFieldValue('illustrationIndicators.deathBenefitAmount', data?.MinNonMec?.responseSpecifiedAmount);
      handleResetPercentAmount(true);
    } else {
      handleOnChange(FASE_AMOUNT_PREMIUM.SpecAmount.value);
      setFieldValue('illustrationIndicators.deathBenefitAmount', data?.SpecAmnt?.responseSpecifiedAmount);
    }
  }, [data, specifiedAmountRow]);

  const SavePopup = () => {
    if (isDeathBenefitAmountValid) {
      setOpen(false);
      ValidateSavePopup();
      handleSubmit();
    }
  };
  const CancelPopup = () => {
    setOpen(false);
    cancelationComparisonRequest();
    handleResetPercentAmount(true);
    ValidateCancelPopup();
  };

  const isDeathBenefitAmountValid = !errors?.illustrationIndicators?.deathBenefitAmount &&
    values.illustrationIndicators.deathBenefitAmount &&
    !isNaN(values.illustrationIndicators.deathBenefitAmount);

  const rafactorAmount = amount =>
    `$${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  const refactorAmountWithOutDecimals = amount =>
    `$${amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  const addSpecifiedAmountRow = radioValue => {
    if (!errors?.illustrationIndicators?.deathBenefitAmount && values.illustrationIndicators.deathBenefitAmount > 0) {
      if (data?.SpecAmnt?.responseSpecifiedAmount > 0) {
        data.SpecAmnt = null;
      }
      setSpecifiedAmountRow(true);
      setDeathBenefitAmountRowSelected(false);
      setDisabledAddNewRow(true);
      handleOnChange(radioValue);
      fetchDeathBenData(refactorAmountWithOutDecimals(values.illustrationIndicators.deathBenefitAmount));
      setDeathBenefitAmount(values.illustrationIndicators.deathBenefitAmount);
    }
  }

  const disabledElement = ((!data?.MinNonMec?.responseTargetPremium && !data?.BalanceSolve?.responseTargetPremium) || data?.BalanceSolve === null);
  const disabledOnLoad = disabledElement || (!data?.SpecAmnt && specifiedAmountRow);

  const onRendered = () => {
    if (values.illustrationId) {
      data.SpecAmnt = null
      removeSpecifiedAmountRow();
    } else if (data?.SpecAmnt) {
      data.SpecAmnt = null;
      addSpecifiedAmountRow(FASE_AMOUNT_PREMIUM.MinNonMec.value);
    }
  }

  const removeSpecifiedAmountRow = () => {
    setSpecifiedAmountRow(false);
    setDeathBenefitAmountRowSelected(false);
    setDisabledAddNewRow(false);
    handleResetPercentAmount(true);
    handleOnChange(initialRadioValue);
  }

  const paperClass = settings.featureFlip.showCashColumns ? classes.paper : `${classes.paper} cash-columns-not-visible`;

  const handlePercentageChange = (percentValue = NONMEC_PERCENT_INCREASE.MIN_VALUE) => {
    const calcValue = !isNaN(percentValue) ? ((+deathBenefitAmount) * ((+percentValue) / 100)) +
      (+deathBenefitAmount) : undefined;
    if (!isNaN(percentValue) && percentValue > 0 && percentValue <= NONMEC_PERCENT_INCREASE.MAX_VALUE) {
      setFieldValue('illustrationIndicators.deathBenefitAmount', calcValue)
    } else if (!isNaN(percentValue) && percentValue > NONMEC_PERCENT_INCREASE.MAX_VALUE) {
      setFieldValue('illustrationIndicators.increaseByPercentage', NONMEC_PERCENT_INCREASE.MAX_VALUE)
      setFieldValue('illustrationIndicators.deathBenefitAmount', calcValue)
    } else {
      setFieldValue('illustrationIndicators.deathBenefitAmount', deathBenefitAmount)
    }
  }

  const handleAmountChange = () => {
    setDeathBenefitAmount(values.illustrationIndicators.deathBenefitAmount);
    if (+values.illustrationIndicators.increaseByPercentage !== 0) {
      setFieldValue('illustrationIndicators.increaseByPercentage', 0);
    }
  }

  const handleResetPercentAmount = (resetValues = false) => {
    if ((!data?.SpecAmnt?.responseSpecifiedAmount && data?.SpecAmnt?.responseSpecifiedAmount !== values.illustrationIndicators.deathBenefitAmount) ||
      resetValues) {
      const deathBenefitAmountValue = data?.MinNonMec?.responseSpecifiedAmount ?? deathBenefitAmount
      setFieldValue('illustrationIndicators.deathBenefitAmount', deathBenefitAmountValue);
      setDeathBenefitAmount(deathBenefitAmountValue);
      setFieldValue('illustrationIndicators.increaseByPercentage', 0);
    }
  }

  const disabledAddButton = ((!data?.SpecAmnt && specifiedAmountRow) || !!errors?.illustrationIndicators?.deathBenefitAmount);
  const selectedRowStyle = value => radioButtonsValue === value ? 'selectedRow' : null;

  return (
    <Modal
      onClose={CancelPopup}
      onRendered={onRendered}
      open={open}
      disableBackdropClick
      onEscapeKeyDown={CancelPopup}
      className={classes.modal}
      container={() => rootRef.current}
    >
      <div className={paperClass}>
        <div className={classes.wrapper}>
          <h2 className={classes.modalTitle}>Minimum Non-MEC Comparison</h2>
          <div className={classes.textDescription}>
            You have selected a face amount of{' '}
            <span className={classes.fontMedium}>minimum Non-MEC</span>. Section
            7702 has changed the Modified Endowment Contract thresholds. Below
            is a comparison of minimum Non-MEC calculations pre and post Section
            7702. You may also compare to a specific a total death benefit
            coverage amount.
          </div>
          <div className={classes.fontMedium}>
            Select one of the options below to continue.
          </div>
          <div className={classes.wrapper}>
            <RadioGroup
              name='radioGroupSelection'
              defaultValue={FASE_AMOUNT_PREMIUM.MinNonMec.value}
              value={radioButtonsValue}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.tableTitle}>
                      {titles.deathBenefit}
                    </TableCell>
                    <TableCell className={classes.tableTitle}>
                      {titles.targetPremium}
                    </TableCell>
                    <TableCell className={classes.tableTitle}>
                      {titles.income}
                    </TableCell>
                    {settings.featureFlip.showCashColumns && (
                      <>
                        <TableCell className={classes.tableTitle}>
                          {titles.cashValue10}
                        </TableCell>
                        <TableCell className={classes.tableTitle}>
                          {titles.cashValue90}
                        </TableCell>
                      </>
                    )}
                    <TableCell className={`pdf-cell ${classes.tableTitle}`}>
                      {titles.pdf}
                    </TableCell>
                    <TableCell className={classes.tableTitle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="premium-table">
                      <FormControlLabel
                        onChange={event => handleOnChange(event.target.value)}
                        disabled={disabledElement}
                        className="radio-button"
                        control={
                          <Radio
                            value={FASE_AMOUNT_PREMIUM.BalanceSolve.value}
                            icon={<EmptyRadioButtonSvg />}
                            checkedIcon={<FilledRadioButtonSvg />}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell className={"premium-table" && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                      {FASE_AMOUNT_PREMIUM.BalanceSolve.value}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                      {data.BalanceSolve !== null ? (
                        <>
                          <span className="title-m">{titles.deathBenefit}</span>
                          {rafactorAmount(data.BalanceSolve.responseSpecifiedAmount)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                      {data.BalanceSolve !== null ? (
                        <>
                          <span className="title-m">{titles.targetPremium}</span>
                          {rafactorAmount(data.BalanceSolve.responseTargetPremium)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                      {data.BalanceSolve !== null ? (
                        <>
                          <span className="title-m">{titles.income}</span>
                          {rafactorAmount(data.BalanceSolve.responseAccumulateIncome)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    {settings.featureFlip.showCashColumns && (
                      <>
                        <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                          {data.BalanceSolve !== null ? (
                            <>
                              <span className="title-m">{titles.cashValue10}</span>
                              {rafactorAmount(data.BalanceSolve.responseAccumulateValueYear10)}
                            </>
                          ) : (
                            <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                          )}
                        </TableCell>
                        <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.BalanceSolve.value)}>
                          {data.BalanceSolve !== null ? (
                            <>
                              <span className="title-m">{titles.cashValue90}</span>
                              {rafactorAmount(data.BalanceSolve.responseAccumulateValueAge90)}
                            </>
                          ) : (
                            <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                          )}
                        </TableCell>
                      </>
                    )}
                    <TableCell className='premium-table pdf-cell'>
                      <PdfDownloadLink pdfData={data?.BalanceSolve} fileName={PDF_FILENAMES.BALANCE_SOLVE} altText='Icon' />
                    </TableCell>
                    <TableCell className="premium-table"></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell size='small'>
                      <FormControlLabel
                        onChange={event => handleOnChange(event.target.value)}
                        disabled={disabledElement}
                        className="radio-button"
                        control={
                          <Radio
                            value={FASE_AMOUNT_PREMIUM.MinNonMec.value}
                            icon={<EmptyRadioButtonSvg />}
                            checkedIcon={<FilledRadioButtonSvg />}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell className={"premium-table" && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                      {`${FASE_AMOUNT_PREMIUM.MinNonMec.value} ${increaseInitialDeathBen ? '+' : ''}`}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                      {data.MinNonMec !== null ? (
                        <>
                          <span className="title-m">{titles.deathBenefit}</span>
                          {rafactorAmount(data.MinNonMec.responseSpecifiedAmount)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                      {data.MinNonMec !== null ? (
                        <>
                          <span className="title-m">{titles.targetPremium}</span>
                          {rafactorAmount(data.MinNonMec.responseTargetPremium)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                      {data.MinNonMec !== null ? (
                        <>
                          <span className="title-m">{titles.income}</span>
                          {rafactorAmount(data.MinNonMec.responseAccumulateIncome)}
                        </>
                      ) : (
                        <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                      )}
                    </TableCell>
                    {settings.featureFlip.showCashColumns && (
                      <>
                        <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                          {data.MinNonMec !== null ? (
                            <>
                              <span className="title-m">{titles.cashValue10}</span>
                              {rafactorAmount(data.MinNonMec.responseAccumulateValueYear10)}
                            </>
                          ) : (
                            <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                          )}
                        </TableCell>
                        <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.MinNonMec.value)}>
                          {data.MinNonMec !== null ? (
                            <>
                              <span className="title-m">{titles.cashValue90}</span>
                              {rafactorAmount(data.MinNonMec.responseAccumulateValueAge90)}
                            </>
                          ) : (
                            <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                          )}
                        </TableCell>
                      </>
                    )}
                    <TableCell className='premium-table pdf-cell'>
                      <PdfDownloadLink pdfData={data?.MinNonMec} fileName={PDF_FILENAMES.MIN_NON_MEC} altText='Icon' />
                    </TableCell>
                    <TableCell className="premium-table"></TableCell>
                  </TableRow>

                  {specifiedAmountRow &&
                    <TableRow>
                      <TableCell className="premium-table">
                        <FormControlLabel
                          onChange={event => handleOnChange(event.target.value)}
                          disabled={disabledElement}
                          className="radio-button"
                          control={
                            <Radio
                              value={FASE_AMOUNT_PREMIUM.SpecAmount.value}
                              icon={<EmptyRadioButtonSvg />}
                              checkedIcon={<FilledRadioButtonSvg />}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell className={"premium-table" && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                        {FASE_AMOUNT_PREMIUM.SpecAmount.value}
                      </TableCell>
                      <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                        {data.SpecAmnt !== null && !disabledOnLoad ? (
                          <>
                            <span className="title-m">{titles.deathBenefit}</span>
                            {rafactorAmount(data.SpecAmnt.responseSpecifiedAmount)}
                          </>
                        ) : (
                          <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                        )}
                      </TableCell>
                      <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                        {data.SpecAmnt !== null && !disabledOnLoad ? (
                          <>
                            <span className="title-m">{titles.targetPremium}</span>
                            {rafactorAmount(data.SpecAmnt.responseTargetPremium)}
                          </>
                        ) : (
                          <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                        )}
                      </TableCell>
                      <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                        {data.SpecAmnt !== null && !disabledOnLoad ? (
                          <>
                            <span className="title-m">{titles.income}</span>
                            {rafactorAmount(data.SpecAmnt.responseAccumulateIncome)}
                          </>
                        ) : (
                          <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                        )}
                      </TableCell>
                      {settings.featureFlip.showCashColumns && (
                        <>
                          <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                            {data.SpecAmnt !== null && !disabledOnLoad ? (
                              <>
                                <span className="title-m">{titles.cashValue10}</span>
                                {rafactorAmount(data.SpecAmnt.responseAccumulateValueYear10)}
                              </>
                            ) : (
                              <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                            )}
                          </TableCell>
                          <TableCell className={classes.valuesTable && selectedRowStyle(FASE_AMOUNT_PREMIUM.SpecAmount.value)}>
                            {data.SpecAmnt !== null && !disabledOnLoad ? (
                              <>
                                <span className="title-m">{titles.cashValue90}</span>
                                {rafactorAmount(data.SpecAmnt.responseAccumulateValueAge90)}
                              </>
                            ) : (
                              <SkeletonComponent className={classes.skeleton} height={SkeletonConfig.height} />
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell className='premium-table pdf-cell'>
                        <PdfDownloadLink pdfData={data?.SpecAmnt} fileName={PDF_FILENAMES.SPEC_AMOUNT} altText='Icon' />
                      </TableCell>
                      <TableCell className={`premium-table ${classes.deathBenefitLastCell}`}>
                        {data.SpecAmnt !== null && !disabledOnLoad ? (
                          <HighlightOffIcon
                            onClick={removeSpecifiedAmountRow}
                            className={`${classes.addCircle} ${classes.removeButton}`}
                          />
                        ) : (<></>)}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </RadioGroup>
            <div className={classes.marginAddButton}>
              {!deathBenefitAmountRowSelected && !disabledAddNewRow &&
                <Button
                  className={classes.addDeathBenefitInput}
                  color="primary"
                  disabled={disabledElement}
                  onClick={() => setDeathBenefitAmountRowSelected(true)}
                >
                  <AddCircleOutlineTwoToneIcon fontSize='small' className={classes.addCircle} />
                  <span className="add-option-text">Add a Specific Total Death Benefit Coverage Amount</span>
                </Button>
              }
              <SlideFadeIn
                slideDirection="right"
                transitionIn={deathBenefitAmountRowSelected}
                slideTimeout={500}
                fadeEnterTimeout={900}
                fadeExitTimeout={200}
              >
                <Grid
                  container
                  className={classes.deathBenefitAmount}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={1} md={1} lg={1}>
                    <Button
                      className={`${classes.addDeathBenefitInput} remove-button`}
                      color="primary"
                      disabled={disabledElement}
                      onClick={() => {
                        setDeathBenefitAmountRowSelected(false);
                        if (errors?.illustrationIndicators?.deathBenefitAmount) {
                          handleResetPercentAmount(true);
                        }
                      }}
                    >
                      <RemoveCircleOutlineTwoToneIcon fontSize='small'
                        className={classes.addCircle}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} lg={1}>
                    <InputFormik
                      name="illustrationIndicators.deathBenefitAmount"
                      label="Starting Death Benefit Amount"
                      numberFormatCustom={CurrencyFormat}
                      maxLength={18}
                      decimalscale={0}
                      handleOnKeyUp={handleAmountChange}
                    />
                  </Grid>
                  <Grid className={`${classes.paddingButton}`} item xs={12} sm={1} md={1}>
                    <Box width={100}>
                      <InputFormik
                        name="illustrationIndicators.increaseByPercentage"
                        label="Increase by"
                        numberFormatCustom={PercentFormat}
                        handleAfterOnChange={(value) => handlePercentageChange(value)}
                        maxLength={4}
                        decimalscale={0}
                      />
                    </Box>
                  </Grid>
                  <Grid className={classes.paddingButton} item xs={12} sm={1} md={1}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="medium"
                      className={`${classes.addBtn} ${classes.buttonPrimary}`}
                      disabled={disabledAddButton}
                      onClick={() => {
                        addSpecifiedAmountRow(FASE_AMOUNT_PREMIUM.SpecAmount.value);
                        handleResetPercentAmount(true);
                      }}
                    >
                      {intl.formatMessage(messages.add)}
                    </Button>
                  </Grid>
                </Grid>
              </SlideFadeIn>
            </div>
          </div>
          <div className={`${classes.textDescription} ${classes.textNote}`}>
            <p>Note: All minimum non-MEC and specified amount variations will be saved to your <b>Illustration History</b>.</p>
            <p>Please turn off your Pop-Up blocker to allow the illustration Output to open.</p>
          </div>
          <div className={classes.actionButtonWrapper}>
            <Button
              className={`${classes.marginControlButton} ${classes.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => SavePopup()}
              disabled={disabledOnLoad}
            >
              {intl.formatMessage(messages.continue)}
            </Button>
            <Button
              className={`${classes.marginControlButton} ${classes.cancelButton}`}
              onClick={() => CancelPopup()}
              variant="outlined"
              color="primary"
            >
              {intl.formatMessage(messages.cancel)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

MinNonMECComparison.propTypes = {
  ValidateSavePopup: PropTypes.any,
  ValidateCancelPopup: PropTypes.any,
  opens: PropTypes.any,
  handleSubmit: PropTypes.any,
  data: PropTypes.any,
  handleOnChange: PropTypes.any,
  radioButtonsValue: PropTypes.any,
  fetchDeathBenData: PropTypes.any,
  increaseInitialDeathBen: PropTypes.any,
  specAmountGetted: PropTypes.any,
  cancelationComparisonRequest: PropTypes.any
}

export default MinNonMECComparison;
