/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Grid } from '@material-ui/core/';
import styled from 'styled-components';
import Icon from '../../images/alert.png';
import messages from './messages';

const RowCentered = styled.div`
  text-align: center;
  color: rgba(24, 70, 110, 1);

  h1 {
    font-size: 2.5em;
    margin-top: 35px;
    color: #87898b;
  }

  h3 {
    color: rgba(24, 70, 110, 1);
  }

  span {
    margin-top: 10px;
    display: inline-block;
  }
`;

/* eslint-disable react/prefer-stateless-function */
export default class NotFound extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12} sm={10} md={9} lg={8} xl={6}>
            <RowCentered>
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
              <img src={Icon} alt="Icon" />
              <br />
              <h3>
                <FormattedMessage {...messages.description} />
              </h3>
              <br />
              <h4>
                <FormattedMessage {...messages.instruction} />
                <br />
                <FormattedHTMLMessage {...messages.return} />
              </h4>
            </RowCentered>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
