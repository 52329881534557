import React from 'react';
import variables from '../../../themeVars';

const CalendarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g>
      <g>
        <path
          fill={variables.calendarIconFill}
          d="M9 4c0 .267-.448 2 0 2-1.552 0-2-.12-2 0V4c0 .12.448 0 2 0zm8 0c0 .267-.448 2 0 2-1.552 0-2-.12-2 0V4c0 .12.448 0 2 0zM6.625 6c.52 0 .522 1.078.01 1.59-.035.139-.081.43-.01.81-.13.43 1.275.985 1.625.8 1.095.185 1.688-.37 1.625-.8.063-.701 0 0 0-.8.213-.513 0-1.6 0-1.6h4.25s-.208 1.091 0 1.6c0 .8-.053.101 0 .8-.053.43.54.985 1.625.8.36.185 1.766-.37 1.625-.8.075-.373.034-.66 0-.8-.516-.51.304-1.6 0-1.6C18.41 6 19 6.552 19 7.6V10H5V7.6C5 6.552 5.59 6 6.625 6zM9 7c0 .558-.442 1-1 1s-1-.442-1-1 .442-1 1-1 1 .442 1 1zm8 0c0 .558-.442 1-1 1s-1-.442-1-1 .442-1 1-1 1 .442 1 1zm2 4v6.222C19 18.426 18.402 19 17.353 19H6.647C5.597 19 5 18.426 5 17.222V11zm-7 13c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        />
      </g>
    </g>
  </svg>
);

export default CalendarSvg;
