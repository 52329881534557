import React from 'react';
import variables from '../../themeVars';

export const EmptyRadioButtonSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{padding: '1px'}}  width="22" height="21" viewBox="0 -0.5 21 21">
    <defs>
      <path fill="#fff" stroke="#767676" strokeMiterlimit="10"  strokeWidth="1" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"/>
    </defs>
    <g>
      <g>
        <g>
          <path fill="#fff" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"/>
          <path fill="none" stroke="#767676" strokeMiterlimit="10" strokeWidth="1" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"/>
        </g>
      </g>
    </g>
  </svg>
);

export const FilledRadioButtonSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g>
      <g>
        <g>
          <path fill={variables.radioButtonFill} d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"/>
        </g>
        <g opacity=".9">
          <path fill="#fff" d="M7.56 13.463l8.032-7.907 1.075 1.055-9.108 8.945-4.226-4.15L4.39 10.35z"/>
        </g>
      </g>
    </g>
  </svg>
);
