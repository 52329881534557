import React from 'react';

export const EstimateSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g>
      <g>
        <path fill="#87898b" d="M15 15V9h2v6c0 .61-.189 1.056-1 1 .056.811-.39 1-1 1H2c-.625 0-1.074-.189-1-1-.815.056-1-.39-1-1V2C0 1.39.185.944 1 1c-.074-.811.375-1 1-1h7v2H2v13zM11 0h6v6h-2V3l-9 9-1-1 9-9h-3z" />
      </g>
    </g>
  </svg>
)

